import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes"
import { getAllValues } from "/apps/client/connectivity/store/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site.js"
import { ListActions, ListLayout, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { paginatedRequest } from "/utils/api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const { gateway } = useContext(GatewayRoutesContext)
  const [items, setItems] = useState([])
  const [selected, setSelected] = useState([])

  const retrieve = async (queryParams = `?gateway=${gateway.id}`) => {
    setLoading(true)

    await paginatedRequest(`connectivity/sites/${siteStore.id}/stores/${queryParams}`, auth.userData.access_token)()
      .then(results => {
        setItems(results)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getFilterValues = async () => {
    await getAllValues(gateway.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && gateway && gateway.id) {
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Connectivity"), url: `/sites/${siteStore.id}/connectivity/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` },
          { label: t("Stores"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/` }
        ])
      )
      getFilterValues()
      retrieve()
    }
  }, [siteStore.id, gateway])

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "type", header: t("Type"), sortable: true },
    { field: "pulling_interval", header: t("Pulling interval"), sortable: true },
    { field: "connector_instance_name", header: t("Connector instance"), sortable: true },
    { field: "number_points", header: t("Number of points"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name") },
    { field: "type", header: t("Type") },
    { field: "pulling_interval", header: t("Pulling interval") },
    { field: "connector_instance_name", header: t("Connector instance") }
  ]

  const getDetailUrl = storeId => {
    return `/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/${storeId}/`
  }

  const getDeleteUrl = storeId => {
    return `/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/${storeId}/delete/`
  }

  const onRowClick = storeId => {
    navigate(getDetailUrl(storeId))
  }

  useEffect(() => {
    setLoading(true)
    if (Object.keys(filters).length > 0) {
      let queryParams = `?gateway=${gateway.id}&`
      Object.keys(filters).forEach(key => {
        Object.values(filters[key]).forEach(value => {
          queryParams = queryParams + `${key}=${value}&`
        })
      })
      retrieve(queryParams)
    } else {
      if (siteStore.id) retrieve()
    }
  }, [filters])

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  return (
    <>
      {gateway ? (
        <section className="list-section">
          <ListActions
            filters={menuFilters}
            filterBackend={true}
          >
            <Link to={`/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/create/`}>
              <Button
                rounded
                severity="info"
                icon="fa-regular fa-plus"
                label={t("Create")}
              />
            </Link>
          </ListActions>

          <ListLayout
            columns={columns}
            value={items}
            objectName={t("Stores")}
            loading={loading}
            filters={filters}
            getDetailUrl={getDetailUrl}
            getDeleteUrl={getDeleteUrl}
            bulkDeleteUrl={`/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
            onRowClick={onRowClick}
            handleSelection={setSelected}
          />
        </section>
      ) : (
        <Loader />
      )}
    </>
  )
}
