import { NetworkRoutesContext } from "/apps/client/assets/communication/network/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListLayout, Loader, OverView, InnerTabView } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { TabPanel } from "primereact/tabview"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getSpaces, update } from "../api"
import { IPNetworkOverview, Network, SerialNetworkOverview } from "../models"

const GatewayInterfaces = ({ siteId, networkId }) => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const retrieve = async () => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`connectivity/sites/${siteId}/gateway_ip_interfaces/?network=${networkId}`, auth.userData.access_token)()
      .then(results => {
        setItems(results)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /*
    await getGatewayInterfaces(siteId, networkId, auth.userData.access_token).then(response => {
      if (response.data) {
        setItems(response.data.results)
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    })
    */
  }

  const columns = [
    { field: "gateway_name", header: t("Gateway") },
    { field: "ip_addr", header: t("IP Address") }
  ]

  useEffect(() => {
    retrieve()
  }, [networkId])

  return (
    <section className="list-section">
      <ListLayout
        value={items}
        columns={columns}
        loading={loading}
        objectName={t("Gateway interfaces")}
        hideActions={true}
      />
    </section>
  )
}

const NodeInterfaces = ({ siteId, networkId, networkType }) => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const retrieve = async () => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`asset/sites/${siteId}/node_interfaces/?network=${networkId}`, auth.userData.access_token)()
      .then(results => {
        setItems(results)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /* await getNodeInterfaces(siteId, networkId, auth.userData.access_token).then(response => {
      if (response.data) {
        setItems(response.data.results)
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const columns =
    networkType === "ipnetwork"
      ? [
          { field: "node_name", header: t("Node") },
          { field: "ip_address", header: t("IP Address") },
          { field: "transport_type", header: t("Transport type") },
          { field: "port", header: t("Port") }
        ]
      : [
          { field: "node_name", header: t("Node") },
          { field: "mstp_address", header: t("MSTP address") }
        ]

  useEffect(() => {
    retrieve()
  }, [networkId])

  return (
    <section className="list-section">
      <ListLayout
        value={items}
        columns={columns}
        loading={loading}
        objectName={t("Node interfaces")}
        hideActions={true}
      />
    </section>
  )
}

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [spaces, setSpaces] = useState([])
  const { network, retrieveDetail } = useContext(NetworkRoutesContext)

  const updateObject = async e => {
    e.preventDefault()

    const dataToSend = {
      ...data,
      ...{ bacnet_network_number: data.is_bacnet ? data.bacnet_network_number : null }
    }

    await update(siteStore.id, network.id, dataToSend, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Network", t("The network has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Network", error.message)]))
      })
  }

  const getSP = async () => {
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(response => {
        setSpaces(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Network", error.message)]))
      })
  }

  useEffect(() => {
    if (network) {
      setLoading(false)
      setObject(network)
    }
  }, [network])

  useEffect(() => {
    siteStore.id && getSP()
  }, [siteStore.id, network])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      object &&
      object.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Communication"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: t("Networks"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          {
            label: object.label ?? object.name,
            url: `/sites/${siteStore.id}/assets/communication/networks/${object.id}/`
          }
        ])
      )
  }, [siteStore.id, object])

  return (
    <>
      {object ? (
        <>
          <div className="base-content-title">
            <h2>
              {object.type} – {object.name ? object.name : object.ip_address_range}
            </h2>
          </div>
          <InnerTabView>
            <TabPanel header={t("Overview")}>
              <OverView
                object={object}
                model={Network(spaces, object.id)}
                overviewModel={object && object.type === "ipnetwork" ? IPNetworkOverview() : SerialNetworkOverview()}
                updateObject={updateObject}
                data={data}
                setData={setData}
                errors={errors}
                edit={edit}
                loading={loading}
                setEdit={setEdit}
                deleteUrl={`/sites/${siteStore.id}/assets/communication/networks/${object.id}/delete/`}
              />
            </TabPanel>
            <TabPanel header={t("Gateway interfaces")}>
              <GatewayInterfaces
                siteId={siteStore.id}
                networkId={object.id}
              />
            </TabPanel>
            <TabPanel header={t("Node interfaces")}>
              <NodeInterfaces
                siteId={siteStore.id}
                networkId={object.id}
                networkType={object.type}
              />
            </TabPanel>
          </InnerTabView>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
