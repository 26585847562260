import i18n from "/utils/i18n"
import { InputNumber } from "primereact/inputnumber"
import { Dropdown } from "hivolution-front-utils"
import { InputTextarea } from "primereact/inputtextarea"

export const CurtailParameters = () => {
  return {
    type: {
      label: i18n.t("Type", { ns: "client" }),
      field: Dropdown,
      options: {
        required: true,
        options: [{ label: i18n.t("Constant", { ns: "client" }), value: "Constant" }]
      }
    },
    schedule_duration: {
      label: i18n.t("Schedule duration", { ns: "client" }),
      dependsOn: "type",
      field: InputNumber,
      options: {
        required: true,
        showButtons: true
      }
    },
    max_schedules_per_day: {
      label: i18n.t("Max Schedules Per Day", { ns: "client" }),
      dependsOn: "type",
      field: InputNumber,
      options: {
        required: true,
        showButtons: true
      }
    },
    neutralization_duration: {
      label: i18n.t("Neutralization duration", { ns: "client" }),
      dependsOn: "type",
      field: InputNumber,
      options: {
        required: true,
        showButtons: true
      }
    },
    rebound_correction: {
      label: i18n.t("Rebound correction", { ns: "client" }),
      dependsOn: "type",
      field: InputNumber,
      options: {
        required: true,
        showButtons: true
      }
    },
    control_method: {
      label: i18n.t("Control method", { ns: "client" }),
      dependsOn: "type",
      field: Dropdown,
      options: {
        required: true,
        options: [{ label: i18n.t("Rectangle", { ns: "client" }), value: "Rectangle" }]
      }
    },
    comment: {
      label: i18n.t("Comment", { ns: "client" }),
      dependsOn: "type",
      field: InputTextarea,
      options: { required: true }
    }
  }
}
