import { paginatedRequestFlex } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { ListActions, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import Calendar from "react-calendar"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { add } from "../api"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const { siteId, flexId } = useParams()

  const [schedules, setSchedules] = useState([])
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedSchedules, setSelectedSchedules] = useState([])

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))

    dispatch(
      updateBreadcrumb([
        { label: t("Sites"), url: "/sites/" },
        { label: t("Detail"), url: `/sites/${siteId}/` },
        { label: t("Optimization"), url: `/sites/${siteId}/optimization/` },
        { label: t("Flexibility"), url: `/sites/${siteId}/optimization/flexibility/` },
        { label: t("Market player interaction"), url: `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/` },
        { label: t("Create"), url: `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/create/` }
      ])
    )
  }, [])

  useEffect(() => {
    const getCS = async dateStr => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequestFlex(`flex/${flexId}/load_curtail_schedule_fetch_in_a_day/${dateStr ? `?current_date=${dateStr}` : ""}`, auth.userData.access_token)()
        .then(results => {
          setSchedules(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getCurtailSchedules(flexId, dateStr, auth.userData.access_token).then(response => {
        setSchedules(response.data.results)
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    if (currentDate) {
      const strDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes())).toISOString().slice(0, 16)

      getCS(strDate)
    }
  }, [currentDate])

  const create = async function () {
    const data = {
      contract_id: flexId,
      schedule_date: new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes())).toISOString().split("T")[0],
      load_curtail_schedule: selectedSchedules.map(schedule => schedule.id)
    }
    await add(flexId, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Create", t("The proposal for aggregator has been created."))]))
        navigate(`/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  return (
    <>
      <Calendar
        className="calendar-box"
        onChange={setCurrentDate}
        value={currentDate}
        tileClassName={({ date, view }) => {
          if (view === "month") {
            let search = schedules.find(() => {
              const strDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`
              return schedules.curtail_start_date === strDate
            })
            if (search) {
              return "border-round border-1 border-solid border-primary"
            }
          }
          return "border-round"
        }}
      />

      <section className="list-section">
        <div className="list-section-head">
          <h3>
            {t("Load curtailment for")} {currentDate.toLocaleString().split(" ")[0]}
          </h3>
        </div>
        <ListActions>
          <Button
            rounded
            severity="success"
            disabled={selectedSchedules.length === 0}
            label={t("Save")}
            onClick={create}
            icon="fa-solid fa-floppy-disk"
          />
        </ListActions>
        <ListLayout
          columns={[
            {
              field: "curtail_start",
              header: t("Curtail start"),
              processValue: value => {
                return new Date(value).toLocaleString()
              }
            },
            {
              field: "curtail_end",
              header: t("Curtail end"),
              processValue: value => {
                return new Date(value).toLocaleString()
              }
            },
            { field: "curtail_capacity", header: t("Curtail capacity") },
            {
              field: "create_time",
              header: t("Proposed time"),
              processValue: value => {
                return new Date(value).toLocaleString()
              }
            },
            { field: "created_by", header: t("Proposed by"), sortable: true },
            { field: "execution_desired_text", header: t("Requested?") },
            {
              field: "request_time",
              header: t("Request time"),
              processValue: value => {
                return value ? new Date(value).toLocaleString() : ""
              }
            },
            { field: "requested_by", header: t("Requested by"), sortable: true }
          ]}
          value={schedules}
          handleSelection={items => setSelectedSchedules(items)}
        />
      </section>
    </>
  )
}
