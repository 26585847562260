import { AggregatorRoutesContext } from "/apps/energy/energy-company/aggregator/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { Loader, OverView, BaseLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { update } from "../api"
import { Aggregator as AggregatorModel } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["energy"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const { aggregator, retrieveDetail } = useContext(AggregatorRoutesContext)

  const updateObject = async function (e) {
    e.preventDefault()
    await update(aggregator.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The aggregator has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response && error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    if (aggregator) {
      setLoading(false)
      setData(aggregator)

      aggregator.id &&
        dispatch(
          updateBreadcrumb([
            { label: t("Energy companies"), url: "/energy/energy-company/distributors/" },
            { label: t("Aggregators"), url: "/energy/energy-company/aggregators/" },
            { label: aggregator.name, url: `/energy/energy-company/aggregators/${aggregator.id}/` }
          ])
        )
    }
  }, [aggregator])

  return (
    <>
      {aggregator && !loading ? (
        <BaseLayout title={aggregator.name}>
          <OverView
            object={aggregator}
            model={AggregatorModel()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            setEdit={setEdit}
            loading={loading}
            deleteUrl={`/energy/energy-company/aggregators/${aggregator.id}/delete/`}
          />
        </BaseLayout>
      ) : (
        <Loader />
      )}
    </>
  )
}
