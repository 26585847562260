{
  "Action": "Action",
  "Active": "Actif",
  "Add edge block": "Ajouter un bloc de bord",
  "Add edge memory object": "Ajouter un objet de mémoire de bord",
  "Add Equivalent Metering": "Ajouter une mesure équivalente",
  "Address": "Adresse",
  "Aggregator Response": "Réponse de l'agrégateur",
  "Altitude": "Altitude",
  "An error occured during the import, see more details in the error report file.": "Une erreur a eu lieu pendant l'import, plus de détail dans le fichier de rapport d'erreur.",
  "Analogue (2)": "Analogue (2)",
  "Analogue Input (0)": "Analogue Input (0)",
  "Analogue Output (1)": "Analogue Output (1)",
  "Analogue Value (2)": "Analogue Value (2)",
  "Alarm (4)": "Alarm (4)",
  "Are you sure you want to delete this interface?": "Êtes vous sur de vouloir supprimer cette interface?",
  "Are you sure you want to delete this protocol instance?": "Êtes vous sur de vouloir supprimer cette interface de protocole?",
  "Automation node": "Noeud d'automatisation",
  "Automation nodes": "Noeuds d'automatisation",
  "Automation point": "Point d'automatisation",
  "Automation point type": "Type de point d'automatisation",
  "Automation points": "Points d'automatisation",
  "Back to connector instance list": "Retour à la liste des instances de connecteur",
  "Back to contract list": "Retour à la liste des contrats",
  "Back to edge controller list": "Retour à la liste des contrôleur de bord",
  "Back to energy delivery point list": "Retour à la liste des points de livraison d'énergie",
  "Back to equipment list": "Retour à la liste des équipements",
  "Back to gateway list": "Retour à la liste des passerelles",
  "Back to network list": "Retour à la liste des réseaux",
  "Back to site list": "Retour à la liste des sites",
  "Back to space list": "Retour à la liste des espaces",
  "Back to state list": "Retour à la liste des états",
  "Back to store list": "Retour à la liste des stockages",
  "Backoff max wait": "Backoff max wait",
  "Bacnet network": "Réseau bacnet",
  "Bacnet number": "Numéro bacnet",
  "Bacnet priority": "Bacnet priority",
  "Bacnet priority uninhibition": "Bacnet priority uninhibition",
  "Bacnet property": "Bacnet property",
  "Batch size": "Taille du lot",
  "Batch size writing": "Taille du lot en écriture",
  "Binary Input (3)": "Binary Input (3)",
  "Binary Output (4)": "Binary Output (4)",
  "Binary Value (5)": "Binary Value (5)",
  "Building": "Bâtiment",
  "Carel node": "Noeud carel",
  "Carel nodes": "Noeuds carel",
  "Choose a node type": "Choisir un type de node",
  "Choose a sub type": "Choisir un sous type",
  "City": "Ville",
  "Client": "Client",
  "Coef": "Coef",
  "Coil": "Coil",
  "Comment": "Commentaire",
  "Communication": "Communication",
  "Condition": "Condition",
  "Configuration": "Configuration",
  "Confirm password": "Confirmation du mot de passe",
  "Confirmed curtail schedules": "Horaires de réduction confirmés",
  "Connect": "Connecter",
  "Connect timeout": "Délai de connection",
  "Connector instances": "Instances de connecteur",
  "Constraint": "Contrainte",
  "Contract step": "Étape de contract",
  "Contract type": "Type de contrat",
  "Contracts": "Contrats",
  "Control light": "Contrôler la lumière",
  "Control method": "Méthode de contrôle",
  "Cooled": "Refroidi",
  "Counter": "Counter",
  "Country": "Pays",
  "Create": "Création",
  "Create automation node": "Create un noeu d'automatisation",
  "Create connector instance": "Créer une instance de connecteur",
  "Create contract": "Créer un contrat",
  "Create curtail parameters": "Créer des paramètres de réduction",
  "Create edge controller": "Créer un contrôleur de bord",
  "Create energy delivery point": "Créer un point de livraison d'énergie",
  "Create equipment": "Créer un équipement",
  "Create gateway": "Créer une passerelle",
  "Create network": "Créer un réseau",
  "Create point": "Créer un point",
  "Create proposal for aggregator": "Créer une proposition pour l'agrégateur",
  "Create protocol instance": "Créer une instance de protocole",
  "Create site.": "Créer un site",
  "Create space": "Créer un espace",
  "Create state": "Créer un état",
  "Create store": "Créer un stockage",
  "Created at": "Créé le",
  "Curtail": "Curtail",
  "Curtail capacity": "Capacité de réduction",
  "Curtail end": "Fin de réduction",
  "Curtail parameters": "Paramètres de réduction",
  "Curtail parameters have been created.": "Les paramètres de réduction ont été créés.",
  "Curtail parameters have been removed.": "Les paramètres de réduction ont été supprimés.",
  "Curtail parameters have been updated.": "Les paramètres de réduction ont été mis à jour.",
  "Curtail start": "Début de réduction",
  "Data step": "Étape de données",
  "Delete": "Suppression",
  "Deployed": "Déployé",
  "Detail": "Détail",
  "Device ID": "ID de l'appareil",
  "Device instance": "Instance d'appareil",
  "Device type": "Type d'appareil",
  "Disable storage": "Désactiver le stockage",
  "Digital (1)": "Digital (1)",
  "Discrete input": "Discrete input",
  "Distributor": "Distributeur",
  "Edge blocks": "Blocs de bord",
  "Edge controller": "Contrôleur de bord",
  "Edge controller history": "Historique du contrôleur de bord",
  "Edge controllers": "Contrôleurs de bord",
  "Edge memory objects": "Objets de mémoire de bord",
  "Electricity": "Électricité",
  "End date": "Date de fin",
  "End time": "Heure de fin",
  "End validity": "Fin de validité",
  "Energy": "Énergie",
  "Energy delivery point": "Point de livraison d'énergie",
  "Energy delivery points": "Points de livraison d'énergie",
  "Equipment": "Equipement",
  "Equipments": "Equipements",
  "Equivalent Metering Intercept": "Interception de mesure équivalente",
  "Equivalent Meterings": "Mesures équivalentes",
  "Eval": "Eval",
  "Execution desired": "Execution desirée",
  "Export": "Exporter",
  "Export automation nodes": "Exporter les noeuds d'automatisation",
  "Export equipments": "Exporter les équipments",
  "Export spaces": "Exporter les espaces",
  "External ID": "ID externe",
  "Flex contract": "Contrat flex",
  "Flex opti": "Flex opti",
  "Flexibility": "Flexibilité",
  "Float": "Float",
  "Gas": "Gaz",
  "Gateway": "Passerelle",
  "Gateway interface": "Interface de passerelles",
  "Gateway interfaces": "Interfaces de passerelles",
  "Gateway point text": "Texte du point de passerelle",
  "Gateway point value": "Valeur du point de passerelle",
  "Gateway UDP Port": "Port UDP de la passerelle",
  "Gateways": "Passerelles",
  "Hardware": "Matériel",
  "Heated": "Chauffé",
  "Height (m)": "Hauteur (m)",
  "History": "Historique",
  "History delete": "Suppression historique",
  "History detail": "Détail historique",
  "Home": "Accueil",
  "Import": "Importer",
  "Import automation nodes": "Importer des noeuds d'automatisation",
  "Import equipments": "Importer des équipements",
  "Import spaces": "Importer des espaces",
  "Inhibit": "Inhibit",
  "Inhibit ignore": "Inhibit ignore",
  "Inhibit key": "Inhibit key",
  "Inhibit light": "Inhiber la lumière",
  "Initial values": "Valeurs initiales",
  "Int": "Int",
  "Integer (3)": "Integer (3)",
  "IO node type": "Type de noeud IO",
  "ID Var": "ID Var",
  "IP": "IP",
  "IP Address": "Adresse IP",
  "IP address range": "Plage d'adresses IP",
  "IP addresses": "Adresses IP",
  "IP Range": "Plage d'adresses IP",
  "Is Logic": "Est logique",
  "Keep alive timer": "Minuterie de maintien en vie",
  "Latitude": "Latitude",
  "Line ID": "ID de ligne",
  "Linear constant": "Constante linéaire",
  "Linear multiplier": "Multiplicateur linéaire",
  "Load curtail parameters": "Paramètes de réduction de charge",
  "Load curtail schedule": "Horaire de réduction de charge",
  "Longitude": "Longitude",
  "Manage modbus timeout": "Gérer les délais modbus",
  "Manufacturer": "Fabricant",
  "Market player": "Acteur du marché",
  "Market player interaction": "Interaction des acteurs du marché",
  "Max count": "Nombre maximum",
  "Max Schedules Per Day": "Horaires maximum par jour",
  "Meter ID": "Identifiant du compteur",
  "Model": "Modèle",
  "MSTP address": "Adresse MSTP",
  "Multi-case name": "Nom multi-cas",
  "Multi State Output (14)": "Multi State Output (14)",
  "Name": "Nom",
  "Network": "Réseau",
  "Networks": "Réseaux",
  "Neutralization duration": "Durée de neutralisation",
  "No": "Non",
  "Node": "Noeud",
  "Node address": "Adresse du noeud",
  "Node interfaces": "Interfaces de noeud",
  "Number of batches": "Nombre de lots",
  "Number of points": "Nombre de points",
  "Number of stores": "Nombre de stockages",
  "Number of words": "Nombre de mots",
  "Object instance": "Object instance",
  "Object name": "Object name",
  "Object type": "Object type",
  "Optimization": "Optimisation",
  "Optimization gateways": "Passerelles d'optimisation",
  "Optimized": "Optimized",
  "Original point value": "Valeur du point d'origine",
  "Overview": "Aperçu",
  "Parent space": "Espace parent",
  "Password": "Mot de passe",
  "Passwords must be the same": "Les mots de passe doivent être identiques",
  "Path": "Chemin",
  "Period": "Période",
  "Permeable": "Perméable",
  "Persistent": "Persistent",
  "Point": "Point",
  "Points": "Points",
  "Points has been removed.": "Les points ont été supprimés.",
  "Points optimized": "Points optimisés",
  "Points writable": "Points writable",
  "Points has been removed": "Les points ont été supprimé",
  "Port": "Port",
  "Post-cooling end date": "Date de début du post-refroidissement",
  "Post-cooling end time": "Heure de début du post-refroidissement",
  "Postcool": "Postcool",
  "Pre-cooling start date": "Date de début du pré-refroidissement",
  "Pre-cooling start time": "Heure du début du pré-refroidissement",
  "Present value": "Present value",
  "Precool": "Precool",
  "Pretty name": "Nom d'affichage",
  "Price zone": "Zone de prix",
  "Priority": "Priorité",
  "Priority 1": "Priorité 1",
  "Priority 2": "Priorité 2",
  "Priority 3": "Priorité 3",
  "Priority 4": "Priorité 4",
  "Priority 5": "Priorité 5",
  "Priority 6": "Priorité 6",
  "Priority 7": "Priorité 7",
  "Priority 8": "Priorité 8",
  "Priority 9": "Priorité 9",
  "Priority 10": "Priorité 10",
  "Priority 11": "Priorité 11",
  "Priority 12": "Priorité 12",
  "Priority 13": "Priorité 13",
  "Priority 14": "Priorité 14",
  "Priority 15": "Priorité 15",
  "Priority 16": "Priorité 16",
  "Priority array": "Priority array",
  "Proposal for aggregator": "Proposition pour l'agrégateur",
  "Proposal sent": "Proposition envoyée",
  "Proposed by": "Proposé par",
  "Proposed curtail schedules": "Horaires de réduction proposés",
  "Proposed time": "Date de création",
  "Protocol": "Protocole",
  "Protocol instances": "Instances de protocole",
  "Protocol type": "Type de protocol",
  "Protocols": "Protocoles",
  "Pulling interval": "Intervalle de tirage",
  "Read": "Lecture",
  "Read timeout": "Délai de lecture",
  "Read/Write": "Lecture/Écriture",
  "Read / Write": "Lecture / Ecriture",
  "Rebound correction": "Correction du rebond",
  "Rectangle": "Rectangle",
  "Relinquish default": "Relinquish default",
  "Request time": "Date de demande",
  "Requested by": "Demandé par",
  "Requested?": "Demandé?",
  "Resolution interval": "Intervalle de résolution",
  "Response timeout": "Délai de réponse",
  "Response timeouts has been updated": "Les délais de réponse ont été mis à jour",
  "Router": "Routeur",
  "Safe state": "Safe state",
  "Save": "Sauvegarder",
  "Scenario priority": "Priorité du scénario",
  "Schedule duration": "Durée du planning",
  "Scheme": "Schème",
  "Security mode": "Mode de sécurité",
  "Security policy": "Politique de sécurité",
  "Select a client": "Choisir un client",
  "Select a gateway": "Choisir une passerelle",
  "Select a price zone": "Choisir une zone de prix",
  "Select a state type": "Choisir un type d'état",
  "Select a weather zone": "Choisir une zone météorologique",
  "Select an edge memory object type": "Choisir un type d'objet de mémoire de bord",
  "Send to aggregator": "Envoyer à l'agrégateur",
  "Sent by": "Envoyée par",
  "Serial": "En série",
  "Serial ID": "Numéro de série",
  "Server certificate path": "Chemin du certificat du serveur",
  "Set wait time": "Set wait time",
  "Site": "Site",
  "Sites": "Sites",
  "Slave ID": "Slave ID",
  "Sleep time": "Durée de sommeil",
  "SM Nodes": "Noeuds SM",
  "Software": "Logiciel",
  "Space": "Espace",
  "Spaces": "Espaces",
  "Start date": "Date de début",
  "Start time": "Heure de début",
  "Start validity": "Début de validité",
  "State": "État",
  "State history": "Historique de l'état",
  "State values": "Valeurs de l'état",
  "States": "État",
  "Status": "Statut",
  "Status flags": "Status flags",
  "Store": "Stockage",
  "Store name": "Nom de stockage",
  "Store type": "Type de stockage",
  "Stores": "Stockages",
  "Subtype": "Sous-type",
  "Surface area (m²)": "Surface (m²)",
  "Synchronize legacy": "Synchroniser avec legacy",
  "System manager": "Gestionnaire de système",
  "Target": "Cible",
  "Template": "Template",
  "Template automation nodes": "Template de noeud d'automatisation",
  "Template equipments": "Template des equipments",
  "Template spaces": "Template des espaces",
  "Test Schedule": "Horaire de test",
  "The automation node has been created.": "Le noeud d'automatisation a été créé.",
  "The automation node has been removed.": "Le noeud d'automatisation a été supprimé.",
  "The automation node has been updated.": "Le noeud d'automatisation a été mis à jour.",
  "The automation point has been created.": "Le point d'automatisation a été créé.",
  "The automation point has been removed.": "Le point d'automatisation a été supprimé.",
  "The automation point has been updated.": "Le point d'automatisation a été mis à jour.",
  "The connector instance has been created.": "L'instance de connecteur a été créée.",
  "The connector instance has been removed.": "L'instance de connecteur a été supprimée.",
  "The connector instance has been updated.": "L'instance de connecteur a été mise à jour.",
  "The contract has been created.": "Le contrat a été créé.",
  "The contract has been removed.": "Le contrat a été supprimé.",
  "The contract has been updated.": "Le contrat a été mis à jour.",
  "The curtail schedule has been removed": "L'horaire de réduction a été créé",
  "The curtail schedule has been updated.": "L'horaire de réduction a été mis à jour.",
  "The edge controller has been created.": "Le contrôleur de bord a été créé.",
  "The edge controller has been removed.": "Le contrôleur de bord a été supprimé.",
  "The edge controller has been updated.": "Le contrôleur de bord a été mis à jour.",
  "The energy delivery point has been created.": "Le point de livraison d'énergie a été créé.",
  "The energy delivery point has been removed.": "Le point de livraison d'énergie a été supprimé.",
  "The energy delivery point has been updated.": "Le point de livraison d'énergie a été mis à jour.",
  "The equipment has been created.": "L'équipement a été créé.",
  "The equipment has been removed.": "L'équipement a été supprimé.",
  "The equipment has been updated.": "L'équipement a été mis à jour.",
  "The flex contract has been created.": "Le contrat flex a été créé.",
  "The flex opti has been created.": "L'opti flex a été créée.",
  "The flex opti has been removed.": "L'opti flex a été supprimée.",
  "The flex opti has been updated.": "L'opti flex a été mise à jour.",
  "The gateway has been created.": "La passerelle a été créée.",
  "The gateway has been removed.": "La passerelle a été supprimée.",
  "The gateway has been updated.": "La passerelle a été mise à jour.",
  "The gateway interface has been created.": "L'interface de la passerelle a été créée.",
  "The gateway interface has been removed.": "L'interface de la passerelle a été supprimée.",
  "The gateway interface has been updated.": "L'interface de la passerelle a été mise à jour.",
  "The interface has been created.": "L'interface a été créée.",
  "The interface has been removed.": "L'interface a été supprimée.",
  "The interface has been updated.": "L'interface a été mise à jour.",
  "The network has been created.": "Le réseau a été créé.",
  "The network has been removed.": "Le réseau a été supprimé.",
  "The network has been updated.": "Le réseau a été mis à jour.",
  "The point has been created.": "Le point a été créé.",
  "The point has been removed.": "Le point a été supprimé.",
  "The point has been updated.": "Le point a été mis à jour.",
  "The proposal for aggregator has been created.": "La proposition d'agrégateur a été créée.",
  "The proposal for aggregator has been removed.": "La proposition d'agrégateur a été supprimée.",
  "The proposal has been sent to the aggregator.": "La proposition a été envoyé a l'agrégateur.",
  "The proposed curtail schedule has been removed.": "L'horaire de réduction proposé a été supprimé.",
  "The proposed curtail schedule has been updated.": "L'horaire de réduction proposé a été mis à jour.",
  "The proposed curtail schedules has been created.": "L'horaire de réduction proposé a été créé.",
  "The protocol instance has been created.": "L'instance de protocole a été créée.",
  "The protocol instance has been removed.": "L'instance de protocole a été supprimée.",
  "The protocol instance has been updated.": "L'instance de protocole a été mise à jour.",
  "The site has been created.": "Le site a été créé.",
  "The site has been removed.": "Le site a été supprimé.",
  "The site has been updated.": "Le site a été mis à jour.",
  "The space has been created.": "L'espace a été créé.",
  "The space has been removed.": "L'espace a été supprimé.",
  "The space has been updated.": "L'espace a été mis à jour.",
  "The state has been created.": "L'état a été créé.",
  "The state has been removed.": "L'état a été supprimé.",
  "The state has been updated.": "l'état a été mis à jour.",
  "The store has been created.": "Le stockage a été créé.",
  "The store has been removed.": "Le stockage a été supprimé.",
  "The store has been updated.": "Le stockage a été mis à jour.",
  "Transmission Company": "Compagnie de transmission",
  "Transport type": "Type de transport",
  "Type": "Type",
  "Unit": "Unité",
  "Type of bit": "Type de bit",
  "UInt": "UInt",
  "Upcoming load curtailment for": "Réduction de charge à venir pour le",
  "Update response timeouts": "Mettre à jour les délais de réponse",
  "Updated at": "Mis à jour le",
  "Updated by": "Mis à jour par",
  "User": "Utilisateur",
  "Valid": "Valide",
  "Value": "Valeur",
  "Weather zone": "Zone météorologique",
  "Write": "Écriture",
  "Write timeout": "Délai d'écriture",
  "Writable": "Droit d'écritue",
  "Yes": "Oui",
  "Zip code": "Code postal",
  "Zone name": "Nom de la zone"
}
