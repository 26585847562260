import { EquipmentRoutesContext } from "/apps/client/assets/building/equipment/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"

import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"

import { Loader, OverView, ReturnButton } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getSpaces, update } from "../api"
import { Equipment, EquipmentOverview } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["client"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [nodes, setNodes] = useState([])
  const [gateways, setGateways] = useState([])
  const [spaces, setSpaces] = useState([])
  const [equipmentTypes, setEquipmentTypes] = useState([])
  const [equipmentSubTypes, setEquipmentSubTypes] = useState([])
  const { equipment, retrieveDetail } = useContext(EquipmentRoutesContext)

  const updateObject = async function (e) {
    e.preventDefault()

    await update(siteStore.id, equipment.id, data, auth.userData.access_token)
      .then(() => {
        setLoading(true)
        dispatch(addNotifications([successNotification("Update", t("The equipment has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          const errorsEquipment = error.response.data
          const errorsEquipCommInfo = {
            ...(errorsEquipment.gateway ? { gateway: errorsEquipment.gateway } : {}),
            ...(errorsEquipment.node ? { node: errorsEquipment.node } : {}),
            ...errorsEquipment.equip_comm_info
          }
          setErrors({
            ...errorsEquipment,
            ...{ equip_comm_info: errorsEquipCommInfo }
          })
          console.error(error.message)
        } else {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (equipment) {
      setLoading(false)
      setObject(equipment)
    }
  }, [equipment])

  useEffect(() => {
    const retrieveNodes = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteStore.id}/nodes/`, auth.userData.access_token)()
        .then(results => {
          setNodes(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getNodes(siteStore.id, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setNodes(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    const retrieveGateways = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`connectivity/sites/${siteStore.id}/gateways/`, auth.userData.access_token)()
        .then(results => {
          setGateways(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getGateways(siteStore.id, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setGateways(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    const retrieveSpaces = async () => {
      await getSpaces(siteStore.id, auth.userData.access_token)
        .then(response => {
          setSpaces(response.data)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    const retrieveEquipmentTypes = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest("asset/equipment_types/", auth.userData.access_token)()
        .then(results => {
          setEquipmentTypes(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getEquipmentTypes(auth.userData.access_token).then(response => {
        if (response.data.results) {
          setEquipmentTypes(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }

    if (siteStore.id) {
      object &&
        object.id &&
        dispatch(
          updateBreadcrumb([
            { label: t("Sites"), url: "/sites/" },
            { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
            { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
            { label: t("Building"), url: `/sites/${siteStore.id}/assets/building/equipments/` },
            { label: t("Equipments"), url: `/sites/${siteStore.id}/assets/building/equipments/` },
            { label: object.label ?? object.name, url: `/sites/${siteStore.id}/assets/building/equipments/${object.id}/` }
          ])
        )

      retrieveNodes()
      retrieveGateways()
      retrieveSpaces()
      retrieveEquipmentTypes()
    }
  }, [siteStore.id, object])

  useEffect(() => {
    const retrieveEquipmentSubTypes = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/equipment_subtypes/?equipment_type=${data.equipment_type}`, auth.userData.access_token)()
        .then(results => {
          setEquipmentSubTypes(results)
          setData({
            ...data,
            ...{ equipment_subtype: null }
          })
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getEquipmentSubTypes(data.equipment_type, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setEquipmentSubTypes(response.data.results)
          setData({
            ...data,
            ...{ equipment_subtype: null},
          })
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    if (edit && data.equipment_type) retrieveEquipmentSubTypes()
    else setEquipmentSubTypes([])
  }, [edit, data.equipment_type])

  return (
    <>
      {object ? (
        <>
          <ReturnButton
            label={t("Back to equipment list")}
            onClick={() => navigate(`/sites/${siteStore.id}/assets/building/equipments`)}
          />

          <div className="base-content-title">
            <h2>
              {t("Equipment")} {object.name}
            </h2>
          </div>

          <OverView
            object={object}
            model={Equipment(nodes, gateways, spaces, equipmentTypes, equipmentSubTypes)}
            overviewModel={EquipmentOverview()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            setEdit={setEdit}
            deleteUrl={`/sites/${siteStore.id}/assets/building/equipments/${object.id}/delete/`}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
