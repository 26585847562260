import { retrieve } from "/apps/client/connectivity/connector-instance/api.js"
import { errorNotification } from "/utils/notification.js"
import { addNotifications } from "/utils/store/notification.js"
import { site } from "/utils/store/site"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import { TabsLayout, Loader } from "hivolution-front-utils"
import List from "./components/List"
import DeleteBulk from "./components/DeleteBulk"
import { useTranslation } from "react-i18next"
import { GatewayRoutesContext } from "../gateway/routes"
import ProtocolInstanceRoutes from "./protocol-instance/routes"

const ConnectorInstanceRoutesContext = createContext(null)

const ConnectorInstanceContent = () => {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["client"])
  const auth = useAuth()
  const { connectorInstanceId } = useParams()
  const [connector, setConnector] = useState()
  const [tabs, setTabs] = useState()
  const { gateway } = useContext(GatewayRoutesContext)

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, connectorInstanceId, auth.userData.access_token)
      .then(response => {
        setConnector(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && retrieveDetail()
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && gateway && gateway.id && connectorInstanceId) {
      setTabs([
        {
          name: t("Base informations"),
          active: location.pathname.endsWith(`/connector-instances/${connectorInstanceId}/`),
          url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connectorInstanceId}/`
        },
        {
          name: t("Protocol instances"),
          active: location.pathname.endsWith("/protocol-instances/"),
          url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connectorInstanceId}/protocol-instances/`
        }
      ])

      retrieveDetail()
    }
  }, [siteStore.id, gateway, connectorInstanceId])

  return (
    <ConnectorInstanceRoutesContext.Provider value={{ connector, retrieveDetail }}>
      {connector ? (
        <TabsLayout
          title={`${t("Connector instance")} ${connector.name}`}
          tabs={tabs}
          setTabs={setTabs}
        />
      ) : (
        <Loader />
      )}
    </ConnectorInstanceRoutesContext.Provider>
  )
}

export { ConnectorInstanceRoutesContext }
export default function ConnectorInstanceRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="/create/"
        element={<Create />}
      />
      <Route
        path="/delete/"
        element={<DeleteBulk />}
      />
      <Route
        path=":connectorInstanceId"
        element={<ConnectorInstanceContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
        <Route
          path="protocol-instances/*"
          element={<ProtocolInstanceRoutes />}
        />
      </Route>
    </Routes>
  )
}
