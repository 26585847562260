import ClientRoutes from "/apps/client/routes"
import ConnectivityRoutes from "/apps/connectivity/routes"
import EnergyRoutes from "/apps/energy/routes"
import TabList from "/apps/home/components/List"
import OrganizationRoutes from "/apps/home/routes"
import OptimizationRoutes from "/apps/optimization/routes"
import Private from "/utils/auth/components/Private"
import Header from "/utils/store/components/Header"
import Notifications from "/utils/store/components/Notifications"
import SideBarManager from "/utils/store/components/SideBarManager"
import store from "/utils/store/main"
import { AuthProvider } from "oidc-react"
import { PrimeReactProvider } from "primereact/api"
import React, { useEffect } from "react"

import "./static/sass/app.sass"

import "./utils/i18n"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter, Outlet, Route, Routes, useLocation } from "react-router-dom"
import BreadCrumb from "./utils/store/components/BreadCrumb"

const container = document.getElementById("app")
const root = createRoot(container)
const primeReactConfig = {
  ripple: true
}

const oidcConfig = {
  authority: window.OIDC_ENDPOINT,
  clientId: window.OIDC_CLIENT_ID,
  redirectUri: window.REDIRECT_URI
}

const MainContent = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <PrimeReactProvider params={primeReactConfig}>
      <Notifications />
      <Header />
      <SideBarManager />

      <main className="main-content">
        <BreadCrumb />
        <Outlet />
      </main>
    </PrimeReactProvider>
  )
}

root.render(
  <AuthProvider {...oidcConfig}>
    <Private>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<MainContent />}
            >
              <Route
                index
                element={<TabList />}
              />
              <Route
                path="organizations/*"
                element={<OrganizationRoutes />}
              />
              <Route
                path="connectivity/*"
                element={<ConnectivityRoutes />}
              />
              <Route
                path="energy/*"
                element={<EnergyRoutes />}
              />
              <Route
                path="optimization/*"
                element={<OptimizationRoutes />}
              />
              <Route
                path="sites/*"
                element={<ClientRoutes />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </Private>
  </AuthProvider>
)
