import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { Dropdown } from "hivolution-front-utils"
import { commonsFields, commonsOverview, t } from "./commons"

export const ModbusBit = nodes => {
  return {
    ...commonsFields(nodes),
    ...{
      address: {
        label: t("Address"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      writable: {
        label: t("Writable"),
        field: InputSwitch,
        options: {}
      },
      bit_type: {
        label: t("Type of bit"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "COIL", label: t("Coil") },
            { value: "DISCRETE_INPUT", label: t("Discrete input") }
          ]
        }
      }
    }
  }
}

export const ModbusBitOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      address: { label: t("Address") },
      read_write: { label: t("Read / Write") },
      bit_type: { label: t("Type of bit") }
    }
  }
}

export const ModbusRegister = nodes => {
  return {
    ...commonsFields(nodes),
    ...{
      address: {
        label: t("Address"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      number_of_words: {
        label: t("Number of words"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      writable: {
        label: t("Writable"),
        field: InputSwitch,
        options: {}
      },
      type_of_register: {
        label: t("Type of bit"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INPUT_REGISTER", label: t("Input register") },
            { value: "HOLDING_REGISTER", label: t("Holding register") }
          ]
        }
      },
      format_of_bit: {
        label: t("Format"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INT", label: t("Int") },
            { value: "UINT", label: t("UInt") },
            { value: "FLOAT", label: t("Float") }
          ]
        }
      },
      swap_words: {
        label: t("Swap words"),
        field: InputSwitch,
        options: {}
      },
      swap_bytes: {
        label: t("Swap bytes"),
        field: InputSwitch,
        options: {}
      }
    }
  }
}

export const ModbusRegisterOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      address: { label: t("Address") },
      number_of_words: { label: t("Number of words") },
      read_write: { label: t("Read / Write") },
      type_of_register: { label: t("Type of bit") },
      format_of_bit: { label: t("Type of bit") },
      swap_words: { label: t("Swap words") },
      swap_bytes: { label: t("Swap bytes") }
    }
  }
}

export const ModbusRegisterBitExtract = nodes => {
  return {
    ...commonsFields(nodes),
    ...{
      address: {
        label: t("Address"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      number_of_words: {
        label: t("Number of words"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      type_of_register: {
        label: t("Type of bit"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INPUT_REGISTER", label: t("Input register") },
            { value: "HOLDING_REGISTER", label: t("Holding register") }
          ]
        }
      },
      format_of_bit: {
        label: t("Format"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INT", label: t("Int") },
            { value: "UINT", label: t("UInt") },
            { value: "FLOAT", label: t("Float") }
          ]
        }
      },
      swap_words: {
        label: t("Swap words"),
        field: InputSwitch,
        options: {}
      },
      swap_bytes: {
        label: t("Swap bytes"),
        field: InputSwitch,
        options: {}
      },
      bit_start: {
        label: t("Bit strat"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      number_of_bits: {
        label: t("Number of Bits"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      writable: {
        label: t("Writable"),
        field: InputSwitch,
        options: {}
      }
    }
  }
}

export const ModbusRegisterBitExtractOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      address: { label: t("Address") },
      number_of_words: { label: t("Number of words") },
      type_of_register: { label: t("Type of bit") },
      format_of_bit: { label: t("Type of bit") },
      swap_words: { label: t("Swap words") },
      swap_bytes: { label: t("Swap bytes") },
      bit_start: { label: t("Bit start") },
      number_of_bits: { label: t("Number of Bits") },
      read_write: { label: t("Read / Write") }
    }
  }
}
