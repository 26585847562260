import { paginatedRequest } from "/utils/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { ListActions, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["connectivity"])

  const [gatewayBoxes, setGatewayBoxes] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { label: t("Gateways"), url: "/connectivity/gateways/boxes/" },
        { label: t("Boxes"), url: "/connectivity/gateways/boxes/" }
      ])
    )

    const retrieve = async () => {
      await paginatedRequest("connectivity/hardwares/", auth.userData.access_token)()
        .then(results => {
          setGatewayBoxes(results)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }

    retrieve()
  }, [])

  const columns = [
    { field: "manufacturer", header: t("Manufacturer"), sortable: true },
    { field: "model", header: t("Model"), sortable: true },
    { field: "serial_id", header: t("Serial ID"), sortable: true }
  ]

  const getDetailUrl = id => {
    return `/connectivity/gateways/boxes/${id}/`
  }

  const getDeleteUrl = id => {
    return `/connectivity/gateways/boxes/${id}/delete/`
  }

  const onRowClick = id => {
    navigate(getDetailUrl(id))
  }

  const headerTemplate = ({ manufacturer }) => manufacturer || "No manufacturer"
  const menuFilters = { columns, items: gatewayBoxes, filters, setFilters }

  return (
    <>
      <ListActions filters={menuFilters}>
        <Link to="/connectivity/gateways/boxes/create/">
          <Button
            rounded
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={gatewayBoxes}
        filters={filters}
        objectName={t("Gateway boxes")}
        loading={loading}
        rowGroupHeaderTemplate={headerTemplate}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
      />
    </>
  )
}
