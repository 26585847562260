import { InputText } from "primereact/inputtext"
import { Dropdown, DateInput } from "hivolution-front-utils"
import { InputTextarea } from "primereact/inputtextarea"
import { InputNumber } from "primereact/inputnumber"
import { MultiSelect } from "primereact/multiselect"

import i18n from "/utils/i18n"

export const FlexContract = (energyDeliveryPoints, energyCompanies, gateways) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("Name", { ns: "client" }), field: InputText, options: { required: true } },
    contract_type: {
      label: i18n.t("Contract type", { ns: "client" }),
      field: Dropdown,
      options: {
        options: [{ label: i18n.t("Flexibility", { ns: "client" }), value: "Flexibility" }],
        disabled: true
      }
    },
    energy_delivery_point: {
      label: i18n.t("Energy delivery point", { ns: "client" }),
      field: Dropdown,
      options: {
        required: true,
        options: energyDeliveryPoints,
        optionLabel: "meter_id",
        optionValue: "id"
      }
    },
    description: {
      label: i18n.t("Description", { ns: "client" }),
      field: InputTextarea,
      options: {}
    },
    contract_step: {
      label: i18n.t("Contract step", { ns: "client" }),
      field: InputNumber,
      options: {
        showButtons: true
      }
    },
    start_validity: {
      label: i18n.t("Start validity", { ns: "client" }),
      field: DateInput,
      options: {}
    },
    end_validity: {
      label: i18n.t("End validity", { ns: "client" }),
      field: DateInput,
      options: {}
    },
    energy_company: {
      label: i18n.t("Market player", { ns: "client" }),
      field: Dropdown,
      options: {
        options: energyCompanies,
        optionValue: "id",
        optionLabel: "name"
      }
    },
    optimization_gateways: {
      label: i18n.t("Optimization gateways", { ns: "client" }),
      field: MultiSelect,
      options: {
        options: gateways,
        optionValue: "id",
        optionLabel: "name",
        display: "chips"
      }
    }
  }
}
