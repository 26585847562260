import React, { useEffect } from "react"
import { DeleteConfirmation, Loader } from "hivolution-front-utils"
import { remove } from "../api"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { addNotifications } from "/utils/store/notification"
import { useAuth } from "oidc-react"
import { errorNotification, successNotification } from "/utils/notification"
import { useTranslation } from "react-i18next"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { organization } from "/utils/store/organization"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["client"])

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    await remove(organizationStore.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The organization has been removed."))])
        navigate("/")
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    organizationStore &&
      organizationStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Organizations"), url: "/" },
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/` },
          { label: t("Delete"), url: `/organizations/${organizationStore.id}/delete/` }
        ])
      )
  }, [organizationStore])

  return (
    <>
      {organizationStore ? (
        <DeleteConfirmation
          objectType={t("Organization")}
          objectNames={[organizationStore.name]}
          remove={removeObject}
          removeNotAllowedMessage={organizationStore.sites_count > 0 ? t("You can't delete this organization, there is sites linked to it") : null}
          goBack={() => navigate(-1)}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}
