import { InputText } from "primereact/inputtext"
import { Dropdown } from "hivolution-front-utils"
import { commonsFields, commonsOverview, t } from "./commons"

export const SMNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      proto: {
        label: t("Protocol"),
        field: Dropdown,
        options: {
          options: [
            { value: "HTTP", label: t("HTTP") },
            { value: "HTTPS", label: t("HTTPS") }
          ],
          required: true
        }
      },
      sm_type: {
        label: t("SM Type"),
        field: Dropdown,
        options: {
          options: [
            { value: "AK-SM8XX", label: t("AK-SM8XX") },
            { value: "AK-SM8XXA", label: t("AK-SM8XXA") },
            { value: "AK-SC355", label: t("AK-SC355") }
          ],
          required: true
        }
      },
      store_name: {
        label: "Store name",
        field: InputText,
        options: {}
      },
      software: {
        label: t("Software"),
        field: InputText,
        options: {}
      },
      user: {
        label: t("User"),
        field: InputText,
        options: {
          required: true
        }
      },
      password: {
        label: t("Password"),
        field: InputText,
        options: {
          required: true,
          type: "password"
        }
      },
      confirm_password: {
        label: t("Confirm password"),
        field: InputText,
        options: {
          required: true,
          type: "password"
        }
      }
    }
  }
}

export const SMOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      proto: { label: t("Protocol") },
      sm_type: { label: t("SM Type") },
      store_name: { label: "Store name" },
      software: { label: t("Software") },
      user: { label: t("User") }
    }
  }
}
