import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"

import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"

import { FormContent } from "hivolution-front-utils"
import { useAuth } from "oidc-react"

import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import { useNavigate } from "react-router-dom"

import { add, getSpaces } from "../api"
import { Equipment } from "../models"
import { site } from "/utils/store/site"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])

  const [data, setData] = useState({
    site: siteStore.id
  })
  const [errors, setErrors] = useState({})
  const [nodes, setNodes] = useState([])
  const [gateways, setGateways] = useState([])
  const [spaces, setSpaces] = useState([])
  const [equipmentTypes, setEquipmentTypes] = useState([])
  const [equipmentSubTypes, setEquipmentSubTypes] = useState([])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))

    siteStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: t("Detail"), url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Building"), url: `/sites/${siteStore.id}/assets/building/equipments/` },
          { label: t("Equipments"), url: `/sites/${siteStore.id}/assets/building/equipments/` },
          { label: t("Create"), url: `/sites/${siteStore.id}/assets/building/equipments/create/` }
        ])
      )

    const retrieveNodes = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteStore.id}/nodes/`, auth.userData.access_token)()
        .then(results => {
          setNodes(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getNodes(siteStore.id, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setNodes(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    siteStore.id && retrieveNodes()

    const retrieveGateways = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`connectivity/sites/${siteStore.id}/gateways/`, auth.userData.access_token)()
        .then(results => {
          setGateways(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getGateways(siteStore.id, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setGateways(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }

    siteStore.id && retrieveGateways()

    const retrieveSpaces = async () => {
      await getSpaces(siteStore.id, auth.userData.access_token)
        .then(response => {
          setSpaces(response.data)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    siteStore.id && retrieveSpaces()
  }, [siteStore.id])

  useEffect(() => {
    const retrieveEquipmentTypes = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest("asset/equipment_types/", auth.userData.access_token)()
        .then(results => {
          setEquipmentTypes(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getEquipmentTypes(auth.userData.access_token).then(response => {
        if (response.data.results) {
          setEquipmentTypes(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    siteStore.id && retrieveEquipmentTypes()
  }, [siteStore.id])

  useEffect(() => {
    const retrieveEquipmentSubTypes = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/equipment_subtypes/?equipment_type=${data.equipment_type}`, auth.userData.access_token)()
        .then(results => {
          setEquipmentSubTypes(results)
          setData({
            ...data,
            ...{ equipment_subtype: null }
          })
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getEquipmentSubTypes(data.equipment_type, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setEquipmentSubTypes(response.data.results)
          setData({
            ...data,
            ...{ equipment_subtype: null},
          })
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    if (data.equipment_type) retrieveEquipmentSubTypes()
    else setEquipmentSubTypes([])
  }, [data.equipment_type])

  const create = async function (e) {
    e.preventDefault()

    siteStore.id &&
      (await add(siteStore.id, data, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Create", t("The equipment has been created."))]))
          navigate(`/sites/${siteStore.id}/assets/building/equipments/${response.data.id}`)
        })
        .catch(error => {
          if (error.response.data) {
            const errorsEquipment = error.response.data
            const errorsEquipCommInfo = {
              ...(errorsEquipment.gateway ? { gateway: errorsEquipment.gateway } : {}),
              ...(errorsEquipment.node ? { node: errorsEquipment.node } : {}),
              ...errorsEquipment.equip_comm_info
            }
            setErrors({
              ...errorsEquipment,
              ...{ equip_comm_info: errorsEquipCommInfo }
            })
          }
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        }))
  }

  return (
    <>
      {data && (
        <>
          <div className="base-content-title">
            <h2>{t("Create equipment")}</h2>
          </div>
          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={Equipment(nodes, gateways, spaces, equipmentTypes, equipmentSubTypes)}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <Button
                rounded
                severity="success"
                label={t("Save")}
                type="submit"
                icon="fa-solid fa-floppy-disk"
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}
