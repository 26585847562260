import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes"
import { removeStores } from "../api"
import { errorNotification, successNotification } from "/utils/notification.js"
import { updateBreadcrumb } from "/utils/store/breadcrumb.js"
import { addNotifications } from "/utils/store/notification.js"
import { updateSideBar } from "/utils/store/sidebar.js"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { paginatedRequest } from "/utils/api"

const DeleteBulk = () => {
  const auth = useAuth()
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["client"])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [objectNames, setObjectNames] = useState([])
  const [processing, setProcessing] = useState(false)
  const { gateway } = useContext(GatewayRoutesContext)
  const ids = searchParams.getAll("id")

  const getStores = async () => {
    console.log(searchParams.toString())
    await paginatedRequest(`connectivity/sites/${siteStore.id}/stores/?gateway=${gateway.id}&${searchParams.toString()}`, auth.userData.access_token)()
      .then(results => {
        setObjectNames(results.map(store => store.name))
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const bulkDelete = async () => {
    setProcessing(true)
    await removeStores(siteStore.id, ids, auth.userData.access_token)
      .then(({ status, statusText }) => {
        if (status === 200) {
          dispatch(addNotifications([successNotification("Delete", t("Stores has been removed."))]))
          navigate(`/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/`)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    getStores()
  }, [])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Connectivity"), url: `/sites/${siteStore.id}/connectivity/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` },
          { label: t("Stores"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/` },
          { label: t("Multi delete"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/multi-delete?${searchParams}` }
        ])
      )
  }, [siteStore.id])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <DeleteConfirmation
          objectType={t("Stores")}
          objectNames={objectNames}
          remove={bulkDelete}
          processing={processing}
          goBack={() => navigate(-1)}
        />
      )}
    </>
  )
}

export default DeleteBulk
