import * as React from "react"
import { Route, Routes } from "react-router-dom"

import EdgeBlockTemplateRoutes from "./edge-block-template/routes"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { updateSideBar } from "/utils/store/sidebar.js"

export default function OptimizationRoutes() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "parameters", siteId: null }))
  }, [])

  return (
    <Routes>
      <Route
        path="/edge-block-templates/*"
        element={<EdgeBlockTemplateRoutes />}
      />
    </Routes>
  )
}
