import React, { useState } from "react"
import { Route, Routes, useLocation, useParams } from "react-router-dom"

import { TabsLayout } from "hivolution-front-utils"
import { useTranslation } from "react-i18next"
import EquipmentRoutes from "./equipment/routes"
import SpaceRoutes from "./space/routes"
import PointRoutes from "./point/routes"

export default function BuildingRoutes() {
  /* Tabs to ListLayout component*/
  const location = useLocation()
  const { t } = useTranslation(["client"])
  const { siteId } = useParams()
  const [tabs, setTabs] = useState([
    { name: t("Spaces"), active: location.pathname.includes("spaces") ? true : false, url: `/sites/${siteId}/assets/building/spaces/` },
    { name: t("Equipments"), active: location.pathname.includes("equipments") ? true : false, url: `/sites/${siteId}/assets/building/equipments/` },
    { name: t("Points"), active: location.pathname.includes("points") ? true : false, url: `/sites/${siteId}/assets/building/points/` }
  ])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("Building")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="/equipments/*"
          element={<EquipmentRoutes />}
        />
        <Route
          index
          path="/spaces/*"
          element={<SpaceRoutes />}
        />
        <Route
          index
          path="/points/*"
          element={<PointRoutes />}
        />
      </Route>
    </Routes>
  )
}
