import { createSlice } from "@reduxjs/toolkit"

export const sidebarSlice = createSlice({
  name: "sidebar",

  initialState: {
    menu: {
      menuName: null,
      siteId: null
    }
  },

  reducers: {
    updateSideBar: (state, action) => {
      state.menu = action.payload
    }
  }
})

export const { updateSideBar } = sidebarSlice.actions
export const sidebar = state => state.sidebar.menu
