import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, FormContent, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, getClients, getPriceZones, getWeatherZones } from "../api"
import { SiteForm } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [priceZones, setPriceZones] = useState([])
  const [weatherZones, setWeatherZones] = useState([])
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [data, setData] = useState({ active: false, synchronise_legacy: false })
  const [errors, setErrors] = useState({})

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const create = async e => {
    e.preventDefault()
    setProcessing(true)
    await add(data, auth.userData.access_token)
      .then(({ data }) => {
        dispatch(addNotifications([successNotification("Create", t("The site has been created."))]))
        data && data.id && navigate(`/sites/${data.id}`)
      })
      .catch(error => {
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getPZ = async () => {
    await getPriceZones(auth.userData.access_token)
      .then(({ data }) => {
        data && setPriceZones(data)
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification("Site", t("Error loading price zones"))]))
        console.error(message)
      })
  }

  const getCLI = async () => {
    await getClients(auth.userData.access_token)
      .then(({ data }) => {
        data && setClients(data)
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification("Site", t("Error loading clients"))]))
        console.error(message)
      })
  }

  const getWZ = async countryCode => {
    await getWeatherZones(countryCode, auth.userData.access_token)
      .then(({ data }) => {
        setWeatherZones(data)
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification("Weather zone", "Error loading weather zone")]))
        console.error(message)
      })
  }

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { label: t("Sites"), url: "/sites/" },
        { label: t("Create"), url: "/sites/create/" }
      ])
    )

    Promise.all([getPZ(), getCLI()]).finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    data.country && getWZ(data.country)
  }, [data.country])

  return (
    <>
      {data && (
        <BaseLayout title={t("Create site")}>
          {loading ? (
            <Loader />
          ) : (
            <form
              className="form-view"
              onSubmit={create}
            >
              <FormContent
                model={SiteForm(priceZones, weatherZones, clients)}
                initial={data}
                data={data}
                setData={setData}
                errors={errors}
              />
              <div className="action-buttons-wrapper">
                <Button
                  rounded
                  severity="success"
                  label={t("Save")}
                  type="submit"
                  icon="fa-solid fa-floppy-disk"
                  disabled={processing}
                />
                <Button
                  rounded
                  severity="info"
                  label={t("Cancel")}
                  icon="fa-solid fa-xmark"
                  onClick={goBack}
                  disabled={processing}
                />
              </div>
            </form>
          )}
        </BaseLayout>
      )}
    </>
  )
}
