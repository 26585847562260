import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { DeleteConfirmation } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["client"])
  const { siteId, flexId, scheduleId } = useParams()

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    await remove(flexId, scheduleId, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The proposed curtail schedule has been removed."))])
        navigate(`/sites/${siteId}/optimization/flexibility/${flexId}/proposed-curtail-schedules/`)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))

    dispatch(
      updateBreadcrumb([
        { label: t("Sites"), url: "/sites/" },
        { label: t("Detail"), url: `/sites/${siteId}/` },
        { label: t("Optimization"), url: `/sites/${siteId}/optimization/` },
        { label: t("Flexibility"), url: `/sites/${siteId}/optimization/flexibility/` },
        { label: t("Proposed curtail schedules"), url: `/sites/${siteId}/optimization/flexibility/${flexId}/proposed-curtail-schedules/` },
        { label: t("Detail"), url: `/sites/${siteId}/optimization/flexibility/${flexId}/proposed-curtail-schedules/${scheduleId}/` },
        { label: t("Delete"), url: `/sites/${siteId}/optimization/flexibility/${flexId}/proposed-curtail-schedules/${scheduleId}/delete/` }
      ])
    )
  }, [])

  return (
    <>
      <DeleteConfirmation
        objectType={t("Load curtail schedule")}
        remove={removeObject}
        goBack={() => navigate(-1)}
      />
    </>
  )
}
