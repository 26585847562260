import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { commonsFields, commonsOverview, t } from "./commons"

export const SMIO = nodes => {
  return {
    ...commonsFields(nodes),
    ...{
      point: {
        label: t("Point"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          showButtons: true
        }
      },
      mod: {
        label: t("Mod"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          showButtons: true
        }
      }
    }
  }
}

export const SMIOOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      point: { label: t("Point") },
      mod: { label: t("Mod") }
    }
  }
}

export const SMMeter = nodes => {
  return {
    ...commonsFields(nodes),
    ...{
      tag: {
        label: t("Tag"),
        field: InputText,
        options: {}
      }
    }
  }
}

export const SMMeterOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      tag: { label: t("Tag") }
    }
  }
}

export const SMController = nodes => {
  return {
    ...commonsFields(nodes),
    ...{
      writable: {
        label: t("Writable"),
        field: InputSwitch,
        options: {}
      },
      tag: {
        label: t("Tag"),
        field: InputText,
        options: {}
      },
      cid: {
        label: t("Cid"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      },
      vid: {
        label: t("Vid"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      }
    }
  }
}

export const SMControllerOverview = nodes => {
  return {
    ...commonsOverview(nodes),
    ...{
      writable: { label: t("Writable") },
      tag: { label: t("Tag") },
      cid: { label: t("Cid") },
      vid: { label: t("Vid") }
    }
  }
}
