import { InputNumber } from "primereact/inputnumber"
import { commonsFields, commonsOverview, t } from "./commons"

export const ModbusNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      slave_id: {
        label: t("Slave ID"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 247,
          showButtons: true
        }
      },
      response_timeout: {
        label: t("Response timeout"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      }
    }
  }
}

export const ModbusOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      slave_id: { label: t("Slave ID") },
      response_timeout: { label: t("Response timeout") }
    }
  }
}
