import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { FormContent } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { add } from "../api"
import { EnergyDeliveryPoint } from "../models"
import { REQUEST_DEFAULT_LIMIT } from "/utils/constants/api.js"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const { siteId } = useParams()
  const [data, setData] = useState({ site: siteId, equivalent_metering_terms: [] })
  const [errors, setErrors] = useState({})
  const [distributors, setDistributors] = useState([])
  const [transmissionCompanies, setTransmissionCompanies] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingDistributors, setLoadingDistributors] = useState(true)
  const [loadingTransmissions, setLoadingTransmissions] = useState(true)

  const getDC = async () => {
    setLoadingDistributors(true)
    await paginatedRequest(`energy/energy_companies/?company_type=Distributor&limit=${REQUEST_DEFAULT_LIMIT}&offset=0`, auth.userData.access_token)()
      .then(results => {
        setDistributors(results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Energy delivery point", message)]))
      })
      .finally(() => setLoadingDistributors(false))
  }

  const getTC = async () => {
    setLoadingTransmissions(true)
    await paginatedRequest(`energy/energy_companies/?company_type=Transmission&limit=${REQUEST_DEFAULT_LIMIT}&offset=0`, auth.userData.access_token)()
      .then(results => {
        setTransmissionCompanies(results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Energy delivery point", message)]))
      })
      .finally(() => setLoadingTransmissions(false))
  }

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)
    await add(siteId, data, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Energy delivery point", t("The energy delivery point has been created."))]))
        navigate(`/sites/${siteId}/energy/energy-delivery-points/${response.data.id}`)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Energy delivery point", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))

      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Energy delivery points"), url: `/sites/${siteStore.id}/energy/energy-delivery-points/` },
          { label: t("Create"), url: `/sites/${siteStore.id}/energy/energy-delivery-points/create/` }
        ])
      )

      getDC()
      getTC()
    }
  }, [siteStore.id])

  return (
    <>
      {data && (
        <>
          <div className="base-content-title">
            <h2>{t("Create energy delivery point")}</h2>
          </div>
          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={EnergyDeliveryPoint(distributors, transmissionCompanies, loadingDistributors, loadingTransmissions)}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <Button
                rounded
                severity="success"
                disabled={processing || loadingDistributors || loadingTransmissions}
                label={t("Save")}
                type="submit"
                icon="fa-solid fa-floppy-disk"
              />
              <Button
                rounded
                severity="info"
                disabled={processing || loadingDistributors || loadingTransmissions}
                label={t("Cancel")}
                icon="fa-solid fa-xmark"
                onClick={goBack}
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}
