import axios from "axios"

const header = token => ({
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Authorization": `Bearer ${token}`
  }
})

const paginatedRequest = (url, token) => {
  return function paginatedFetcher(next, obj_list = []) {
    return axios.get(next ? next : `${window.BACKEND_URL}/${url}`, header(token)).then(({ data }) => {
      obj_list.push(...data.results)
      if (!data.next) return obj_list
      return paginatedFetcher(data.next, obj_list)
    })
  }
}

const paginatedRequestFlex = (url, token) => {
  return function paginatedFetcher(next, obj_list = []) {
    return axios.get(next ? next : `${window.BACKEND_FLEX_URL}/${url}`, header(token)).then(({ data }) => {
      obj_list.push(...data.results)
      if (!data.next) return obj_list
      return paginatedFetcher(data.next, obj_list)
    })
  }
}

export { header, paginatedRequest, paginatedRequestFlex }
