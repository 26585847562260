{
  "Action": "Action",
  "Back to edge block template list": "Retour à la liste des edge block templates",
  "Condition": "Condition",
  "Create": "Création",
  "Create edge block template": "Créer un Edge block template",
  "Delete": "Suppression",
  "Description": "Description",
  "Detail": "Détail",
  "Edge block template": "Edge block template",
  "Edge block templates": "Edge block templates",
  "Home": "Accueil",
  "Name": "Nom",
  "Priority": "Priorité",
  "Save": "Sauvegarder",
  "The edge block template has been created.": "L'edge block template a été créé.",
  "The edge block template has been removed": "L'edge block template a été supprimé",
  "The edge block template has been updated.": "L'edge block template a été mis à jour.",
  "Updated by": "Mis à jour par"
}
