import { TabsLayout } from "hivolution-front-utils"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation, useParams } from "react-router-dom"
import NetworkRoutes from "./network/routes"
import AutomationNodeRoutes from "./automation-node/routes"
import AutomationPointRoutes from "./automation-point/routes"

export default function CommunicationRoutes() {
  /* Tabs to ListLayout component*/
  const location = useLocation()
  const { t } = useTranslation(["client"])
  const { siteId } = useParams()
  const [tabs, setTabs] = useState([
    { name: t("Networks"), active: location.pathname.includes("networks") ? true : false, url: `/sites/${siteId}/assets/communication/networks/` },
    { name: t("Automation nodes"), active: location.pathname.includes("automation-nodes") ? true : false, url: `/sites/${siteId}/assets/communication/automation-nodes/` },
    { name: t("Automation points"), active: location.pathname.includes("automation-points") ? true : false, url: `/sites/${siteId}/assets/communication/automation-points/` }
  ])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("Communication")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="/networks/*"
          element={<NetworkRoutes />}
        />
        <Route
          index
          path="/automation-nodes/*"
          element={<AutomationNodeRoutes />}
        />
        <Route
          index
          path="/automation-points/*"
          element={<AutomationPointRoutes />}
        />
      </Route>
    </Routes>
  )
}
