import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar.js"
import { BaseLayout, Loader, OverView } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { addContract, addSavingShare, getClients, getContracts, getPriceZones, getSavingShares, getWeatherZones, update, updateContract, updateSavingShare } from "../api"
import { SiteAddress, SiteAddressForm, SiteContract, SiteContractForm, SiteOverview, SiteOverviewForm } from "../models"
import { SiteContentContext } from "/apps/client/zone/routes.js"
import { TabPanel, TabView } from "primereact/tabview"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [contractObject, setContractObject] = useState({})
  const [contractData, setContractData] = useState({})
  const [contract, setContract] = useState()
  const [savingShare, setSavingShare] = useState()
  const [errors, setErrors] = useState({})
  const [contractErrors, setContractErrors] = useState({})
  const [processing, setProcessing] = useState(false)
  const [processingContractData, setProcessingContractData] = useState(false)
  const [priceZones, setPriceZones] = useState([])
  const [weatherZones, setWeatherZones] = useState([])
  const [clients, setClients] = useState([])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingContractData, setLoadingContractData] = useState(false)
  const [active, setActive] = useState(0)
  const { site, retrieveDetail } = useContext(SiteContentContext)

  const onTabChange = e => {
    setActive(e.index)
    setEdit(false)
  }

  const getPZ = async () => {
    await getPriceZones(auth.userData.access_token)
      .then(response => {
        setPriceZones(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getCLI = async () => {
    await getClients(auth.userData.access_token)
      .then(response => {
        setClients(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getWZ = async countryCode => {
    await getWeatherZones(countryCode, auth.userData.access_token)
      .then(response => {
        setWeatherZones(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getSiteContract = async () => {
    const response = await getContracts(site.id, auth.userData.access_token).catch(error => dispatch(addNotifications([errorNotification("Contract", error.message)])))
    return response && response.data && response.data.results && !!response.data.results.length && response.data.results[0]
  }

  const getSiteSavingShare = async id => {
    const response = await getSavingShares(id, auth.userData.access_token).catch(error => dispatch(addNotifications([errorNotification("Saving share", error.message)])))
    return response && response.data && response.data.results && !!response.data.results.length && response.data.results[0]
  }

  const updateSiteContractData = async () => {
    let contractInfos = {}

    setLoadingContractData(true)

    const contract = await getSiteContract()
    const savingShare = contract && contract.id && (await getSiteSavingShare(contract.id))

    if (contract) {
      const { name, forced_stop_hours, forced_stop_reset } = contract
      contractInfos = { ...contractInfos, name, forced_stop_hours, forced_stop_reset }
      setContract(contract)
    }

    if (savingShare) {
      const { bb_share, start_validity, end_validity } = savingShare
      contractInfos = { ...contractInfos, bb_share, start_validity: start_validity.split("T")[0], end_validity: end_validity.split("T")[0] }
      setSavingShare(savingShare)
    }

    setContractData(contractInfos)
    setContractObject(contractInfos)
    setLoadingContractData(false)
  }

  const updateDetails = async () => {
    updateSiteContractData()
    setObject(site)
    setData(site)
  }

  const updateContractDetail = () => {
    const data = {
      site_id: site.id,
      ...contractData
    }

    return contract ? updateContract(contract.id, data, auth.userData.access_token) : addContract(data, auth.userData.access_token)
  }

  const updateSavingShareDetail = contractId => {
    const { bb_share, start_validity, end_validity } = contractData
    const data = {
      contract_id: contractId,
      bb_share,
      start_validity,
      end_validity
    }

    return savingShare ? updateSavingShare(savingShare.id, data, auth.userData.access_token) : addSavingShare(data, auth.userData.access_token)
  }

  const updateObject = async function (e) {
    e.preventDefault()
    setLoading(true)
    await update(site.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Site", t("The site has been updated."))]))
        retrieveDetail()
        updateDetails()
        setEdit(false)
      })
      .catch(error => {
        error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Site", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const updateContractData = async e => {
    e && e.preventDefault()
    setProcessingContractData(true)

    let contractErrorsData = {}
    let hasError = false

    const contract = await updateContractDetail().catch(error => {
      hasError = true
      error.response.data && (contractErrorsData = { ...contractErrorsData, ...error.response.data })
      dispatch(addNotifications([errorNotification("Contract", error.message)]))
    })

    contract &&
      contract.data &&
      contract.data.id &&
      (await updateSavingShareDetail(contract.data.id).catch(error => {
        hasError = true
        error.response.data && (contractErrorsData = { ...contractErrorsData, ...error.response.data })
        dispatch(addNotifications([errorNotification("Saving share", error.message)]))
      }))

    setProcessingContractData(false)

    if (hasError) {
      setContractErrors(contractErrorsData)
    } else {
      setEdit(false)
      updateSiteContractData()
    }
  }

  useEffect(() => {
    if (site && site.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: site.id }))
      updateDetails()
      setLoading(false)
    }
  }, [site])

  useEffect(() => {
    object &&
      object.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: object.pretty_name ?? object.name, url: `/sites/${object.id}/` }
        ])
      )
  }, [object])

  useEffect(() => {
    if (edit) {
      setProcessing(true)
      Promise.all([getPZ(), getCLI()]).finally(() => setProcessing(false))
    }
  }, [edit])

  useEffect(() => {
    data.country && getWZ(data.country)
  }, [data.country])

  return (
    <>
      {object ? (
        <>
          <BaseLayout title={object.pretty_name ?? object.name}>
            <TabView
              activeIndex={active}
              onTabChange={onTabChange}
            >
              <TabPanel header={t("Overview")}>
                <OverView
                  model={SiteOverviewForm(priceZones, clients)}
                  overviewModel={SiteOverview()}
                  object={object}
                  updateObject={updateObject}
                  data={data}
                  setData={setData}
                  errors={errors}
                  edit={edit}
                  loading={loading}
                  processing={processing}
                  setEdit={setEdit}
                  deleteUrl={`/sites/${object.id}/delete/`}
                />
              </TabPanel>
              <TabPanel header={t("Address")}>
                <OverView
                  model={SiteAddressForm(weatherZones)}
                  overviewModel={SiteAddress()}
                  object={object}
                  updateObject={updateObject}
                  data={data}
                  setData={setData}
                  errors={errors}
                  edit={edit}
                  loading={loading}
                  processing={processing}
                  setEdit={setEdit}
                  deleteUrl={`/sites/${object.id}/delete/`}
                />
              </TabPanel>
              <TabPanel header={t("Contract")}>
                <OverView
                  model={SiteContractForm()}
                  overviewModel={SiteContract()}
                  object={contractObject}
                  updateObject={updateContractData}
                  data={contractData}
                  setData={setContractData}
                  errors={contractErrors}
                  edit={edit}
                  loading={loadingContractData}
                  processing={processingContractData}
                  setEdit={setEdit}
                  deleteUrl={`/sites/${object.id}/delete/`}
                />
              </TabPanel>
            </TabView>
          </BaseLayout>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
