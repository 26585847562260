import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { Cards } from "hivolution-front-utils"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useParams } from "react-router-dom"
import BuildingRoutes from "./building/routes"
import CommunicationRoutes from "./communication/routes"
import GatewayRoutes from "/apps/client/connectivity/gateway/routes.js"

function AssetsHome() {
  const { siteId } = useParams()
  const { t } = useTranslation(["client"])
  const siteStore = useSelector(site)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))
  }, [siteId])

  useEffect(() => {
    siteStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` }
        ])
      )
  }, [siteStore.id])

  return (
    <>
      <Cards
        items={[
          { icon: "fa-solid fa-podcast", url: `/sites/${siteId}/assets/communication/networks/`, title: t("Communication") },
          { icon: "fa-regular fa-building", url: `/sites/${siteId}/assets/building/spaces/`, title: t("Building") },
          { icon: "fa-solid fa-tower-broadcast", url: `/sites/${siteId}/assets/gateways/`, title: t("Gateways") }
        ]}
      />
    </>
  )
}

export default function AssetRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<AssetsHome />}
      />
      <Route
        path="communication/*"
        element={<CommunicationRoutes />}
      />
      <Route
        path="building/*"
        element={<BuildingRoutes />}
      />
      <Route
        path="/gateways/*"
        element={<GatewayRoutes />}
      />
    </Routes>
  )
}
