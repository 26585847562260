{
  "Hardwares": "Hardwares",
  "Parameters": "Paramètres",
  "Energy_companies": "Sociétés d'energie",
  "Edge_block_templates": "Edge block templates",
  "Weather_zone": "Weather zone",
  "Price_zone": "Price zones",
  "Equipment_type": "Equipment type",
  "Sites": "Sites",
  "Optimization": "Optimisation",
  "Configuration": "Configuration",
  "Sign out": "Se déconnecter"
}
