import { paginatedRequest } from "/utils/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"

import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"

import { ListActions, ListLayout } from "hivolution-front-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"

import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import { Link, useNavigate, useSearchParams } from "react-router-dom"

import { exportEquipments, exportTemplate, getAllValues, importEquipments } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation(["client"])
  const hiddenFileInput = useRef(null)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [selected, setSelected] = useState([])
  const [selectedFilters, setSelectedFilters] = useState([])
  const selectedGateway = searchParams.get("gateway_name")

  const retrieve = async (queryParams = "") => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`asset/sites/${siteStore.id}/equipments/${queryParams}`, auth.userData.access_token)()
      .then(results => {
        setItems(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setLoading(false)
      })

    // TODO: uncomment when the API will no longer paginate
    /* await retrieveList(siteStore.id, auth.userData.access_token).then(response => {
      if (response.data) {
        setItems(response.data.results)
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const getFilterValues = async () => {
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "node_name", header: t("Node"), sortable: true },
    { field: "gateway_name", header: t("Gateway"), sortable: true },
    { field: "equipment_type_name", header: t("Type"), sortable: true },
    { field: "equipment_subtype_name", header: t("Subtype"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name") },
    { field: "node_name", header: t("Node") },
    { field: "gateway_name", header: t("Gateway") },
    { field: "space_name", header: t("Space") },
    { field: "equipment_type_name", header: t("Type") },
    { field: "equipment_subtype_name", header: t("Subtype") },
    { field: "communicable", header: t("Communicable") }
  ]

  const getDetailUrl = objectId => {
    return `/sites/${siteStore.id}/assets/building/equipments/${objectId}/`
  }

  const getDeleteUrl = objectId => {
    return `/sites/${siteStore.id}/assets/building/equipments/${objectId}/delete/`
  }

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const getExport = async function () {
    await exportEquipments(siteStore.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `equipments_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getTemplate = async function () {
    await exportTemplate(siteStore.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `template_equipments_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const postImport = async function ({ target }) {
    const file = target.files[0]

    if (file) {
      await importEquipments(siteStore.id, file, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Import", response.data)]))
          retrieve()
        })
        .catch(error => {
          if (error.response && error.response.headers && error.response.headers["content-type"] === "text/csv") {
            fileDownload(error.response.data, `ERROR_equipments_${datetimeStr()}.csv`)
            dispatch(addNotifications([errorNotification("Error import", t("An error occured during the import, see more details in the error report file."))]))
          } else {
            dispatch(addNotifications([errorNotification("Error import", error.message)]))
          }
        })
    }
  }

  const menuEntries = [
    {
      label: t("Import"),
      icon: "fa-solid fa-file-import",
      command: () => hiddenFileInput && hiddenFileInput.current.click()
    },
    {
      label: t("Export"),
      icon: "fa-solid fa-file-export",
      command: getExport
    },
    {
      label: t("Template"),
      icon: "fa-solid fa-file",
      command: getTemplate
    }
  ]

  useEffect(() => {
    if (selectedGateway) {
      setSelectedFilters(["gateway_name"])
      setFilters({ gateway_name: [selectedGateway] })
    }
  }, [selectedGateway])

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Building"), url: `/sites/${siteStore.id}/assets/building/equipments/` },
          { label: t("Equipments"), url: `/sites/${siteStore.id}/assets/building/equipments/` }
        ])
      )
      getFilterValues()
    }
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id) {
      setLoading(true)
      if (Object.keys(filters).length > 0) {
        let queryParams = "?"
        Object.keys(filters).forEach(key => {
          Object.values(filters[key]).forEach(value => {
            queryParams += `${key}=${value}&`
          })
        })
        retrieve(queryParams)
      } else {
        retrieve()
      }
    }
  }, [siteStore.id, filters])

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  return (
    <>
      <ListActions
        entries={menuEntries}
        filterBackend={true}
        filters={menuFilters}
        selectedFilters={selectedFilters}
      >
        <input
          className="hidden"
          ref={hiddenFileInput}
          type="file"
          onChange={postImport}
        />

        <Link to={`/sites/${siteStore.id}/assets/building/equipments/create/`}>
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Equipments")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        bulkDeleteUrl={`/sites/${siteStore.id}/assets/building/equipments/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        handleSelection={setSelected}
      />
    </>
  )
}
