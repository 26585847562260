import React, { useContext, useEffect, useState } from "react"

import { OverView } from "hivolution-front-utils"

import { useDispatch, useSelector } from "react-redux"

import { addNotifications } from "/utils/store/notification"

import { update, getSpaces } from "../api"
import { BacnetNode, BacnetOverview } from "../models/bacnet"
import { CarelNetworkNode, CarelNetworkOverview } from "../models/carelnetwork"
import { CarelNode, CarelOverview } from "../models/carel"
import { ModbusNode, ModbusOverview } from "../models/modbus"
import { OPCUANode, OPCUAOverview } from "../models/opcua"
import { SMControllerNode, SMControllerOverview } from "../models/smcontroller"
import { SMIONode, SMIOOverview } from "../models/smio"
import { SMMeterNode, SMMeterOverview } from "../models/smmeter"
import { SMNode, SMOverview } from "../models/sm"
import { useTranslation } from "react-i18next"
import { useAuth } from "oidc-react"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { updateSideBar } from "/utils/store/sidebar"
import { errorNotification, successNotification } from "/utils/notification"
import { paginatedRequest } from "/utils/api"
import Interfaces from "./Interfaces"
import { ExternalAPIOverview } from "../models/externalapi"
import { NodeRoutesContext } from "../routes"
import { site } from "/utils/store/site"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])

  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [spaces, setSpaces] = useState([])
  const [smNodes, setSMNodes] = useState([])
  const [carelNodes, setCarelNodes] = useState([])
  const { node, retrieveDetail } = useContext(NodeRoutesContext)

  useEffect(() => {
    if (node) {
      setLoading(false)
      setObject(node)
    }
  }, [node])

  useEffect(() => {
    const retrieveSMNodes = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteStore.id}/nodes/?type=smnode`, auth.userData.access_token)()
        .then(results => {
          setSMNodes(results)
          if (object.sm_node) {
            const smNode = results.find(node => node.id === object.sm_node)
            const obj = object
            obj.sm_node_label = smNode.name
            setObject(obj)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getSMNodes(siteId, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setSMNodes(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }

    const retrieveCarelNodes = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteStore.id}/nodes/?type=carelnode`, auth.userData.access_token)()
        .then(results => {
          setCarelNodes(results)
          if (object.carel_node) {
            const carelNode = results.find(node => node.id === object.carel_node)
            const obj = object
            obj.carel_node_label = carelNode.name
            setObject(obj)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getCarelNodes(siteId, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setCaremNodes(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }

    const retrieveSpaces = async () => {
      await getSpaces(siteStore.id, auth.userData.access_token)
        .then(response => {
          setSpaces(response.data)
          if (object.space) {
            const space = response.data.find(item => item.id === object.space)
            const obj = object
            obj.space_label = space.name
            setObject(obj)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }

    if (siteStore.id && object && ["smionode", "smcontrollernode", "smmeternode"].includes(object.type)) retrieveSMNodes()
    else setSMNodes([])

    if (siteStore.id && object && object.type === "carelnetworknode") retrieveCarelNodes()
    else setCarelNodes([])

    if (siteStore.id && object) retrieveSpaces()
  }, [siteStore.id, object])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      object &&
      object.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Communication"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: t("Automation nodes"), url: `/sites/${siteStore.id}/assets/communication/automation-nodes/` },
          { label: object.label ?? object.name, url: `/sites/${siteStore.id}/assets/communication/automation-nodes/${object.id}/` }
        ])
      )
  }, [siteStore.id, object])

  const updateObject = async function (e) {
    e.preventDefault()
    await update(siteStore.id, node.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The automation node has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getModel = nodeType => {
    switch (nodeType) {
      case "modbusnode":
        return ModbusNode(spaces)
      case "bacnetnode":
        return BacnetNode(spaces)
      case "smnode":
        return SMNode(spaces)
      case "smionode":
        return SMIONode(spaces, smNodes)
      case "smcontrollernode":
        return SMControllerNode(spaces, smNodes)
      case "smmeternode":
        return SMMeterNode(spaces, smNodes)
      case "opcuanode":
        return OPCUANode(spaces)
      case "carelnode":
        return CarelNode(spaces)
      case "carelnetworknode":
        return CarelNetworkNode(spaces, carelNodes)
      default:
        return {}
    }
  }

  const getOverviewModel = nodeType => {
    switch (nodeType) {
      case "modbusnode":
        return ModbusOverview()
      case "bacnetnode":
        return BacnetOverview()
      case "smnode":
        return SMOverview()
      case "smionode":
        return SMIOOverview()
      case "smcontrollernode":
        return SMControllerOverview()
      case "smmeternode":
        return SMMeterOverview()
      case "opcuanode":
        return OPCUAOverview()
      case "carelnode":
        return CarelOverview()
      case "carelnetworknode":
        return CarelNetworkOverview()
      case "externalapinode":
        return ExternalAPIOverview()
      default:
        return {}
    }
  }

  return (
    <>
      {object && siteStore.id && (
        <>
          <h1 className="title">
            {object.type} {object.name}
          </h1>
          <OverView
            object={object}
            model={data && getModel(data.type)}
            overviewModel={object && getOverviewModel(object.type)}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            setEdit={setEdit}
            deleteUrl={`/sites/${siteStore.id}/assets/communication/automation-nodes/${node.id}/delete/`}
          />

          <Interfaces
            siteId={siteStore.id}
            nodeId={object.id}
            node={object}
          />
        </>
      )}
    </>
  )
}
