import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"

import { addNotifications } from "/utils/store/notification"

import { FormContent } from "hivolution-front-utils/"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch } from "react-redux"

import { useNavigate } from "react-router-dom"
import { add } from "../api"

import { TSO } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["energy"])
  const [data, setData] = useState({ company_type: "Transmission" })
  const [errors, setErrors] = useState({})

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { label: t("Energy companies"), url: "/energy/energy-company/distributors/" },
        { label: t("TSO"), url: "/energy/energy-company/transmissions/" },
        { label: t("Create"), url: "/energy/energy-company/transmissions/create/" }
      ])
    )
  }, [])

  const create = async function (e) {
    e.preventDefault()

    await add(data, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Create", t("The TSO has been created."))]))
        navigate(`/energy/energy-company/transmissions/${response.data.id}`)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  return (
    <>
      {data && (
        <>
          <div className="base-content-title">
            <h2>{t("Create TSO")}</h2>
          </div>
          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={TSO()}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <Button
                rounded
                severity="success"
                label={t("Save")}
                type="submit"
                icon="fa-solid fa-floppy-disk"
              />
              <Button
                rounded
                severity="info"
                label={t("Cancel")}
                icon="fa-solid fa-xmark"
                onClick={goBack}
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}
