import { EnergyDeliveryPointRoutesContext } from "/apps/client/energy/energy-delivery-point/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListLayout, ListActions, Loader, OverView, BaseLayout, InnerTabView, FormContent } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { update } from "../api"
import { EnergyDeliveryPoint, EnergyDeliveryPointOverview, EquivalentMeteringTerm } from "../models"
import { TabPanel } from "primereact/tabview"
import { Button } from "primereact/button"
import { SortOrder } from "primereact/api"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [distributors, setDistributors] = useState([])
  const [transmissionCompanies, setTransmissionCompanies] = useState([])
  const [points, setPoints] = useState([])
  const [equivalentMeteringTerm, setEquivalentMeteringTerm] = useState([])
  const [edit, setEdit] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingPoints, setLoadingPoints] = useState(true)
  const [loadingDistributors, setLoadingDistributors] = useState(true)
  const [loadingTransmissions, setLoadingTransmissions] = useState(true)
  const [activeIndex, setActiveIndex] = useState(0)
  const [editEquivalentMetering, setEditEquivalentMetering] = useState(false)
  const [selectedEquivalentMetering, setSelectedEquivalentMetering] = useState([])
  const [meteringUpdate, setMeteringUpdate] = useState(false)
  const [processDelete, setProcessDelete] = useState(false)
  const { energyDeliveryPoint, retrieveDetail } = useContext(EnergyDeliveryPointRoutesContext)

  const equivalentMeteringTermsColumns = [
    { field: "gatewayPoint", header: t("Gateway point"), sortable: true },
    { field: "coef", header: t("Coef"), sortable: true }
  ]

  const onTabChange = e => {
    setEdit(false)
    setEditEquivalentMetering(false)
    setEquivalentMeteringTerm([])
    setActiveIndex(e.index)
  }

  const getPts = async () => {
    setLoadingPoints(true)
    await paginatedRequest(`asset/sites/${siteStore.id}/points/`, auth.userData.access_token)()
      .then(results => {
        setPoints(results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Points", message)]))
      })
      .finally(() => setLoadingPoints(false))

    // TODO: uncomment when the API will no longer paginate
    /*
    await getPoints(siteStore.id, auth.userData.access_token, offsetPoints)
      .then(response => {
        setOffsetPoints(offsetPoints + REQUEST_DEFAULT_LIMIT)
        setPoints(response.data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Energy delivery point", error.message)]))
      })
      .finally(() => setLoadingPoints(false))
    */
  }

  const getDC = async () => {
    setLoadingDistributors(true)
    await paginatedRequest("energy/energy_companies/?company_type=Distributor", auth.userData.access_token)()
      .then(results => {
        setDistributors(results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Distributor company", message)]))
      })
      .finally(() => setLoadingDistributors(false))
  }

  const getTC = async () => {
    setLoadingTransmissions(true)
    await paginatedRequest("energy/energy_companies/?company_type=Transmission", auth.userData.access_token)()
      .then(results => {
        setTransmissionCompanies(results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Transmission", message)]))
      })
      .finally(() => setLoadingTransmissions(false))
  }

  const processUpdate = async data => {
    setProcessing(true)

    await update(siteStore.id, energyDeliveryPoint.id, data, auth.userData.access_token)
      .then(async () => {
        dispatch(addNotifications([successNotification("Energy delivery point", t("The energy delivery point has been updated."))]))
        await retrieveDetail()
        setEquivalentMeteringTerm([])
        setSelectedEquivalentMetering([])
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Energy delivery point", message)]))
      })
      .finally(() => {
        setProcessing(false)
        setLoading(false)
        setMeteringUpdate(false)
        setEditEquivalentMetering(false)
      })
  }

  const updateObject = e => {
    e && e.preventDefault()
    processUpdate(data)
  }

  const editEquivalentMeteringItem = data => {
    setMeteringUpdate(true)
    setEquivalentMeteringTerm(data)
    setEditEquivalentMetering(true)
  }

  const deleteEquivalentMeteringItems = async items => {
    const terms = energyDeliveryPoint.equivalent_metering_terms.filter(({ gatewayPoint }) => !items.includes(gatewayPoint))

    setProcessDelete(true)
    await processUpdate({ ...data, equivalent_metering_terms: terms })
  }

  const updateEquivalentMeteringData = async () => {
    if (energyDeliveryPoint) {
      const exist = energyDeliveryPoint.equivalent_metering_terms && energyDeliveryPoint.equivalent_metering_terms.find(({ gatewayPoint }) => gatewayPoint === equivalentMeteringTerm.gatewayPoint)
      const terms = exist
        ? energyDeliveryPoint.equivalent_metering_terms.map(({ gatewayPoint, coef }) =>
            gatewayPoint === equivalentMeteringTerm.gatewayPoint
              ? {
                  gatewayPoint: equivalentMeteringTerm.gatewayPoint,
                  coef: equivalentMeteringTerm.coef
                }
              : { gatewayPoint, coef }
          )
        : energyDeliveryPoint.equivalent_metering_terms
          ? [...energyDeliveryPoint.equivalent_metering_terms, equivalentMeteringTerm]
          : [equivalentMeteringTerm]

      setData({ ...data, equivalent_metering_terms: terms })
    }
  }

  const equivalentMeteringHandleSelection = selections => {
    setSelectedEquivalentMetering(selections.map(item => item.gatewayPoint))
  }

  const equivalentMeteringBulkDelete = () => {
    deleteEquivalentMeteringItems(selectedEquivalentMetering)
  }

  const resetTermUpdate = () => {
    setData(object)
    setEquivalentMeteringTerm([])
    setMeteringUpdate(false)
    setEditEquivalentMetering(false)
  }

  const equivalentMeteringBodyTemplate = data => {
    const current = data.gatewayPoint
    const doDelete = async () => {
      setSelectedEquivalentMetering([current])
      deleteEquivalentMeteringItems([current])
    }

    return (
      <div className="p-datatable-action-buttons">
        {processDelete && selectedEquivalentMetering.includes(current) ? (
          <i className="fa-solid fa-spinner fa-spin" />
        ) : (
          <>
            <button
              disabled={processing}
              onClick={() => editEquivalentMeteringItem(data)}
            >
              <i className="fa-solid fa-pencil" />
            </button>
            <button
              disabled={processing}
              onClick={() => doDelete()}
            >
              <i className="fa-solid fa-trash-can" />
            </button>
          </>
        )}
      </div>
    )
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (energyDeliveryPoint) {
      setLoading(false)

      setObject(energyDeliveryPoint)
      setData(energyDeliveryPoint)
    }
  }, [energyDeliveryPoint])

  useEffect(() => {
    siteStore.id &&
      object &&
      object.meter_id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Energy delivery points"), url: `/sites/${siteStore.id}/energy/energy-delivery-points/` },
          { label: object.meter_id, url: `/sites/${siteStore.id}/energy/energy-delivery-points/${object.id}/` }
        ])
      )
  }, [siteStore.id, object])

  useEffect(() => {
    if (edit) {
      getDC()
      getTC()
    }
  }, [edit])

  useEffect(() => {
    editEquivalentMetering && !points.length && getPts()
  }, [editEquivalentMetering])

  useEffect(() => {
    equivalentMeteringTerm && equivalentMeteringTerm.gatewayPoint && updateEquivalentMeteringData()
  }, [equivalentMeteringTerm])

  return (
    <>
      {siteStore.id && object ? (
        <BaseLayout title={object.meter_id}>
          <InnerTabView
            activeIndex={activeIndex}
            onTabChange={onTabChange}
          >
            <TabPanel header={t("Overview")}>
              <OverView
                object={object}
                model={EnergyDeliveryPoint(distributors, transmissionCompanies, loadingDistributors, loadingTransmissions)}
                overviewModel={EnergyDeliveryPointOverview()}
                updateObject={updateObject}
                data={data}
                setData={setData}
                errors={errors}
                edit={edit}
                setEdit={setEdit}
                loading={loading}
                processing={processing || loadingDistributors || loadingTransmissions}
                deleteUrl={`/sites/${siteStore.id}/energy/energy-delivery-points/${object.id}/delete/`}
              />
            </TabPanel>
            <TabPanel header={t("Equivalent metering")}>
              {editEquivalentMetering ? (
                <>
                  <div className="field-wrapper">
                    <FormContent
                      model={EquivalentMeteringTerm(points, meteringUpdate, loadingPoints)}
                      data={equivalentMeteringTerm}
                      setData={setEquivalentMeteringTerm}
                      errors={{}}
                    />
                  </div>
                  <div className="action-buttons-wrapper">
                    <Button
                      rounded
                      type="submit"
                      severity="success"
                      onClick={updateObject}
                      disabled={processing}
                      label={t("Save")}
                      icon="fa-solid fa-floppy-disk"
                    />
                    <Button
                      rounded
                      severity="info"
                      onClick={resetTermUpdate}
                      disabled={processing}
                      label="Cancel"
                      icon="fa-solid fa-xmark"
                    />
                  </div>
                </>
              ) : (
                <>
                  <ListLayout
                    columns={equivalentMeteringTermsColumns}
                    dataKey="gatewayPoint"
                    sortField="gatewayPoint"
                    sortOrder={SortOrder.ASC}
                    value={object.equivalent_metering_terms}
                    objectName={t("Equivalent meterings")}
                    actionsBodyTemplate={equivalentMeteringBodyTemplate}
                    handleSelection={equivalentMeteringHandleSelection}
                    bulkDeleteAction={equivalentMeteringBulkDelete}
                  />

                  {!selectedEquivalentMetering.length && (
                    <ListActions align="right">
                      <Button
                        rounded
                        severity="info"
                        label={t("Add")}
                        icon="fa-regular fa-plus"
                        disabled={processing}
                        onClick={() => setEditEquivalentMetering(true)}
                      />
                    </ListActions>
                  )}
                </>
              )}
            </TabPanel>
          </InnerTabView>
        </BaseLayout>
      ) : (
        <Loader />
      )}
    </>
  )
}
