import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Dropdown, SelectModal, ArrayInput } from "hivolution-front-utils"

import i18n from "/utils/i18n"
import { InputSwitch } from "primereact/inputswitch"
import { InputNumber } from "primereact/inputnumber"

const t = str => i18n.t(str, { ns: "client" })

export const Point = (equipments, automationPoints, parameters, stores) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: t("Name"), field: InputText, options: { required: true } },
    description: { label: t("Description"), field: InputTextarea, options: {} },
    equipment: {
      label: t("Equipment"),
      field: SelectModal,
      options: {
        required: true,
        items: equipments,
        columns: [
          { field: "name", header: t("Name"), sortable: true },
          { field: "node_name", header: t("Node"), sortable: true },
          { field: "gateway_name", header: t("Gateway"), sortable: true }
        ],
        objectName: t("Equipments"),
        searchLabel: id => {
          let find = equipments.find(equipment => equipment.id === id)
          return find ? find.name : ""
        }
      }
    },
    automation_point: {
      label: t("Automation point"),
      field: SelectModal,
      options: {
        items: automationPoints,
        columns: [
          { field: "name", header: t("Name"), sortable: true },
          { field: "node_name", header: t("Node"), sortable: true },
          { field: "description", header: t("Description"), sortable: true }
        ],
        objectName: t("Automation points"),
        searchLabel: id => {
          let find = automationPoints.find(automationPoint => automationPoint.id === id)
          return find ? find.name : ""
        }
      },
      dependsOn: "equipment"
    },
    read: {
      label: t("Read"),
      field: InputSwitch
    },
    write: {
      label: t("Write"),
      field: InputSwitch
    },
    parameter: {
      label: t("Unit"),
      field: Dropdown,
      options: {
        options: parameters,
        optionLabel: "context_name",
        optionValue: "id"
      }
    },
    time_series_storage_info: {
      store_type: {
        label: t("Store type"),
        field: Dropdown,
        options: {
          options: [
            { label: t("Gauge"), value: "gauge" },
            { label: t("Status"), value: "status" }
          ],
          optionLabel: "label",
          optionValue: "value"
        }
      },
      store: {
        label: t("Store"),
        field: Dropdown,
        options: {
          options: stores,
          optionLabel: "name",
          optionValue: "id"
        },
        dependsOn: "store_type"
      },
      disable_storage: {
        label: t("Disable storage"),
        field: InputSwitch,
        dependsOn: "store_type"
      },
      linear_multiplier: {
        label: t("Linear multiplier"),
        field: InputNumber,
        options: { showButtons: true },
        dependsOn: "store_type",
        dependsOnValue: "gauge"
      },
      linear_constant: {
        label: t("Linear constant"),
        field: InputNumber,
        options: { showButtons: true },
        dependsOn: "store_type",
        dependsOnValue: "gauge"
      },
      acceptable_values: {
        field: ArrayInput,
        dependsOn: "store_type",
        dependsOnValue: "status",
        options: {
          columns: [
            { field: "original_point_value", header: t("Original point value") },
            { field: "gateway_point_value", header: t("Gateway point value") },
            { field: "gateway_point_text", header: t("Gateway point text") }
          ],
          modelForm: () => {
            return {
              original_point_value: {
                label: t("Original point value"),
                field: InputNumber,
                options: { showButtons: true, required: true }
              },
              gateway_point_value: {
                label: t("Gateway point value"),
                field: InputNumber,
                options: { showButtons: true, required: true }
              },
              gateway_point_text: {
                label: t("Gateway point text"),
                field: InputText
              }
            }
          },
          label: t("Acceptable values"),
          createLabel: t("Add values")
        }
      }
    }
  }
}

export const PointOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: t("Name") },
    description: { label: t("Description") },
    equipment_name: { label: t("Equipment") },
    automation_point_name: { label: t("Automation point") },
    read_write: { label: t("Read/Write") }
  }
}
