{
  "Hardwares": "Hardwares",
  "Parameters": "Parameters",
  "Energy_companies": "Energy companies",
  "Edge_block_templates": "Edge block templates",
  "Weather_zone": "Weather zone",
  "Price_zone": "Price zones",
  "Equipment_type": "Equipment type",
  "Sites": "Sites",
  "Optimization": "Optimization",
  "Configuration": "Configuration",
  "Sign out": "Sign out"
}
