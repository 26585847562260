import { paginatedRequest } from "/utils/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const [parameters, setParameters] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["connectivity"])

  useEffect(() => {
    dispatch(updateBreadcrumb([{ label: t("Parameters"), url: "/connectivity/parameters/" }]))

    const retrieve = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest("asset/parameters/", auth.userData.access_token)()
        .then(results => {
          setParameters(results)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await retrieveList(auth.userData.access_token).then(response => {
              if (response.data) {
                setParameters(response.data.results)
              }
              setLoading(false)
            }).catch((error) => {
              setLoading(false)
              dispatch(addNotifications([errorNotification("Error", error.message)]))
            }) */
    }
    retrieve()
  }, [])

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "unit", header: t("Unit"), sortable: true },
    { field: "quantity", header: t("Quantity"), sortable: true }
  ]

  return (
    <BaseLayout title={t("Parameters")}>
      <ListLayout
        objectName={t("Parameters")}
        columns={columns}
        value={parameters}
        loading={loading}
      />
    </BaseLayout>
  )
}
