import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { organization } from "/utils/store/organization"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import { Icon, Point } from "leaflet"
import icon from "static/images/marker.png"
import MarkerClusterGroup from "./MarkerClusterGroup"
import { useTranslation } from "react-i18next"
import { Button } from "primereact/button"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { listSites } from "../api"
import { useAuth } from "oidc-react"
import { addNotifications } from "/utils/store/notification.js"
import { errorNotification } from "/utils/notification.js"

const customMarkerIcon = new Icon({
  iconUrl: `/${icon}`,
  iconSize: new Point(33, 33, true)
})

const createClusterCustomIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: new Point(33, 33, true)
  })
}

export default function SitesMap() {
  const organizationStore = useSelector(organization)
  const { t } = useTranslation(["home"])
  const dispatch = useDispatch()
  const [sites, setSites] = useState([])
  const auth = useAuth()

  const retrieveSites = async () => {
    await listSites(organizationStore.id, auth.userData.access_token)
      .then(response => {
        setSites(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    if (organizationStore && organizationStore.id) {
      dispatch(
        updateBreadcrumb([
          { label: t("Organizations"), url: "/" },
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/` },
          { label: t("Map"), url: `/organizations/${organizationStore.id}/map/` }
        ])
      )
      retrieveSites()
    }
  }, [organizationStore])

  return (
    <div>
      <MapContainer
        className="map-container"
        center={[51.505, -0.09]}
        zoom={5}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup
          chunkedLoading
          iconCreateFunction={createClusterCustomIcon}
        >
          {sites.map((site, index) => {
            return (
              <Marker
                key={index}
                icon={customMarkerIcon}
                position={[site.latitude, site.longitude]}
              >
                <Popup>
                  <div className="popup-site">
                    <h2>
                      {t("Site")} {site.name}
                    </h2>
                    <p>
                      {t("Address")} : {`${site.address}, ${site.zip_code} ${site.city}, ${site.country}`}
                    </p>
                    <Button
                      className="popup-button"
                      rounded
                      severity="info"
                      label={t("Open")}
                      icon="fas fa-door-open"
                      onClick={() => console.log("open site")}
                    />
                  </div>
                </Popup>
              </Marker>
            )
          })}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  )
}
