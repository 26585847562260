import i18n from "/utils/i18n"
import { DateInput, TimeInput } from "hivolution-front-utils"
import { InputSwitch } from "primereact/inputswitch"
import { InputNumber } from "primereact/inputnumber"

export const ProposedCurtailSchedule = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    curtail_start_date: {
      label: i18n.t("Start date", { ns: "client" }),
      field: DateInput,
      options: { required: true }
    },
    curtail_start_time: {
      label: i18n.t("Start time", { ns: "client" }),
      field: TimeInput,
      options: { required: true }
    },
    curtail_end_date: {
      label: i18n.t("End date", { ns: "client" }),
      field: DateInput,
      options: { required: true }
    },
    curtail_end_time: {
      label: i18n.t("End time", { ns: "client" }),
      field: TimeInput,
      options: { required: true }
    },
    pre_cooling_start_date: {
      label: i18n.t("Pre-cooling start date", { ns: "client" }),
      field: DateInput,
      options: { required: true }
    },
    pre_cooling_start_time: {
      label: i18n.t("Pre-cooling start time", { ns: "client" }),
      field: TimeInput,
      options: { required: true }
    },
    post_cooling_end_date: {
      label: i18n.t("Post-cooling end date", { ns: "client" }),
      field: DateInput,
      options: { required: true }
    },
    post_cooling_end_time: {
      label: i18n.t("Post-cooling end time", { ns: "client" }),
      field: TimeInput,
      options: { required: true }
    },
    curtail_capacity: {
      label: i18n.t("Curtail capacity", { ns: "client" }),
      field: InputNumber,
      options: { required: true, showButtons: true }
    },
    execution_desired: {
      label: i18n.t("Execution desired", { ns: "client" }),
      field: InputSwitch
    },
    test: {
      label: i18n.t("Test Schedule", { ns: "client" }),
      field: InputSwitch
    }
  }
}
