import { InputText } from "primereact/inputtext"
import { CountryDropdown, Dropdown, DateInput } from "hivolution-front-utils"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import i18n from "/utils/i18n"

const SiteOverviewForm = (priceZones, clients) => ({
  name: {
    label: i18n.t("Name", { ns: "client" }),
    field: InputText,
    options: {
      required: true,
      keyfilter: /[\w\d-.]/
    }
  },
  pretty_name: {
    label: i18n.t("Pretty name", { ns: "client" }),
    field: InputText,
    options: { required: true }
  },
  country: {
    label: i18n.t("Country", { ns: "client" }),
    field: CountryDropdown,
    options: { required: true }
  },
  synchronize_legacy: {
    label: i18n.t("Synchronize legacy", { ns: "client" }),
    field: InputSwitch,
    options: {}
  },
  client_id: {
    label: i18n.t("Client", { ns: "client" }),
    field: Dropdown,
    options: {
      required: true,
      options: clients,
      filter: true,
      optionLabel: "name",
      optionValue: "id",
      placeholder: i18n.t("Select a client", { ns: "client" })
    }
  },
  price_zone_id: {
    label: i18n.t("Price zone", { ns: "client" }),
    field: Dropdown,
    options: {
      required: true,
      options: priceZones,
      filter: true,
      optionLabel: "name",
      optionValue: "id",
      placeholder: i18n.t("Select a price zone", { ns: "client" })
    }
  },
  active: {
    label: i18n.t("Active", { ns: "client" }),
    field: InputSwitch,
    options: {}
  }
})

const SiteAddressForm = weatherZones => ({
  address: {
    label: i18n.t("Address", { ns: "client" }),
    field: InputText,
    options: { required: true }
  },
  zip_code: {
    label: i18n.t("Zip code", { ns: "client" }),
    field: InputText,
    options: {
      required: true,
      keyfilter: "pint"
    }
  },
  city: {
    label: i18n.t("City", { ns: "client" }),
    field: InputText,
    options: { required: true }
  },
  latitude: {
    label: i18n.t("Latitude", { ns: "client" }),
    field: InputNumber,
    options: {
      required: true,
      min: -90,
      max: 90,
      step: 0.0001,
      showButtons: true,
      maxFractionDigits: 4
    }
  },
  longitude: {
    label: i18n.t("Longitude", { ns: "client" }),
    field: InputNumber,
    options: {
      required: true,
      min: -180,
      max: 180,
      step: 0.001,
      showButtons: true,
      maxFractionDigits: 4
    }
  },
  altitude: {
    label: i18n.t("Altitude", { ns: "client" }),
    field: InputNumber,
    options: {
      required: true,
      step: 0.001,
      showButtons: true,
      maxFractionDigits: 4
    }
  },
  weather_zone_id: {
    label: i18n.t("Weather zone", { ns: "client" }),
    field: Dropdown,
    options: {
      required: true,
      options: weatherZones,
      optionLabel: "city_name",
      optionValue: "id",
      filter: true,
      virtualScrollerOptions: { itemSize: 38 },
      placeholder: i18n.t("Select a weather zone", { ns: "client" })
    }
  }
})

const SiteForm = (priceZones, weatherZones, clients) => ({
  ...SiteOverviewForm(priceZones, clients),
  ...SiteAddressForm(weatherZones)
})

const SiteContractForm = () => ({
  name: {
    label: i18n.t("Name", { ns: "client" }),
    field: InputText,
    options: {
      required: true,
      keyfilter: /[\w\d-.]/
    }
  },
  forced_stop_hours: {
    label: i18n.t("Forced stop hours", { ns: "client" }),
    field: InputNumber,
    options: {
      required: true,
      showButtons: true,
      min: 1
    }
  },
  forced_stop_reset: {
    label: i18n.t("Forced stop reset", { ns: "client" }),
    field: DateInput,
    options: { required: true }
  },
  bb_share: {
    label: i18n.t("Percent of share economics", { ns: "client" }),
    field: InputNumber,
    options: {
      required: true,
      showButtons: true,
      min: 1,
      max: 99
    }
  },
  start_validity: {
    label: i18n.t("Start validity", { ns: "client" }),
    field: DateInput,
    options: { required: false }
  },
  end_validity: {
    label: i18n.t("End validity", { ns: "client" }),
    field: DateInput,
    options: { required: false }
  }
})

const SiteOverview = () => ({
  pretty_name: { label: i18n.t("Pretty name", { ns: "client" }) },
  name: { label: i18n.t("Name", { ns: "client" }) },
  country: { label: i18n.t("Country", { ns: "client" }) },
  client_name: { label: i18n.t("Client", { ns: "client" }) },
  price_zone_id: { label: i18n.t("Price zone", { ns: "client" }) }, // replace by price zone name
  synchronize_legacy: { label: i18n.t("Synchronize legacy", { ns: "client" }) },
  active: { label: i18n.t("Active", { ns: "client" }) }
})

const SiteAddress = () => ({
  address: { label: i18n.t("Address", { ns: "client" }) },
  zip_code: { label: i18n.t("Zip code", { ns: "client" }) },
  city: { label: i18n.t("City", { ns: "client" }) },
  latitude: { label: i18n.t("Latitude", { ns: "client" }) },
  longitude: { label: i18n.t("Longitude", { ns: "client" }) },
  altitude: { label: i18n.t("Altitude", { ns: "client" }) },
  weather_zone_id: { label: i18n.t("Weather zone", { ns: "client" }) } // replace by weather zone name
})

const SiteContract = () => ({
  name: { label: i18n.t("Name", { ns: "client" }) },
  forced_stop_hours: { label: i18n.t("Forced stop hours", { ns: "client" }) },
  forced_stop_reset: { label: i18n.t("Forced stop reset", { ns: "client" }) },
  bb_share: { label: i18n.t("Percentage of share economics", { ns: "client" }) },
  start_validity: { label: i18n.t("Start validity", { ns: "client" }) },
  end_validity: { label: i18n.t("End validity", { ns: "client" }) }
})

export { SiteForm, SiteAddressForm, SiteOverviewForm, SiteContractForm, SiteOverview, SiteAddress, SiteContract }
