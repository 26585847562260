import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Dropdown } from "hivolution-front-utils"

import i18n from "/utils/i18n"

export const t = str => i18n.t(str, { ns: "client" })

export const commonsFields = nodes => {
  return {
    name: { label: t("Name"), field: InputText, options: { required: true } },
    node: {
      label: t("Node"),
      field: Dropdown,
      options: {
        required: true,
        options: nodes,
        optionLabel: "name",
        optionValue: "id",
        filter: true
      }
    },
    description: { label: t("Description"), field: InputTextarea, options: {} }
  }
}

export const commonsOverview = () => {
  return {
    name: { label: t("Name") },
    node: { label: t("Node") },
    description: { label: t("Description") }
  }
}
