import ConnectorInstanceRoutes from "/apps/client/connectivity/connector-instance/routes"
import NetworkInterfaceRoutes from "/apps/client/connectivity/network-interface/routes"
import StoreRoutes from "/apps/client/connectivity/store/routes"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { Loader, TabsLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { createContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useParams } from "react-router-dom"
import { retrieve } from "./api"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import List from "./components/List"

const GatewayRoutesContext = createContext(null)

const GatewayLayout = () => {
  const siteStore = useSelector(site)
  const { gatewayId } = useParams()
  const auth = useAuth()
  const dispatch = useDispatch()
  const { t } = useTranslation(["client"])
  const [gateway, setGateway] = useState(null)

  const [tabs, setTabs] = useState()

  const retrieveDetail = async () => {
    retrieve(siteStore.id, gatewayId, auth.userData.access_token)
      .then(response => {
        const gatewayData = {
          ...response.data,
          ...{
            hardware: response.data.hardware && response.data.hardware.id,
            hardware_label: response.data.hardware && response.data.hardware.label,
            hardware_info: response.data.hardware
          }
        }
        setGateway(gatewayData)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    if (siteStore.id && gatewayId) {
      setTabs([
        {
          name: t("Base informations"),
          active: location.pathname.endsWith(`/gateways/${gatewayId}/`),
          url: `/sites/${siteStore.id}/assets/gateways/${gatewayId}/`
        },
        {
          name: t("Network interfaces"),
          active: location.pathname.includes("network-interfaces"),
          url: `/sites/${siteStore.id}/assets/gateways/${gatewayId}/network-interfaces/`
        },
        {
          name: t("Connector instances"),
          active: location.pathname.includes("connector-instances"),
          url: `/sites/${siteStore.id}/assets/gateways/${gatewayId}/connector-instances/`
        },
        {
          name: t("Stores"),
          active: location.pathname.includes("stores"),
          url: `/sites/${siteStore.id}/assets/gateways/${gatewayId}/stores/`
        }
      ])

      retrieveDetail()
    }
  }, [siteStore.id, gatewayId])

  return (
    <>
      <GatewayRoutesContext.Provider value={{ gateway, retrieveDetail }}>
        {gateway ? (
          <TabsLayout
            title={`${t("Gateway")} ${gateway.name}`}
            tabs={tabs}
            setTabs={setTabs}
          />
        ) : (
          <Loader />
        )}
      </GatewayRoutesContext.Provider>
    </>
  )
}

export { GatewayRoutesContext }
export default function GatewayRoutes() {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["client"])

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))

      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` }
        ])
      )
    }
  }, [siteStore.id])

  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":gatewayId"
        element={<GatewayLayout />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
        <Route
          path="network-interfaces/*"
          element={<NetworkInterfaceRoutes />}
        />
        <Route
          path="connector-instances/*"
          element={<ConnectorInstanceRoutes />}
        />
        <Route
          path="stores/*"
          element={<StoreRoutes />}
        />
      </Route>
    </Routes>
  )
}
