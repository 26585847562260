import { AggregatorRoutesContext } from "/apps/energy/energy-company/aggregator/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["energy"])
  const { aggregator } = useContext(AggregatorRoutesContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    await remove(aggregator.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The aggregator has been removed."))])
        navigate("/energy/energy-company/aggregators/")
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    aggregator &&
      aggregator.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Energy companies"), url: "/energy/energy-company/distributors/" },
          { label: t("Aggregators"), url: "/energy/energy-company/aggregators/" },
          { label: aggregator.name, url: `/energy/energy-company/aggregators/${aggregator.id}/` },
          { label: t("Delete"), url: `/energy/energy-company/aggregators/${aggregator.id}/delete/` }
        ])
      )
  }, [aggregator])

  return (
    <>
      {aggregator && aggregator.name ? (
        <DeleteConfirmation
          objectType={t("Aggregator")}
          objectNames={[aggregator.name]}
          remove={removeObject}
          goBack={() => navigate(-1)}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}
