import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { Dropdown, SelectModal } from "hivolution-front-utils"
import { commonsFields, commonsOverview, t } from "./commons"

export const SMIONode = (spaces, smNodes) => {
  return {
    ...commonsFields(spaces),
    ...{
      sm_node: {
        label: t("System manager"),
        field: SelectModal,
        options: {
          items: smNodes,
          columns: [
            { field: "name", header: t("Name") },
            { field: "model", header: t("Model") },
            { field: "manufacturer", header: t("Manufacturer") },
            { field: "proto", header: t("Protocol") },
            { field: "sm_type", header: t("SM Type") },
            { field: "software", header: t("Software") },
            { field: "user", header: t("User") }
          ],
          required: true,
          objectName: t("SM Nodes"),
          searchLabel: id => {
            let find = smNodes.find(node => node.id === id)
            return find ? find.name : ""
          }
        }
      },
      node_addr: {
        label: t("Node address"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      },
      node_type: {
        label: t("IO node type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: 0, label: t("0: digital input") },
            { value: 1, label: t("1: digital output") },
            { value: 2, label: t("2: analog input") },
            { value: 3, label: t("3: analog output") }
          ]
        }
      },
      device_type: {
        label: t("Device type"),
        field: InputText,
        options: {}
      }
    }
  }
}

export const SMIOOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      sm_node_label: { label: t("System manager") },
      node_addr: { label: t("Node address") },
      node_type: { label: t("IO node type") },
      device_type: { label: t("Device type") }
    }
  }
}
