import { DistributorRoutesContext } from "/apps/energy/energy-company/distributor/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["energy"])
  const { distributor } = useContext(DistributorRoutesContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    await remove(distributor.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The distributor has been removed."))])
        navigate("/energy/energy-company/distributors/")
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    distributor &&
      distributor.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Energy companies"), url: "/energy/energy-company/distributors/" },
          { label: t("Distributors"), url: "/energy/energy-company/distributors/" },
          { label: distributor.name, url: `/energy/energy-company/distributors/${distributor.id}/` },
          { label: t("Delete"), url: `/energy/energy-company/distributors/${distributor.id}/delete/` }
        ])
      )
  }, [distributor])

  return (
    <>
      {distributor && distributor.name ? (
        <DeleteConfirmation
          objectType={t("Distributor")}
          objectNames={[distributor.name]}
          remove={removeObject}
          goBack={() => navigate(-1)}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}
