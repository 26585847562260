import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { retrieveList } from "/apps/client/energy/energy-delivery-point/api"
import { REQUEST_DEFAULT_LIMIT } from "/utils/constants/api.js"
import { SortOrder } from "primereact/api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [items, setItems] = useState([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [next, setNext] = useState(false)
  const [loadingNext, setLoadingNext] = useState(true)
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})

  const retrieve = async () => {
    setLoadingNext(true)

    await retrieveList(siteStore.id, auth.userData.access_token, offset)
      .then(({ data }) => {
        if (data) {
          setCount(data.count)
          setNext(data.next)
          setOffset(offset + REQUEST_DEFAULT_LIMIT)
          setItems([...items, ...data.results])
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Energy delivery point", message)]))
      })
      .finally(() => {
        setLoading(false)
        setLoadingNext(false)
      })
  }

  const columns = [
    { field: "meter_id", header: t("Meter ID"), sortable: true },
    { field: "energy_type", header: t("Energy type"), sortable: true },
    { field: "energy_company_name", header: t("Distributor"), sortable: true },
    { field: "start_validity", header: t("Start validity"), sortable: true },
    { field: "end_validity", header: t("End validity"), sortable: true }
  ]

  const getDetailUrl = edpId => {
    return `/sites/${siteStore.id}/energy/energy-delivery-points/${edpId}/`
  }

  const getDeleteUrl = edpId => {
    return `/sites/${siteStore.id}/energy/energy-delivery-points/${edpId}/delete/`
  }

  const onRowClick = edpId => {
    navigate(getDetailUrl(edpId))
  }

  const menuFilters = { items, columns, filters, setFilters }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Energy delivery points"), url: `/sites/${siteStore.id}/energy/energy-delivery-points/` }
        ])
      )
      retrieve()
    }
  }, [siteStore.id])

  return (
    <>
      <section className="list-section">
        <ListActions filters={menuFilters}>
          <Link to={`/sites/${siteStore.id}/energy/energy-delivery-points/create/`}>
            <Button
              rounded
              severity="info"
              icon="fa-regular fa-plus"
              label={t("Create")}
            />
          </Link>
        </ListActions>

        <ListLayout
          columns={columns}
          value={items}
          sortField="meter_id"
          sortOrder={SortOrder.ASC}
          objectName={t("Energy delivery points")}
          loading={loading}
          filters={filters}
          getDetailUrl={getDetailUrl}
          getDeleteUrl={getDeleteUrl}
          onRowClick={onRowClick}
          count={count}
          next={next}
          loadMore={retrieve}
          loadingNext={loadingNext}
        />
      </section>
    </>
  )
}
