import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(flexId, token) {
  return await axios.get(`${window.BACKEND_FLEX_URL}/flex/${flexId}/market_schedule_group/`, header(token))
}

export async function getCurtailSchedules(flexId, date, token) {
  return await axios.get(`${window.BACKEND_FLEX_URL}/flex/${flexId}/load_curtail_schedule_fetch_in_a_day/${date ? `?current_date=${date}` : ""}`, header(token))
}

export async function retrieve(flexId, marketPlayerId, token) {
  return await axios.get(`${window.BACKEND_FLEX_URL}/flex/${flexId}/market_schedule_group/${marketPlayerId}/`, header(token))
}

export async function retrieveCurtailSchedules(flexId, marketPlayerId, token) {
  return await axios.get(`${window.BACKEND_FLEX_URL}/flex/${flexId}/load_curtail_schedule_of_market_schedule_group/${marketPlayerId}`, header(token))
}

export async function add(flexId, data, token) {
  return await axios.post(`${window.BACKEND_FLEX_URL}/flex/${flexId}/market_schedule_group/`, data, header(token))
}

export async function postAggregator(flexId, marketPlayerId, token) {
  return await axios.post(`${window.BACKEND_FLEX_URL}/flex/${flexId}/send_market_schedule_group/${marketPlayerId}`, {}, header(token))
}

export async function remove(flexId, marketPlayerId, token) {
  return await axios.delete(`${window.BACKEND_FLEX_URL}/flex/${flexId}/market_schedule_group/${marketPlayerId}/`, header(token))
}

export async function getCount(id, token) {
  return await axios.get(`${window.BACKEND_URL}/DETAIL_URL/${id}/count`, header(token))
}
