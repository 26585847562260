import { InputText } from "primereact/inputtext"
import { Dropdown } from "hivolution-front-utils"
import { InputSwitch } from "primereact/inputswitch"
import { commonsFields, commonsOverview, t } from "./commons"

export const CarelNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      scheme: {
        label: t("Scheme"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "HTTP", label: t("HTTP") },
            { value: "HTTPS", label: t("HTTPS") }
          ]
        }
      },
      target: {
        label: t("Target"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "PlantVisorPRO", label: "Plant Visor PRO" },
            { value: "boss", label: "Boss" }
          ]
        }
      },
      tls_version: {
        label: t("TLS version"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "TLSv1", label: "TLS Version 1" },
            { value: "TLSv1_1", label: "TLS Version 1.1" },
            { value: "TLSv1_2", label: "TLS Version 1.2" }
          ]
        }
      },
      low_cipher: {
        label: t("Low cipher"),
        field: InputSwitch,
        options: {}
      },
      user: {
        label: t("User"),
        field: InputText,
        options: {
          required: true
        }
      },
      password: {
        label: t("Password"),
        field: InputText,
        options: {
          required: true,
          type: "password"
        }
      },
      confirm_password: {
        label: t("Confirm password"),
        field: InputText,
        options: {
          required: true,
          type: "password"
        }
      }
    }
  }
}

export const CarelOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      scheme: { label: t("Scheme") },
      target: { label: t("Target") },
      tls_version: { label: t("TLS version") },
      low_cipher: { label: t("Low cipher") },
      user: { label: t("User") }
    }
  }
}
