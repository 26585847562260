import { InputText } from "primereact/inputtext"

export const Organization = () => {
  return {
    name: { label: "Name", field: InputText, options: { required: true } }
  }
}

export const organizations = [
  {
    "id": 1,
    "name": "carrefour",
    "app_url": "localhost:7000",
    "site_count": 8,
    "logo": "https://upload.wikimedia.org/wikipedia/en/1/12/Carrefour_logo_no_tag.svg",
    "primary-color": "",
    "secondary-color": "",
    "tertiary-color": ""
  },
  {
    "id": 2,
    "name": "intermarché",
    "app_url": "localhost:6000",
    "site_count": 100,
    "logo": "https://www.lanuitdesreussites.fr/wp-content/uploads/2021/05/nuit-des-reussites-partenaire-Intermarches-2.svg",
    "primary-color": "",
    "secondary-color": "",
    "tertiary-color": ""
  },
  {
    "id": 3,
    "name": "decathlon",
    "app_url": "localhost:6000",
    "site_count": 38,
    "logo": "https://upload.wikimedia.org/wikipedia/commons/c/c4/Decathlon_Logo.svg",
    "primary-color": "",
    "secondary-color": "",
    "tertiary-color": ""
  },
  {
    "id": 4,
    "name": "continental",
    "app_url": "localhost:6000",
    "site_count": 3,
    "logo": "https://upload.wikimedia.org/wikipedia/fr/f/fa/Continental-Logo.svg",
    "primary-color": "",
    "secondary-color": "",
    "tertiary-color": ""
  },
  {
    "id": 5,
    "name": "leclerc",
    "app_url": "localhost:6000",
    "site_count": 72,
    "logo": "https://upload.wikimedia.org/wikipedia/commons/e/ed/Logo_E.Leclerc_Sans_le_texte.svg",
    "primary-color": "",
    "secondary-color": "",
    "tertiary-color": ""
  },
  {
    "id": 6,
    "name": "transalliance",
    "app_url": "localhost:6000",
    "site_count": 2,
    "logo": "https://cdn.worldvectorlogo.com/logos/logo-transalliance.svg",
    "primary-color": "",
    "secondary-color": "",
    "tertiary-color": ""
  }
]
