import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { Dropdown } from "hivolution-front-utils"

import { commonsFields, commonsOverview, t } from "./commons"

export const Bacnet = nodes => {
  return {
    ...commonsFields(nodes),
    ...{
      object_instance: {
        label: t("Object instance"),
        field: InputNumber,
        options: {}
      },
      object_name: {
        label: t("Object name"),
        field: InputText,
        options: {}
      },
      object_type: {
        label: t("Object type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "ANALOG_INPUT", label: t("Analogue Input (0)") },
            { value: "ANALOG_OUTPUT", label: t("Analogue Output (1)") },
            { value: "ANALOG_VALUE", label: t("Analogue Value (2)") },
            { value: "BINARY_INPUT", label: t("Binary Input (3)") },
            { value: "BINARY_OUTPUT", label: t("Binary Output (4)") },
            { value: "BINARY_VALUE", label: t("Binary Value (5)") },
            { value: "MULTI_STATE_INPUT", label: t("Multi State Input (13)") },
            { value: "MULTI_STATE_OUTPUT", label: t("Multi State Output (14)") }
          ]
        }
      },
      bacnet_property: {
        label: t("Bacnet property"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "PRESENT_VALUE", label: t("Present value") },
            { value: "STATUS_FLAGS", label: t("Status flags") },
            { value: "PRIORITY_ARRAY", label: t("Priority array") },
            { value: "RELINQUISH_DEFAULT", label: t("Relinquish default") },
            { value: "UNINHIBIT_PRIORITY", label: t("Uninhibit priority") },
            { value: "PRIORITY_1", label: t("Priority 1") },
            { value: "PRIORITY_2", label: t("Priority 2") },
            { value: "PRIORITY_3", label: t("Priority 3") },
            { value: "PRIORITY_4", label: t("Priority 4") },
            { value: "PRIORITY_5", label: t("Priority 5") },
            { value: "PRIORITY_6", label: t("Priority 6") },
            { value: "PRIORITY_7", label: t("Priority 7") },
            { value: "PRIORITY_8", label: t("Priority 8") },
            { value: "PRIORITY_9", label: t("Priority 9") },
            { value: "PRIORITY_10", label: t("Priority 10") },
            { value: "PRIORITY_11", label: t("Priority 11") },
            { value: "PRIORITY_12", label: t("Priority 12") },
            { value: "PRIORITY_13", label: t("Priority 13") },
            { value: "PRIORITY_14", label: t("Priority 14") },
            { value: "PRIORITY_15", label: t("Priority 15") },
            { value: "PRIORITY_16", label: t("Priority 16") }
          ]
        }
      },
      bacnet_priority: {
        label: t("Bacnet priority"),
        field: InputNumber,
        options: {}
      },
      bacnet_priority_uninhibition: {
        label: t("Bacnet priority uninhibition"),
        field: InputNumber,
        options: {}
      }
    }
  }
}

export const BacnetOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      device_instance: { label: t("Device instance") },
      router: { label: t("Router") },
      object_instance: { label: t("Object instance") },
      object_name: { label: t("Object name") },
      object_type: { label: t("Object type") },
      bacnet_priority: { label: t("Bacnet priority") },
      bacnet_priority_uninhibition: { label: t("Bacnet priority uninhibition") }
    }
  }
}
