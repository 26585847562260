import React, { useEffect, useState } from "react"

import { ListLayout, FormContent } from "hivolution-front-utils"

import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import { addNotifications } from "/utils/store/notification"

import { addInterfaces, updateInterfaces, removeInterfaces } from "../api"
import { useTranslation } from "react-i18next"
import { useAuth } from "oidc-react"
import { errorNotification, successNotification } from "/utils/notification"
import { paginatedRequest } from "/utils/api"
import { Card } from "primereact/card"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Interface } from "../models/interface"

export default function Interfaces({ siteId, nodeId, node }) {
  const { t } = useTranslation(["client"])
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()

  const [interfaces, setInterfaces] = useState([])
  const [visible, setVisible] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [networks, setNetworks] = useState([])
  const [interfaceForm, setInterfaceForm] = useState({
    node: nodeId
  })
  const [errors, setErrors] = useState({})
  const [currentInterface, setCurrentInterface] = useState(null)

  useEffect(() => {
    const retrieveInterfaces = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteId}/node_interfaces/?node=${nodeId}`, auth.userData.access_token)()
        .then(results => {
          setInterfaces(
            results.map(nodeInterface => {
              return {
                ...nodeInterface,
                ...{
                  network_label: nodeInterface.ip_address_range ? nodeInterface.ip_address_range : nodeInterface.name
                }
              }
            })
          )
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getInterfaces(siteId, gatewayId, auth.userData.access_token).then(response => {
        setInterfaces(response.data.results.map(ipInterface => {
          return {
            ...ipInterface,
            ...{
              network: ipInterface.network.id,
              network_label: ipInterface.network.label
            }
          }
        }))
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    retrieveInterfaces()
  }, [nodeId])

  useEffect(() => {
    const retrieveNetworks = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteId}/networks/`, auth.userData.access_token)()
        .then(results => {
          setNetworks(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getNetworks(siteId, auth.userData.access_token).then(response => {
        setNetworks(response.data.results)
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    retrieveNetworks()
  }, [siteId])

  const findNetworkType = () => {
    const currentNetwork = networks.find(network => network.id === interfaceForm.network)
    return currentNetwork ? currentNetwork.type : null
  }

  const findTypeAndTransportType = (nodeType, networkType) => {
    if (networkType === "ipnetwork") {
      return {
        type: nodeType === "bacnetnode" ? "udpip" : "tcpip",
        transport_type: nodeType === "bacnetnode" ? "UDP/IP" : "TCP/IP"
      }
    } else if (networkType === "serialnetwork") {
      return {
        type: nodeType === "bacnetnode" ? "mstpserial" : "serial"
      }
    }
    return {}
  }

  const submit = async e => {
    e.preventDefault()

    const dataToSend = {
      ...interfaceForm,
      ...findTypeAndTransportType(node.type, findNetworkType())
    }

    if (currentInterface) {
      await updateInterfaces(siteId, currentInterface, dataToSend, auth.userData.access_token)
        .then(() => {
          navigate(0)
          dispatch(addNotifications([successNotification("Update", t("The interface has been updated."))]))
        })
        .catch(error => {
          if (error.response.data) {
            setErrors(error.response.data)
          }
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    } else {
      await addInterfaces(siteId, dataToSend, auth.userData.access_token)
        .then(() => {
          navigate(0)
          dispatch(addNotifications([successNotification("Create", t("The interface has been created."))]))
        })
        .catch(error => {
          if (error.response.data) {
            setErrors(error.response.data)
          }
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
  }

  const deleteInterface = async () => {
    await removeInterfaces(siteId, currentInterface, auth.userData.access_token)
      .then(() => {
        navigate(0)
        dispatch(addNotifications([successNotification("Delete", t("The interface has been removed."))]))
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const actionsBodyTemplate = rowData => {
    return (
      <div className="flex">
        <Button
          className="ml-1"
          size="small"
          severity="danger"
          icon="fa-regular fa-trash-can"
          onClick={() => {
            setDeleteVisible(true)
            setCurrentInterface(rowData.id)
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => {
          setVisible(false)
          setCurrentInterface(null)
          setInterfaceForm({ node: nodeId })
        }}
      >
        <form
          className="form-view"
          onSubmit={submit}
        >
          <FormContent
            model={Interface(networks, node.type, findNetworkType())}
            data={interfaceForm}
            setData={setInterfaceForm}
            errors={errors}
          />
          <div className="action-buttons-wrapper">
            <Button
              className="button"
              severity="success"
              label={t("Save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </Dialog>

      <Dialog
        visible={deleteVisible}
        style={{ width: "50vw" }}
        onHide={() => {
          setDeleteVisible(false)
          setCurrentInterface(null)
        }}
      >
        <span>{t("Are you sure you want to delete this interface?")}</span>
        <div className="flex justify-content-between">
          <Button
            className="button"
            severity="success"
            label={t("Yes")}
            type="button"
            onClick={deleteInterface}
          />
          <Button
            className="button"
            severity="danger"
            label={t("No")}
            type="button"
            onClick={() => setDeleteVisible(false)}
          />
        </div>
      </Dialog>

      <Card className="mt-2">
        <div className="w-full">
          <div className="flex justify-content-between">
            <h2 className="text-base text-primary ml-2 uppercase">{t("Network interfaces")}</h2>
            <Button
              className="mr-2 button"
              label={t("Connect a network")}
              onClick={() => setVisible(true)}
              severity="info"
            />
          </div>
          <ListLayout
            columns={[
              { field: "network_label", header: t("Network") },
              { field: "type", header: t("Type") },
              { field: "ip_address", header: t("Infos") }
            ]}
            value={interfaces}
            onRowClick={interfaceId => {
              const nodeInterface = interfaces.find(item => item.id === interfaceId)
              setInterfaceForm(nodeInterface)
              setCurrentInterface(interfaceId)
              setVisible(true)
            }}
            actionsBodyTemplate={actionsBodyTemplate}
          />
        </div>
      </Card>
    </>
  )
}
