import * as React from "react"
import { Route, Routes } from "react-router-dom"
import EnergyCompanyRoutes from "./energy-company/routes"
import { useEffect } from "react"
import { updateSideBar } from "/utils/store/sidebar.js"
import { useDispatch } from "react-redux"

export default function EnergyRoutes() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "parameters", siteId: null }))
  }, [])

  return (
    <Routes>
      <Route
        path="/energy-company/*"
        element={<EnergyCompanyRoutes />}
      />
    </Routes>
  )
}
