import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { Dropdown } from "hivolution-front-utils"

import { t } from "./commons"

const bacnetField = {
  mstp_address: {
    label: t("MSTP address"),
    field: InputText,
    dependsOn: "network",
    options: {
      required: true
    }
  }
}

const ipFields = {
  ip_address: {
    label: t("IP Address"),
    field: InputText,
    options: {
      required: true
    }
  },
  port: {
    label: t("Port"),
    field: InputNumber,
    options: {
      required: true,
      showButtons: true
    }
  }
}

export const Interface = (networks, nodeType, networkType) => {
  const networkField = {
    network: {
      label: t("Network"),
      field: Dropdown,
      options: {
        options: networks,
        required: true,
        optionValue: "id",
        optionLabel: "context_name"
      }
    }
  }

  if (nodeType === "bacnetnode" && networkType === "serialnetwork") {
    return {
      ...networkField,
      ...bacnetField
    }
  }
  if (networkType === "ipnetwork") {
    return {
      ...networkField,
      ...ipFields
    }
  }
  return networkField
}
