import { InputText } from "primereact/inputtext"
import { CountryDropdown } from "hivolution-front-utils"

import i18n from "/utils/i18n"

export const TSO = () => {
  return {
    name: { label: i18n.t("Name", { ns: "energy" }), field: InputText, options: { required: true } },
    country: { label: i18n.t("Country", { ns: "energy" }), field: CountryDropdown, options: { required: true } }
  }
}
