import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes.js"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { Loader, OverView } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getSpaces, update } from "../api"
import { Gateway, GatewayOverview } from "../models"
import { Button } from "primereact/button"
import { Link } from "react-router-dom"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [hardwares, setHardwares] = useState([])
  const [spaces, setSpaces] = useState([])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const { gateway, retrieveDetail } = useContext(GatewayRoutesContext)

  const updateObject = async e => {
    e.preventDefault()
    await update(siteStore.id, gateway.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The gateway has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const errorNotifications = []
        if (error.response.data) {
          setErrors(error.response.data)
          if (error.response.data.non_field_errors) {
            errorNotifications.push(errorNotification("Error", error.response.data.non_field_errors))
          }
        }
        errorNotifications.push(errorNotification("Error", error.message))
        dispatch(addNotifications(errorNotifications))
      })
  }

  const hardwareInfos = () => {
    return (
      <>
        {object && object.hardware_info && (
          <div className="list-section">
            <div className="list-section-head">
              <h3>{t("Hardware")}</h3>
            </div>

            <dl className="overview-resume">
              <div className="overview-resume-item">
                <dt>{t("Model")}</dt>
                <dd>{object.hardware_info.model}</dd>
              </div>
              <div className="overview-resume-item">
                <dt>{t("Manufacturer")}</dt>
                <dd>{object.hardware_info.manufacturer}</dd>
              </div>
              <div className="overview-resume-item">
                <dt>{t("Serial ID")}</dt>
                <dd>{object.hardware_info.serial_id}</dd>
              </div>
              <div className="overview-resume-item">
                <dt>{t("Description")}</dt>
                <dd>{object.hardware_info.description}</dd>
              </div>
            </dl>
          </div>
        )}
      </>
    )
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (gateway) {
      setLoading(false)
      setObject(gateway)
      setData(gateway)
    }
  }, [gateway])

  useEffect(() => {
    siteStore.id &&
      gateway &&
      gateway.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` }
        ])
      )
  }, [siteStore.id, gateway])

  useEffect(() => {
    const retrieveHardwares = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest("connectivity/hardwares/", auth.userData.access_token)()
        .then(results => {
          setHardwares(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getHardwares(auth.userData.access_token).then(response => {
        setHardwares(response.data.results)
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }

    const retrieveSpaces = async () => {
      await getSpaces(siteStore.id, auth.userData.access_token)
        .then(response => {
          setSpaces(response.data)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    // Only if edit mode
    if (edit) {
      retrieveHardwares()
      retrieveSpaces()
    }
  }, [edit])

  return (
    <>
      {object ? (
        <>
          <nav>
            <Link to={`/sites/${siteStore.id}/assets/building/equipments?gateway_name=${gateway.name}`}>
              <Button
                link
                rounded
                severity="secondary"
                label="Equipments"
                icon="fa-solid fa-square-arrow-up-right"
              />
            </Link>
            <Link to={`/sites/${siteStore.id}/assets/building/points?gateway_name=${gateway.name}`}>
              <Button
                link
                rounded
                severity="secondary"
                label="Points"
                icon="fa-solid fa-square-arrow-up-right"
              />
            </Link>
          </nav>

          <OverView
            object={object}
            model={Gateway(hardwares, spaces)}
            overviewModel={GatewayOverview()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            setEdit={setEdit}
            loading={loading}
            deleteUrl={`/sites/${siteStore.id}/assets/gateways/${object.id}/delete/`}
            additionalComponent={hardwareInfos}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
