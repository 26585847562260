import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`, header(token))
}

export async function retrieve(siteId, gatewayId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/${gatewayId}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`, data, header(token))
}

export async function update(siteId, gatewayId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/${gatewayId}/`, data, header(token))
}

export async function remove(siteId, gatewayId, token) {
  return await axios.delete(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/${gatewayId}/`, header(token))
}

export async function getCount(gatewayId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/gateways/${gatewayId}/count`, header(token))
}

export async function getHardwares(token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/hardwares/`, header(token))
}

export async function getSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, header(token))
}

export async function getIpNetworks(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/?type=ipnetwork`, header(token))
}

export async function getDeployHistory(gatewayName, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/deploy/history`, {
    ...header(token),
    params: { device_id: gatewayName }
  })
}
