import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { CardCreateItem, Cards } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const { siteId } = useParams()

  const [items, setItems] = useState([])

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))

    dispatch(
      updateBreadcrumb([
        { label: t("Sites"), url: "/sites/" },
        { label: t("Detail"), url: `/sites/${siteId}/` },
        { label: t("Optimization"), url: `/sites/${siteId}/optimization/` },
        { label: t("Flexibility"), url: `/sites/${siteId}/optimization/flexibility/` }
      ])
    )

    const retrieve = async () => {
      await retrieveList(siteId, auth.userData.access_token)
        .then(response => {
          if (response.data) {
            setItems(response.data.results)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    retrieve()
  }, [])

  const getDetailUrl = flexContractId => {
    return `/sites/${siteId}/optimization/flexibility/${flexContractId}/load-curtail-parameters/`
  }

  return (
    <>
      {items.length > 0 && (
        <Cards
          items={items.map(item => {
            return {
              icon: "fa-solid fa-file-contract",
              url: getDetailUrl(item.id),
              title: item.name
            }
          })}
        >
          <CardCreateItem
            url={`/sites/${siteId}/optimization/flexibility/create/`}
            title={t("Create contract")}
            icon="fa-solid fa-circle-plus add-icon"
          />
        </Cards>
      )}
    </>
  )
}
