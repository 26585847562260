import { paginatedRequest } from "/utils/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { CardCreateItem, Cards } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [items, setItems] = useState([])

  const getDetailUrl = gatewayId => {
    return `/sites/${siteStore.id}/assets/gateways/${gatewayId}/`
  }

  const retrieve = async () => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`connectivity/sites/${siteStore.id}/gateways/`, auth.userData.access_token)()
      .then(results => {
        setItems(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /* await retrieveList(siteId, auth.userData.access_token).then(response => {
      if (response.data) {
        setItems(response.data.results)
      }
    }).catch((error) => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  useEffect(() => {
    siteStore.id && retrieve()
  }, [siteStore.id])

  return (
    <Cards
      items={items.map(item => {
        return {
          icon: "fa-solid fa-house-signal",
          url: getDetailUrl(item.id),
          title: item.name
        }
      })}
    >
      <CardCreateItem
        url={`/sites/${siteStore.id}/assets/gateways/create/`}
        title={t("Create gateway")}
        icon="fa-solid fa-circle-plus add-icon"
      />
    </Cards>
  )
}
