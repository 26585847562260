{
  "Aggregator": "Aggregator",
  "Aggregators": "Aggregators",
  "Back to aggregator list": "Back to aggregator list",
  "Back to distributor list": "Back to distributor list",
  "Back to retailer list": "Back to retailer list",
  "Back to TSO list": "Back to TSO list",
  "Country": "Country",
  "Create": "Create",
  "Create aggregator": "Create aggregator",
  "Create distributor": "Create distributor",
  "Create retailer": "Create retailer",
  "Create TSO": "Create TSO",
  "Delete": "Delete",
  "Detail": "Detail",
  "Distributor": "Distributor",
  "Distributors": "Distributors",
  "Energy companies": "Energy companies",
  "Home": "Home",
  "Name": "Name",
  "Retailer": "Retailer",
  "Retailers": "Retailers",
  "Save": "Save",
  "The aggregator has been created.": "The energy company aggregator has been created.",
  "The aggregator has been updated.": "The aggregator has been updated.",
  "The distributor has been created.": "The energy company distributor has been created.",
  "The distributor has been updated.": "The distributor has been updated.",
  "The retailer has been created.": "The retailer has been created.",
  "The retailer has been removed.": "The retailer has been removed.",
  "The retailer has been updated.": "The retailer has been updated.",
  "The TSO has been created.": "The TSO has been created.",
  "The TSO has been removed.": "The TSO has been removed.",
  "The TSO has been updated.": "The TSO has been updated.",
  "TSO": "TSO"
}
