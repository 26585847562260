import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Dropdown, SelectModal } from "hivolution-front-utils"

import i18n from "/utils/i18n"

const t = str => i18n.t(str, { ns: "client" })

export const Equipment = (nodes, gateways, spaces, equipmentTypes, equipmentSubTypes) => {
  return {
    /*
     * List of fields for the model, like this
     */
    ...{
      name: { label: t("Name"), field: InputText, options: { required: true } },
      description: { label: t("Description"), field: InputTextarea, options: {} },
      equip_comm_info: {
        node: {
          label: t("Node"),
          field: SelectModal,
          options: {
            items: nodes,
            columns: [
              { field: "name", header: t("Name"), sortable: true },
              { field: "model", header: t("Model"), sortable: true },
              { field: "manufacturer", header: t("Manufacturer"), sortable: true },
              { field: "type", header: t("Protocol"), sortable: true },
              { field: "ip_addresses", header: t("IP addresses"), sortable: true }
            ],
            objectName: i18n.t("Nodes", { ns: "client" }),
            searchLabel: id => {
              let find = nodes.find(node => node.id === id)
              return find ? find.name : ""
            }
          }
        },
        gateway: {
          label: t("Gateway"),
          field: Dropdown,
          options: {
            options: gateways,
            optionLabel: "name",
            optionValue: "id"
          }
        }
      },
      space: {
        label: t("Space"),
        field: Dropdown,
        options: {
          options: spaces,
          optionLabel: "name",
          optionValue: "id"
        }
      },
      equipment_type: {
        label: t("Equipment type"),
        field: Dropdown,
        options: {
          options: equipmentTypes,
          optionLabel: "name",
          optionValue: "id",
          filter: true
        }
      }
    },
    ...(equipmentSubTypes.length > 0
      ? {
          equipment_subtype: {
            label: t("Equipment sub type"),
            field: Dropdown,
            options: {
              options: equipmentSubTypes,
              optionLabel: "name",
              optionValue: "id",
              filter: true
            }
          }
        }
      : {})
  }
}

export const EquipmentOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: t("Name") },
    description: { label: t("Description") },
    node_name: { label: t("Node") },
    gateway_name: { label: t("Gateway") },
    equipment_type_name: { label: t("Type") },
    equipment_subtype_name: { label: t("Subtype") }
  }
}
