import * as React from "react"
import { Route, Routes } from "react-router-dom"

import List from "./components/List"

export default function ParameterRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
    </Routes>
  )
}
