import { InputText } from "primereact/inputtext"
import { InputSwitch } from "primereact/inputswitch"

import { commonsFields, commonsOverview, t } from "./commons"

export const BacnetNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      device_instance: {
        label: t("Device instance"),
        field: InputText,
        options: {}
      },
      router: {
        label: t("Router"),
        field: InputSwitch,
        options: {}
      }
    }
  }
}

export const BacnetOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      device_instance: { label: t("Device instance") },
      router: { label: t("Router") }
    }
  }
}
