import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes"
import { removeInterface } from "/apps/client/connectivity/network-interface/api"
import { NetworkInterfaceRoutesContext } from "/apps/client/connectivity/network-interface/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

export default function Delete() {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)
  const { t } = useTranslation(["client"])
  const { networkInterface } = useContext(NetworkInterfaceRoutesContext)
  const { gateway } = useContext(GatewayRoutesContext)

  const removeObject = async () => {
    setProcessing(true)
    await removeInterface(siteStore.id, networkInterface.id, auth.userData.access_token)
      .then(() => {
        navigate(`/sites/${siteStore.id}/assets/gateways/${gateway.id}/network-interfaces/`)
        dispatch(addNotifications([successNotification("Delete", t("The gateway interface has been removed."))]))
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessing(false))
  }

  return (
    <>
      {networkInterface ? (
        <DeleteConfirmation
          objectType={t("Network interface")}
          objectNames={[networkInterface.ip_addr]}
          remove={removeObject}
          processing={processing}
          goBack={() => navigate(-1)}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}
