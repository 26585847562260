import i18n from "./i18n"

const menu = (menuName, siteId = null) => {
  let items

  switch (menuName) {
    case "parameters":
      items = [
        {
          label: i18n.t("Gateways"),
          icon: "fa-solid fa-microchip",
          url: "/connectivity/gateways/boxes/",
          disabled: false,
          visible: true
        },
        {
          label: i18n.t("Parameters"),
          icon: "fa-solid fa-sliders",
          url: "/connectivity/parameters/",
          disabled: false,
          visible: true
        },
        {
          label: i18n.t("Energy companies"),
          icon: "fa fa-bolt",
          namespace: "/energy/energy-company/",
          url: "/energy/energy-company/distributors/",
          disabled: false,
          visible: true
        },
        {
          label: i18n.t("Edge block templates"),
          icon: "fa-solid fa-code",
          url: "/optimization/edge-block-templates",
          disabled: false,
          visible: true
        },
        {
          label: i18n.t("Weather zone"),
          icon: "fa-solid fa-cloud-rain",
          url: "#",
          disabled: true,
          visible: true
        },
        {
          label: i18n.t("Price zone"),
          icon: "fa-solid fa-landmark",
          url: "#",
          disabled: true,
          visible: true
        },
        {
          label: i18n.t("Equipment type"),
          icon: "fa-solid fa-plug",
          url: "#",
          disabled: true,
          visible: true
        }
      ]
      break

    case "organizations":
      items = [
        {
          label: i18n.t("Sites"),
          icon: "fa-solid fa-city",
          url: "/sites/",
          disabled: false,
          visible: true
        }
      ]
      break

    case "site":
      items = [
        {
          label: i18n.t("Assets"),
          icon: "fa-solid fa-network-wired",
          url: `/sites/${siteId}/assets/`,
          namespace: `/sites/${siteId}/assets/`,
          disabled: false,
          visible: true,
          items: [
            {
              label: i18n.t("Communication"),
              icon: "fa-solid fa-podcast",
              url: `/sites/${siteId}/assets/communication/networks/`,
              disabled: false,
              visible: true
            },
            {
              label: i18n.t("Building"),
              icon: "fa-regular fa-building",
              url: `/sites/${siteId}/assets/building/spaces/`,
              namespace: `/sites/${siteId}/assets/building/`,
              disabled: false,
              visible: true
            },
            {
              label: i18n.t("Gateways"),
              icon: "fa-solid fa-tower-broadcast",
              url: `/sites/${siteId}/assets/gateways/`,
              namespace: `/sites/${siteId}/assets/gateways/`,
              disabled: false,
              visible: true
            }
          ]
        },
        {
          label: i18n.t("Energy"),
          icon: "fa fa-bolt",
          namespace: `/sites/${siteId}/energy/`,
          url: `/sites/${siteId}/energy/energy-delivery-points/`,
          disabled: false,
          visible: true
        },
        {
          label: i18n.t("Connectivity"),
          icon: "fa-solid fa-wifi",
          url: `/sites/${siteId}/connectivity/`,
          disabled: false,
          visible: false
        },
        {
          label: i18n.t("Optimization"),
          icon: "fa-solid fa-gears",
          url: `/sites/${siteId}/optimization/`,
          disabled: false,
          visible: true,
          items: [
            {
              label: i18n.t("Configuration"),
              icon: "fa-solid fa-gear",
              namespace: `/sites/${siteId}/optimization/configuration/`,
              url: `/sites/${siteId}/optimization/configuration/states/`,
              disabled: false,
              visible: true
            },
            {
              label: i18n.t("Flexibility"),
              icon: "fa-solid fa-shuffle",
              namespace: `/sites/${siteId}/optimization/flexibility/`,
              url: `/sites/${siteId}/optimization/flexibility/`,
              disabled: false,
              visible: false
            }
          ]
        }
      ]
      break

    default:
      items = []
      break
  }

  return items
}

export default menu
