import { ContractRoutesContext } from "/apps/client/energy/contract/routes.js"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["client"])
  const { contract } = useContext(ContractRoutesContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    await remove(siteStore.id, contract.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The contract has been removed."))])
        navigate(`/sites/${siteStore.id}/energy/contracts/`)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      contract &&
      contract.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Energy"), url: `/sites/${siteStore.id}/energy/energy-delivery-points/` },
          { label: t("Contracts"), url: `/sites/${siteStore.id}/energy/contracts/` },
          { label: contract.name, url: `/sites/${siteStore.id}/energy/contracts/${contract.id}/` },
          { label: t("Delete"), url: `/sites/${siteStore.id}/energy/contracts/${contract.id}/delete/` }
        ])
      )
  }, [siteStore.id, contract])

  return (
    <>
      {contract ? (
        <DeleteConfirmation
          objectType={t("Contract")}
          objectNames={[contract.name]}
          remove={removeObject}
          goBack={() => navigate(-1)}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}
