import { InputText } from "primereact/inputtext"
import { Dropdown, DateInput } from "hivolution-front-utils"
import { InputTextarea } from "primereact/inputtextarea"
import { InputNumber } from "primereact/inputnumber"

import i18n from "/utils/i18n"

const EnergyDeliveryPoint = (distributors, transmissionCompanies, loadingDistributions = false, loadingTransmissions = false) => ({
  /*
   * List of fields for the model, like this
   */
  meter_id: {
    label: i18n.t("Meter ID", { ns: "client" }),
    field: InputText,
    options: {
      required: true,
      keyfilter: /[\w\d-]/
    }
  },
  energy_type: {
    label: i18n.t("Type", { ns: "client" }),
    field: Dropdown,
    options: {
      required: true,
      placeholder: "Select type",
      options: [
        { label: i18n.t("Gas", { ns: "client" }), value: "Gas" },
        { label: i18n.t("Electricity", { ns: "client" }), value: "Electricity" }
      ]
    }
  },
  energy_company: {
    label: i18n.t("Distributor"),
    field: Dropdown,
    options: {
      placeholder: loadingDistributions ? "Loading..." : "Select distributor",
      options: distributors,
      optionLabel: "name",
      optionValue: "id",
      filter: distributors && distributors.length > 20,
      loading: loadingDistributions
    }
  },
  transmission_energy_company: {
    label: i18n.t("Transmission Company", { ns: "client" }),
    field: Dropdown,
    options: {
      placeholder: loadingTransmissions ? "Loading..." : "Select transmission Company",
      options: transmissionCompanies,
      optionLabel: "name",
      optionValue: "id",
      filter: transmissionCompanies && transmissionCompanies.length > 20,
      loading: loadingTransmissions
    }
  },
  description: {
    label: i18n.t("Description", { ns: "client" }),
    field: InputTextarea,
    options: {}
  },
  start_validity: {
    label: i18n.t("Start validity", { ns: "client" }),
    field: DateInput,
    options: {}
  },
  end_validity: {
    label: i18n.t("End validity", { ns: "client" }),
    field: DateInput,
    options: {}
  },
  equivalent_metering_intercept: {
    label: i18n.t("Equivalent Metering Intercept", { ns: "client" }),
    field: InputNumber,
    options: { showButtons: true }
  },
  data_step: {
    label: i18n.t("Data step", { ns: "client" }),
    field: InputNumber,
    options: { showButtons: true }
  }
})

const EnergyDeliveryPointOverview = () => ({
  meter_id: { label: i18n.t("Meter ID", { ns: "client" }) },
  energy_type: { label: i18n.t("Type", { ns: "client" }) },
  energy_company_name: { label: i18n.t("Distributor", { ns: "client" }) },
  transmission_energy_company: { label: i18n.t("Transmission company", { ns: "client" }) },
  description: { label: i18n.t("Description", { ns: "client" }) },
  start_validity: { label: i18n.t("Start validity", { ns: "client" }) },
  end_validity: { label: i18n.t("End validity", { ns: "client" }) },
  equivalent_metering_intercept: { label: i18n.t("Equivalent metering intercept", { ns: "client" }) },
  equivalent_metering_equation: { label: i18n.t("Equivalent metering equation", { ns: "client" }) },
  data_step: { label: i18n.t("Data step", { ns: "client" }) }
})

const EquivalentMeteringTerm = (points, edit, loading) => ({
  gatewayPoint: {
    label: i18n.t("Point", { ns: "client" }),
    field: Dropdown,
    options: {
      required: true,
      disabled: edit,
      options: points,
      optionLabel: "label",
      optionValue: "id",
      filter: points.length && points.length > 20,
      placeholder: loading ? "Loading..." : "Select a point",
      loading
    }
  },
  coef: {
    label: i18n.t("Coef", { ns: "client" }),
    field: InputNumber,
    options: {
      required: true,
      showButtons: true
    }
  }
})

export { EnergyDeliveryPoint, EnergyDeliveryPointOverview, EquivalentMeteringTerm }
