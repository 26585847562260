import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/hardwares/`, header(token))
}

export async function retrieve(id, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/hardwares/${id}`, header(token))
}

export async function add(data, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/hardwares/`, data, header(token))
}

export async function update(id, data, token) {
  return await axios.put(`${window.BACKEND_URL}/connectivity/hardwares/${id}/`, data, header(token))
}

export async function remove(id, token) {
  return await axios.delete(`${window.BACKEND_URL}/connectivity/hardwares/${id}/`, header(token))
}

export async function getCount(id, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/hardwares/${id}/count`, header(token))
}
