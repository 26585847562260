import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { Dropdown } from "hivolution-front-utils"
import { commonsFields, commonsOverview, t } from "./commons"

export const OPCUANode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      namespace_index: {
        label: t("Namespace index"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 0
        }
      },
      path: {
        label: t("Path"),
        field: InputText,
        options: {}
      },
      batch_size_read: {
        label: t("Batch size read"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 1
        }
      },
      batch_size_write: {
        label: t("Batch size write"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 1
        }
      },
      security_policy: {
        label: t("Security policy"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "None", label: "None" },
            { value: "Basic256", label: "Basic256" },
            { value: "Basic128Rsa15", label: "Basic128Rsa15" },
            { value: "Basic256Sha256", label: "Basic256Sha256" }
          ]
        }
      },
      security_mode: {
        label: t("Security mode"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "None", label: "None" },
            { value: "Sign", label: "Sign" },
            { value: "SignAndEncrypt", label: "SignAndEncrypt" }
          ]
        }
      },
      server_certificate_path: {
        label: t("Server certificate path"),
        field: InputText,
        options: {}
      }
    }
  }
}

export const OPCUAOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      namespace_index: { label: t("Namespace index") },
      path: { label: t("Path") },
      batch_size_read: { label: t("Batch size read") },
      batch_size_write: { label: t("Batch size write") },
      security_policy: { label: t("Security policy") },
      security_mode: { label: t("Security mode") },
      server_certificate_path: { label: t("Server certificate path") }
    }
  }
}
