{
  "Back to hardware list": "Back to hardware list",
  "Create": "Create",
  "Create Hardware": "Create Hardware",
  "Delete": "Delete",
  "Description": "Description",
  "Detail": "Detail",
  "Gateway box": "Gateway box",
  "Gateway boxes": "Gateway boxes",
  "Home": "Home",
  "Manufacturer": "Manufacturer",
  "Model": "Model",
  "Name": "Name",
  "Parameters": "Parameters",
  "Quantity": "Quantity",
  "Save": "Save",
  "Serial ID": "Serial ID",
  "The hardware has been created.": "The hardware has been created.",
  "The hardware has been removed.": "The hardware has been removed.",
  "The hardware has been updated.": "The hardware has been updated.",
  "Unit": "Unit"
}
