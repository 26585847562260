import * as React from "react"
import { useState, createContext, useEffect } from "react"
import { Outlet, Route, Routes, useParams } from "react-router-dom"

import List from "./components/List"
import Create from "./components/Create"
import Detail from "./components/Detail"
import Delete from "./components/Delete"
import { retrieve } from "./api"
import { useDispatch } from "react-redux"
import DeleteBulk from "./components/DeleteBulk"
import { useAuth } from "oidc-react"

const NodeRoutesContext = createContext(null)

const NodeContent = () => {
  const auth = useAuth()
  const { siteId, nodeId } = useParams()
  const [node, setNode] = useState()
  const dispatch = useDispatch()

  const retrieveDetail = async () => {
    await retrieve(siteId, nodeId, auth.userData.access_token)
      .then(response => {
        setNode(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteId && nodeId && retrieveDetail()
  }, [siteId, nodeId])

  return (
    <NodeRoutesContext.Provider value={{ node, retrieveDetail }}>
      <Outlet />
    </NodeRoutesContext.Provider>
  )
}

export { NodeRoutesContext }
export default function AutomationNodeRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="/create/"
        element={<Create />}
      />
      <Route
        path="/multi-delete/"
        element={<DeleteBulk />}
      />
      <Route
        path=":nodeId"
        element={<NodeContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
