import * as React from "react"
import { createContext, useState, useEffect } from "react"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"

import List from "./components/List"
import { useDispatch, useSelector } from "react-redux"
import { addNotifications } from "/utils/store/notification"
import { errorNotification } from "/utils/notification"
import { retrieve } from "./api"
import { useAuth } from "oidc-react"
import { site } from "/utils/store/site"
import DeleteBulk from "./components/DeleteBulk"

const ProtocolInstanceRouteContext = createContext(null)

const ProtocolInstanceContent = () => {
  const siteStore = useSelector(site)
  const [protocolInstance, setProtocolInstance] = useState()
  const dispatch = useDispatch()
  const auth = useAuth()
  const { protocolInstanceId } = useParams()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, protocolInstanceId, auth.userData.access_token)
      .then(response => {
        setProtocolInstance(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && protocolInstanceId && retrieveDetail()
  }, [siteStore.id, protocolInstanceId])

  return (
    <ProtocolInstanceRouteContext.Provider value={{ protocolInstance, retrieveDetail }}>
      <Outlet />
    </ProtocolInstanceRouteContext.Provider>
  )
}

export { ProtocolInstanceRouteContext }
export default function ProtocolInstanceRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="/create/"
        element={<Create />}
      />
      <Route
        path="/delete/"
        element={<DeleteBulk />}
      />
      <Route
        path=":protocolInstanceId"
        element={<ProtocolInstanceContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
