import { paginatedRequestFlex } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { ListActions, ListLayout, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { postAggregator, retrieve } from "../api"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])

  const [object, setObject] = useState(null)
  const [curtailSchedules, setCurtailSchedules] = useState([])
  const { siteId, flexId, marketPlayerId } = useParams()

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))

    dispatch(
      updateBreadcrumb([
        { label: t("Sites"), url: "/sites/" },
        { label: t("Detail"), url: `/sites/${siteId}/` },
        { label: t("Optimization"), url: `/sites/${siteId}/optimization/` },
        { label: t("Flexibility"), url: `/sites/${siteId}/optimization/flexibility/` },
        { label: t("Market player interaction"), url: `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/` },
        { label: t("Detail"), url: `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/${marketPlayerId}/` }
      ])
    )

    const retrieveDetail = async () => {
      await retrieve(flexId, marketPlayerId, auth.userData.access_token)
        .then(response => {
          setObject(response.data)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    retrieveDetail()

    const retrieveCS = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequestFlex(`flex/${flexId}/load_curtail_schedule_of_market_schedule_group/${marketPlayerId}`, auth.userData.access_token)()
        .then(results => {
          setCurtailSchedules(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await retrieveCurtailSchedules(flexId, marketPlayerId, auth.userData.access_token).then(response => {
        setCurtailSchedules(response.data.results)
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    retrieveCS()
  }, [])

  const sendToAggregator = async () => {
    await postAggregator(flexId, marketPlayerId, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Success", t("The proposal has been sent to the aggregator."))]))
        navigate(`/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  return (
    <>
      {object ? (
        <section className="list-section">
          <div className="list-section-head">
            <h3>
              {t("Load curtailment for")} {object.schedule_date}
            </h3>
          </div>
          <ListActions>
            <Button
              rounded
              severity="info"
              label={t("Send to aggregator")}
              onClick={() => sendToAggregator()}
              icon="fa-solid fa-angles-up"
            />
          </ListActions>
          <ListLayout
            columns={[
              {
                field: "curtail_start",
                header: t("Curtail start"),
                processValue: value => {
                  return new Date(value).toLocaleString()
                }
              },
              {
                field: "curtail_end",
                header: t("Curtail end"),
                processValue: value => {
                  return new Date(value).toLocaleString()
                }
              },
              { field: "curtail_capacity", header: t("Curtail capacity") },
              {
                field: "create_time",
                header: t("Proposed time"),
                processValue: value => {
                  return new Date(value).toLocaleString()
                }
              },
              { field: "created_by", header: t("Proposed by"), sortable: true },
              { field: "execution_desired_text", header: t("Requested?") },
              {
                field: "request_time",
                header: t("Request time"),
                processValue: value => {
                  return value ? new Date(value).toLocaleString() : ""
                }
              },
              { field: "requested_by", header: t("Requested by"), sortable: true }
            ]}
            value={curtailSchedules}
          />
        </section>
      ) : (
        <Loader />
      )}
    </>
  )
}
