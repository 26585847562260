import { paginatedRequest } from "/utils/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"

import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"

import { ListActions, ListLayout } from "hivolution-front-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"

import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import { Link, useNavigate } from "react-router-dom"

import { exportNetworks, exportTemplate, getAllValues, importNetwork } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const hiddenFileInput = useRef(null)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [selected, setSelected] = useState([])
  const [processingImport, setProcessingImport] = useState(false)
  const [processingExport, setProcessingExport] = useState(false)
  const [processingTemplate, setProcessingTemplate] = useState(false)

  const retrieve = async (queryParams = "") => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`asset/sites/${siteStore.id}/networks/${queryParams}`, auth.userData.access_token)()
      .then(results => {
        setItems(results)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Networks", error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /* await retrieveList(siteId, auth.userData.access_token).then(response => {
      if (response.data) {
        setItems(response.data.results)
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const getFilterValues = async () => {
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Communication"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: t("Networks"), url: `/sites/${siteStore.id}/assets/communication/networks/` }
        ])
      )
      getFilterValues()
      retrieve()
    }
  }, [siteStore.id])

  const columns = [
    { field: "type", header: t("Type"), sortable: true },
    { field: "ip_address_range", header: t("IP Range"), sortable: true },
    { field: "name", header: t("Zone name"), sortable: true },
    { field: "description", header: t("Description") }
  ]

  const columnsFilter = [
    { field: "type", header: t("Type") },
    { field: "space_name", header: t("Space") },
    { field: "is_bacnet", header: t("Bacnet") },
    { field: "ip_address_range", header: t("IP addres range") },
    { field: "bacnet_network_number", header: t("Bacnet number") }
  ]

  const getDetailUrl = networkId => {
    return `/sites/${siteStore.id}/assets/communication/networks/${networkId}/`
  }

  const getDeleteUrl = networkId => {
    return `/sites/${siteStore.id}/assets/communication/networks/${networkId}/delete`
  }

  const onRowClick = networkId => {
    navigate(getDetailUrl(networkId))
  }

  const getExport = async function () {
    setProcessingExport(true)
    await exportNetworks(siteStore.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `networks_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Networks", error.message)]))
      })
      .finally(() => {
        setProcessingExport(false)
      })
  }

  const getTemplate = async function () {
    setProcessingTemplate(true)
    await exportTemplate(0, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `template_networks_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Networks", error.message)]))
      })
      .finally(() => {
        setProcessingTemplate(false)
      })
  }

  const postImport = async function ({ target }) {
    const file = target.files[0]

    if (file) {
      setProcessingImport(true)
      await importNetwork(siteStore.id, file, auth.userData.access_token)
        .then(async response => {
          //dispatch(addNotifications([successNotification("Import", response.data)]))
          await retrieve()

          dispatch(
            addNotifications([
              {
                severity: "success",
                summary: "Networks",
                detail: response.data,
                closable: false,
                life: 3000
              }
            ])
          )
        })
        .catch(error => {
          if (error.response && error.response.headers && error.response.headers["content-type"] === "text/csv") {
            fileDownload(error.response.data, `ERROR_networks_${datetimeStr()}.csv`)
            dispatch(addNotifications([errorNotification("Networks", t("An error occured during the import, see more details in the error report file."))]))
          } else {
            dispatch(addNotifications([errorNotification("Networks", error.message)]))
          }
        })
        .finally(() => {
          setProcessingImport(false)
          target.value = null
        })
    }
  }

  const menuEntries = [
    {
      label: t("Import"),
      icon: processingImport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-import",
      disabled: processingImport,
      command: () => hiddenFileInput && hiddenFileInput.current.click()
    },
    {
      label: t("Export"),
      icon: processingExport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-export",
      disabled: processingExport,
      command: getExport
    },
    {
      label: t("Template"),
      icon: processingTemplate ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file",
      disabled: processingTemplate,
      command: getTemplate
    }
  ]

  useEffect(() => {
    setLoading(true)
    if (Object.keys(filters).length > 0) {
      let queryParams = "?"
      Object.keys(filters).forEach(key => {
        Object.values(filters[key]).forEach(value => {
          queryParams = queryParams + `${key}=${value}&`
        })
      })
      retrieve(queryParams)
    } else {
      if (siteStore.id) retrieve()
    }
  }, [filters])

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  return (
    <>
      <ListActions
        entries={menuEntries}
        filterBackend={true}
        filters={menuFilters}
      >
        <input
          className="hidden"
          ref={hiddenFileInput}
          type="file"
          onChange={e => postImport(e.target)}
        />
        <Link to={`/sites/${siteStore.id}/assets/communication/networks/create/`}>
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Networks")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        bulkDeleteUrl={`/sites/${siteStore.id}/assets/communication/networks/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        onRowClick={onRowClick}
        handleSelection={setSelected}
      />
    </>
  )
}
