import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import { componentsTranslationEN } from "hivolution-front-utils"
import { componentsTranslationFR } from "hivolution-front-utils"

import homeTranslationEN from "../apps/home/locales/en.json"
import homeTranslationFR from "../apps/home/locales/fr.json"
import connectivityTranslationEN from "../apps/connectivity/locales/en.json"
import connectivityTranslationFR from "../apps/connectivity/locales/fr.json"
import optimizationTranslationEN from "../apps/optimization/locales/en.json"
import optimizationTranslationFR from "../apps/optimization/locales/fr.json"
import energyTranslationEN from "../apps/energy/locales/en.json"
import energyTranslationFR from "../apps/energy/locales/fr.json"
import clientTranslationEN from "../apps/client/locales/en.json"
import clientTranslationFR from "../apps/client/locales/fr.json"

import commonTranslationEN from "./locales/en.json"
import commonTranslationFR from "./locales/fr.json"

const resources = {
  en: {
    connectivity: connectivityTranslationEN,
    home: homeTranslationEN,
    components: componentsTranslationEN,
    optimization: optimizationTranslationEN,
    energy: energyTranslationEN,
    common: commonTranslationEN,
    client: clientTranslationEN
  },
  fr: {
    connectivity: connectivityTranslationFR,
    home: homeTranslationFR,
    components: componentsTranslationFR,
    optimization: optimizationTranslationFR,
    energy: energyTranslationFR,
    common: commonTranslationFR,
    client: clientTranslationFR
  }
}

i18n.use(initReactI18next).init({
  resources,
  defaultNS: "home",
  lng: "en",
  interpolation: { escapeValue: false }
})

export default i18n
