import { paginatedRequest } from "/utils/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { ListActions, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["energy"])

  const [aggregators, setAggregators] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { label: t("Energy companies"), url: "/energy/energy-company/distributors/" },
        { label: t("Aggregators"), url: "/energy/energy-company/aggregators/" }
      ])
    )

    const retrieve = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest("energy/energy_companies/?company_type=Aggregator", auth.userData.access_token)()
        .then(results => {
          setAggregators(results)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await retrieveList(auth.userData.access_token).then(response => {
              if (response.data) {
                setAggregators(response.data.results)
              }
              setLoading(false)
            }).catch(error => {
              setLoading(false)
              dispatch(addNotifications([errorNotification("Error", error.message)]))
            }) */
    }
    retrieve()
  }, [])

  const columns = [
    { field: "name", header: t("Name") },
    { field: "country", header: t("Country") }
  ]

  const getDetailUrl = id => {
    return `/energy/energy-company/aggregators/${id}/`
  }

  const getDeleteUrl = id => {
    return `/energy/energy-company/aggregators/${id}/delete/`
  }

  const onRowClick = id => {
    navigate(getDetailUrl(id))
  }

  const menuFilters = { columns, items: aggregators, filters, setFilters }

  return (
    <>
      <ListActions filters={menuFilters}>
        <Link to="/energy/energy-company/aggregators/create/">
          <Button
            rounded
            icon="fa-regular fa-plus"
            label={t("Create aggregator")}
          />
        </Link>
      </ListActions>
      <ListLayout
        columns={columns}
        value={aggregators}
        filters={filters}
        objectName={t("Aggregators")}
        loading={loading}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
      />
    </>
  )
}
