import React, { useContext, useEffect, useState } from "react"
import { DeleteConfirmation } from "hivolution-front-utils"
import { remove, getCount } from "../api"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { addNotifications } from "/utils/store/notification"
import { useAuth } from "oidc-react"
import { errorNotification, successNotification } from "/utils/notification"
import { useTranslation } from "react-i18next"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { PointRoutesContext } from "../routes"

export default function Delete() {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["client"])
  const { point } = useContext(PointRoutesContext)

  const [relatedObjects, setRelatedObjects] = useState(null)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const count = async () => {
    await getCount(point.id, auth.userData.access_token)
      .then(response => {
        setRelatedObjects(response.data)
      })
      .catch(error => {
        displayNotification(errorNotification("Error", error.message))
      })
  }

  const removeObject = async () => {
    await remove(siteStore.id, point.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The automation point has been removed."))])
        navigate(`/sites/${siteStore.id}/assets/communication/automation-points/`)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    if (siteStore.id && point) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))

      dispatch(
        updateBreadcrumb([
          { label: t("Home"), url: "/" },
          { label: t("Sites"), url: "/sites/" },
          { label: t("Detail"), url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Communication"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: t("Automation points"), url: `/sites/${siteStore.id}/assets/communication/automation-points/` },
          { label: point.label ?? point.name, url: `/sites/${siteStore.id}/assets/communication/networks/${point.id}/` },
          { label: t("Delete"), url: `/sites/${siteStore.id}/assets/communication/automation-points/${point.id}/delete/` }
        ])
      )
      count()
    }
  }, [siteStore.id, point])

  return (
    <>
      {point && (
        <DeleteConfirmation
          objectType={t("Automation point")}
          objectNames={[point.label ?? point.name]}
          remove={removeObject}
          relatedObjects={relatedObjects}
          goBack={() => navigate(-1)}
        />
      )}
    </>
  )
}
