{
  "Action": "Action",
  "Back to edge block template list": "Back to edge block template list",
  "Condition": "Condition",
  "Create": "Create",
  "Create Edge block template": "Create Edge block template",
  "Delete": "Delete",
  "Description": "Description",
  "Detail": "Detail",
  "Edge block template": "Edge block template",
  "Edge block templates": "Edge block templates",
  "Home": "Home",
  "Name": "Name",
  "Priority": "Priority",
  "Save": "Save",
  "The edge block template has been created.": "The edge block template has been created.",
  "The edge block template has been removed": "The edge block template has been removed",
  "The edge block template has been updated.": "The edge block template has been updated.",
  "Updated by": "Updated by"
}
