import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { FormContent } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add } from "../api"
import { Store } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const { gateway } = useContext(GatewayRoutesContext)

  const [data, setData] = useState({ gateway: gateway.id })
  const [errors, setErrors] = useState({})
  const [connectorInstances, setConnectorInstances] = useState([])
  const [processing, setProcessing] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const create = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await add(siteStore.id, data, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Create", t("The store has been created."))]))
        navigate(`/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/${response.data.id}/`)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getConnectorInstances = async () => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`connectivity/sites/${siteStore.id}/connector_instances/?gateway=${gateway.id}`, auth.userData.access_token)()
      .then(results => {
        setConnectorInstances(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /* await retrieveConnectorInstances(siteId, gatewayId, auth.userData.access_token).then(response => {
                      setConnectorInstances(response.data.results)
                    }).catch(error => {
                      dispatch(addNotifications([errorNotification("Error", error.message)]))
                    }) */
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && gateway && gateway.id) {
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Connectivity"), url: `/sites/${siteStore.id}/connectivity/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` },
          { label: t("Stores"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/` },
          {
            label: t("Create"),
            url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/create/`
          }
        ])
      )

      getConnectorInstances()
    }
  }, [siteStore.id, gateway])

  return (
    <>
      {data && (
        <>
          <div className="base-content-title">
            <h2>{t("Create store")}</h2>
          </div>
          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={Store(connectorInstances)}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <Button
                rounded
                severity="success"
                label={t("Create")}
                disabled={processing}
                type="submit"
                icon="fa-solid fa-floppy-disk"
              />
              <Button
                rounded
                severity="info"
                label={t("Cancel")}
                disabled={processing}
                icon="fa-solid fa-xmark"
                onClick={goBack}
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}
