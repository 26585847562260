import i18n from "/utils/i18n"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

const t = str => i18n.t(str, { ns: "client" })

export const ConnectorInstance = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: t("Name"),
      field: InputText,
      options: { required: true }
    },
    stores_response_timeout: {
      label: t("Response timeout"),
      field: InputNumber,
      options: {
        required: true,
        showButtons: true
      }
    },
    description: {
      label: t("Description"),
      field: InputTextarea,
      options: {}
    }
  }
}
