import { retrieveList } from "/apps/client/energy/contract/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { REQUEST_DEFAULT_LIMIT } from "/utils/constants/api"
import { SortOrder } from "primereact/api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [items, setItems] = useState([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [next, setNext] = useState(false)
  const [loadingNext, setLoadingNext] = useState(true)
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})

  const retrieve = async () => {
    await retrieveList(siteStore.id, auth.userData.access_token, offset)
      .then(({ data }) => {
        if (data) {
          setCount(data.count)
          setNext(data.next)
          setOffset(offset + REQUEST_DEFAULT_LIMIT)
          setItems([...items, ...data.results])
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Energy contract", message)]))
      })
      .finally(() => {
        setLoading(false)
        setLoadingNext(false)
      })
  }

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "contract_type", header: t("Contract type"), sortable: true },
    { field: "energy_delivery_point_meter_id", header: t("Energy delivery point"), sortable: true },
    { field: "opti_gateways_names", header: t("Optimization gateways"), sortable: true },
    { field: "energy_company_name", header: t("Market player"), sortable: true }
    // { field: "valid", header: t("Valid") }
  ]

  const getDetailUrl = objectId => {
    return `/sites/${siteStore.id}/energy/contracts/${objectId}/`
  }

  const getDeleteUrl = objectId => {
    return `/sites/${siteStore.id}/energy/contracts/${objectId}/delete/`
  }

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const menuFilters = { items, columns, filters, setFilters }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Energy"), url: `/sites/${siteStore.id}/energy/energy-delivery-points/` },
          { label: t("Contracts"), url: `/sites/${siteStore.id}/energy/contracts/` }
        ])
      )

      retrieve()
    }
  }, [siteStore.id])

  return (
    <section className="list-section">
      <ListActions filters={menuFilters}>
        <Link to={`/sites/${siteStore.id}/energy/contracts/create/`}>
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        sortField="name"
        sortOrder={SortOrder.ASC}
        objectName={t("Contracts")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        count={count}
        next={next}
        loadMore={retrieve}
        loadingNext={loadingNext}
      />
    </section>
  )
}
