import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Dropdown } from "hivolution-front-utils"

import i18n from "/utils/i18n"

export const t = str => i18n.t(str, { ns: "client" })

export const commonsFields = spaces => {
  return {
    name: { label: t("Name"), field: InputText, options: { required: true } },
    model: { label: t("Model"), field: InputText, options: {} },
    manufacturer: { label: t("Manufacturer"), field: InputText, options: {} },
    space: {
      label: t("Space"),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        filter: true
      }
    },
    description: { label: t("Description"), field: InputTextarea, options: {} }
  }
}

export const commonsOverview = () => {
  return {
    name: { label: t("Name") },
    model: { label: t("Model") },
    manufacturer: { label: t("Manufacturer") },
    space_label: { label: t("Space") },
    description: { label: t("Description") }
  }
}
