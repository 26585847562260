import { InputNumber } from "primereact/inputnumber"
import { SelectModal } from "hivolution-front-utils"
import { commonsFields, commonsOverview, t } from "./commons"

export const SMMeterNode = (spaces, smNodes) => {
  return {
    ...commonsFields(spaces),
    ...{
      sm_node: {
        label: t("System manager"),
        field: SelectModal,
        options: {
          items: smNodes,
          columns: [
            { field: "name", header: t("Name") },
            { field: "model", header: t("Model") },
            { field: "manufacturer", header: t("Manufacturer") },
            { field: "proto", header: t("Protocol") },
            { field: "sm_type", header: t("SM Type") },
            { field: "software", header: t("Software") },
            { field: "user", header: t("User") }
          ],
          required: true,
          objectName: t("SM Nodes"),
          searchLabel: id => {
            let find = smNodes.find(node => node.id === id)
            return find ? find.name : ""
          }
        }
      },
      node_addr: {
        label: t("Node address"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      },
      meter_id: {
        label: t("Meter ID"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      }
    }
  }
}

export const SMMeterOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      sm_node_label: { label: t("System manager") },
      node_addr: { label: t("Node address") },
      meter_id: { label: t("Meter ID") }
    }
  }
}
