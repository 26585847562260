import i18n from "/utils/i18n"
import { Dropdown } from "hivolution-front-utils"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Gateway = (hardwares, spaces) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("Name", { ns: "client" }),
      field: InputText,
      options: { required: true }
    },
    hardware: {
      label: i18n.t("Hardware", { ns: "client" }),
      field: Dropdown,
      options: {
        required: true,
        options: hardwares,
        optionLabel: "label",
        optionValue: "id",
        filter: true
      }
    },
    space: {
      label: i18n.t("Space", { ns: "client" }),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        filter: true
      }
    },
    description: {
      label: i18n.t("Description", { ns: "client" }),
      field: InputTextarea,
      options: {}
    }
  }
}

export const GatewayOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("Name", { ns: "client" }) },
    hardware_label: { label: i18n.t("Hardware", { ns: "client" }) },
    space: { label: i18n.t("Space", { ns: "client" }) },
    description: { label: i18n.t("Description", { ns: "client" }) }
  }
}
