import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"

import { FormContent } from "hivolution-front-utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { update } from "../api"
import { Organization as OrganizationModel } from "../models"
import { useAuth } from "oidc-react"
import LogoInput from "./LogoInput"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { organization } from "/utils/store/organization"
import { useNavigate } from "react-router-dom"
import { Button } from "primereact/button"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["home"])
  const navigate = useNavigate()

  useEffect(() => {
    if (organizationStore) {
      setData(organizationStore)
    }
  }, [organizationStore])

  useEffect(() => {
    organizationStore &&
      organizationStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Organizations"), url: "/" },
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/` }
        ])
      )
  }, [organizationStore])

  const updateObject = async function (e) {
    e.preventDefault()
    await update(organizationStore.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The organization has been updated."))]))
        navigate("/")
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  return (
    data && (
      <>
        <form
          className="form-view"
          onSubmit={updateObject}
        >
          <FormContent
            model={OrganizationModel()}
            data={data}
            setData={setData}
            errors={errors}
          />

          <LogoInput
            data={data}
            setData={setData}
          />

          <div className="flex justify-content-end">
            <Button
              rounded
              className="button"
              severity="success"
              label={t("Save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </>
    )
  )
}
