import { createSlice } from "@reduxjs/toolkit"

export const notificationsSlice = createSlice({
  name: "notification",

  initialState: {
    items: []
  },

  reducers: {
    addNotifications: (state, action) => {
      state.item = action.payload
    }
  }
})

export const { addNotifications } = notificationsSlice.actions

export const notifications = state => state.notifications.item
