import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { BaseLayout, FormContent } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, getSpaces } from "../api"
import { Gateway } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [data, setData] = useState({
    type: "box",
    site: siteStore.id
  })
  const [errors, setErrors] = useState({})
  const [hardwares, setHardwares] = useState([])
  const [spaces, setSpaces] = useState([])
  const [processing, setProcessing] = useState(false)

  const getHW = async () => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest("connectivity/hardwares/", auth.userData.access_token)()
      .then(results => {
        setHardwares(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /* await getHardwares(auth.userData.access_token).then(response => {
      setHardwares(response.data.results)
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const getSP = async () => {
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(response => {
        setSpaces(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const create = async function (e) {
    e.preventDefault()
    setProcessing(true)

    await add(siteStore.id, data, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Create", t("The gateway has been created."))]))
        navigate(`/sites/${siteStore.id}/assets/gateways/${response.data.id}`)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: t("Create"), url: `/sites/${siteStore.id}/assets/gateways/create/` }
        ])
      )

      getHW()
      getSP()
    }
  }, [siteStore.id])

  return (
    <>
      {data && (
        <BaseLayout title={t("Create gateway")}>
          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={Gateway(hardwares, spaces)}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <Button
                rounded
                severity="success"
                label={t("Save")}
                type="submit"
                icon="fa-solid fa-floppy-disk"
                disabled={processing}
              />
            </div>
          </form>
        </BaseLayout>
      )}
    </>
  )
}
