import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

import i18n from "/utils/i18n"

export const GatewayBox = () => {
  return {
    manufacturer: { label: i18n.t("Manufacturer", { ns: "connectivity" }), field: InputText, options: {} },
    model: { label: i18n.t("Model", { ns: "connectivity" }), field: InputText, options: {} },
    serial_id: { label: i18n.t("Serial ID", { ns: "connectivity" }), field: InputText, options: { required: true } },
    description: { label: i18n.t("Description", { ns: "connectivity" }), field: InputTextarea }
  }
}
