import { createSlice } from "@reduxjs/toolkit"

export const organizationSlice = createSlice({
  name: "organization",

  initialState: {
    entry: { id: null }
  },

  reducers: {
    updateOrganization: (state, action) => {
      state.entry = action.payload
    },

    resetOrganization: state => {
      state.entry = { id: null }
    }
  }
})

export const { updateOrganization, resetOrganization } = organizationSlice.actions
export const organization = state => state.organization.entry
