import { PROTOCOL_INSTANCE_TYPE } from "/utils/constants/protocol-instance"
import i18n from "/utils/i18n"
import { Dropdown } from "hivolution-front-utils"
import { InputNumber } from "primereact/inputnumber"

const t = str => i18n.t(str, { ns: "client" })

export const ProtocolInstanceOverview = () => ({
  label: { label: t("Type") },
  gateway_ip_interface_label: { label: t("Gateway interface") }
})

export const ProtocolInstance = (gatewayInterfaces, editMode = false) => {
  return {
    type: {
      label: t("Type"),
      field: Dropdown,
      options: {
        required: true,
        options: [
          { value: "bacnetip", label: t(PROTOCOL_INSTANCE_TYPE["bacnetip"]) },
          { value: "modbusip", label: t(PROTOCOL_INSTANCE_TYPE["modbusip"]) },
          { value: "smxmlapi", label: t(PROTOCOL_INSTANCE_TYPE["smxmlapi"]) },
          { value: "opcua", label: t(PROTOCOL_INSTANCE_TYPE["opcua"]) },
          { value: "carelxmlapi", label: t(PROTOCOL_INSTANCE_TYPE["carelxmlapi"]) }
        ],
        disabled: editMode
      }
    },
    gateway_ip_interface: {
      label: t("Gateway interface"),
      field: Dropdown,
      dependsOn: "type",
      options: {
        required: true,
        optionLabel: "label",
        optionValue: "id",
        options: gatewayInterfaces
      }
    },
    gateway_udp_port: {
      label: t("Gateway UDP Port"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "bacnetip",
      options: {
        required: true,
        showButtons: true
      }
    },
    batch_size: {
      label: t("Batch size"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "smxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    },
    batch_size_write: {
      label: t("Batch size writing"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "smxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    },
    sleep_time: {
      label: t("Sleep time"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "smxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    },
    keepalive_timer: {
      label: t("Keep alive timer"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    connect_timeout: {
      label: t("Connect timeout"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    read_timeout: {
      label: t("Read timeout"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    write_timeout: {
      label: t("Write timeout"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    backoff_max_wait: {
      label: t("Backoff max wait"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    set_wait_time: {
      label: t("Set wait time"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "carelxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    }
  }
}
