import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation, useParams } from "react-router-dom"
import { TabsLayout } from "hivolution-front-utils"
import EnergyDeliveryPointRoutes from "./energy-delivery-point/routes"
import ContractRoutes from "./contract/routes"

export default function EnergyRoutes() {
  const location = useLocation()
  const { t } = useTranslation(["client"])
  const { siteId } = useParams()
  const [tabs, setTabs] = useState([
    {
      name: t("Delivery points"),
      active: location.pathname.includes("points") ? true : false,
      url: `/sites/${siteId}/energy/energy-delivery-points/`
    },
    {
      name: t("Contracts"),
      active: location.pathname.includes("contracts") ? true : false,
      url: `/sites/${siteId}/energy/contracts/`
    }
  ])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("Energy")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="energy-delivery-points/*"
          element={<EnergyDeliveryPointRoutes />}
        />
        <Route
          index
          path="contracts/*"
          element={<ContractRoutes />}
        />
      </Route>
    </Routes>
  )
}
