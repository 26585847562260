import { InputText } from "primereact/inputtext"
import { Dropdown, DateInput } from "hivolution-front-utils"
import { InputTextarea } from "primereact/inputtextarea"
import { InputNumber } from "primereact/inputnumber"
import { MultiSelect } from "primereact/multiselect"

import i18n from "/utils/i18n"

const t = str => i18n.t(str, { ns: "client" })

export const Contract = (energyDeliveryPoints, energyCompanies, gateways, loadingDeliveryPoints = false, loadingCompanies = false, loadingGateways = false) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: t("Name"), field: InputText, options: { required: true } },
    contract_type: {
      label: t("Contract type"),
      field: Dropdown,
      options: {
        options: [
          { value: t("Energy"), label: "Energy" },
          { value: t("Peak demand"), label: "Peak" },
          { label: t("Flexibility"), value: "Flexibility" }
        ],
        placeholder: "Select contract type",
        required: true
      }
    },
    energy_delivery_point: {
      label: t("Energy delivery point"),
      field: Dropdown,
      options: {
        required: true,
        options: energyDeliveryPoints,
        optionLabel: "meter_id",
        optionValue: "id",
        placeholder: loadingDeliveryPoints ? "Loading..." : "Select delivery point",
        loading: loadingDeliveryPoints
      }
    },
    description: {
      label: t("Description"),
      field: InputTextarea,
      options: {}
    },
    contract_step: {
      label: t("Contract step"),
      field: InputNumber,
      options: {
        showButtons: true
      }
    },
    start_validity: {
      label: t("Start validity"),
      field: DateInput,
      options: {}
    },
    end_validity: {
      label: t("End validity"),
      field: DateInput,
      options: {}
    },
    energy_company: {
      label: t("Market player"),
      field: Dropdown,
      options: {
        options: energyCompanies,
        optionValue: "id",
        optionLabel: "name",
        placeholder: loadingCompanies ? "Loading..." : "Select company",
        loading: loadingCompanies
      }
    },
    optimization_gateways: {
      label: t("Optimization gateways"),
      field: MultiSelect,
      options: {
        options: gateways,
        optionValue: "id",
        optionLabel: "name",
        filter: gateways && gateways.length > 20,
        display: "chip",
        placeholder: loadingGateways ? "Loading..." : "Select gateway",
        loading: loadingGateways
      }
    }
  }
}

export const ContractOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: t("Name") },
    contract_type: { label: t("Contract type") },
    energy_delivery_point_meter_id: { label: t("Energy delivery point") },
    description: { label: t("Description") },
    contract_step: { label: t("Contract step") },
    start_validity: { label: t("Start validity") },
    end_validity: { label: t("End validity") },
    energy_company_name: { label: t("Market player") },
    opti_gateways_names: { label: t("Optimization gateways") }
  }
}
