import { retrieve } from "/apps/client/connectivity/network-interface/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Delete from "./components/Delete"
import Detail from "./components/Detail"

import List from "./components/List"

const NetworkInterfaceRoutesContext = createContext(null)

const NetworkInterfaceContent = () => {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { networkInterfaceId } = useParams()
  const [networkInterface, setNetworkInterface] = useState(null)

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, networkInterfaceId, auth.userData.access_token)
      .then(response => {
        setNetworkInterface({
          ...response.data,
          network: response.data.network.id,
          network_label: response.data.network.label
        })
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && siteStore && retrieveDetail()
  }, [siteStore.id, networkInterfaceId])

  return (
    <NetworkInterfaceRoutesContext.Provider value={{ networkInterface, retrieveDetail }}>
      <Outlet />
    </NetworkInterfaceRoutesContext.Provider>
  )
}

export { NetworkInterfaceRoutesContext }
export default function NetworkInterfaceRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path=":networkInterfaceId"
        element={<NetworkInterfaceContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
