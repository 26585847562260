import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes"
import { StoreRoutesContext } from "/apps/client/connectivity/store/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getCount, remove } from "../api"

export default function Delete() {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["client"])
  const [relatedObjects, setRelatedObjects] = useState(null)
  const { store } = useContext(StoreRoutesContext)
  const { gateway } = useContext(GatewayRoutesContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const count = async () => {
    await getCount(siteStore.id, auth.userData.access_token)
      .then(response => {
        setRelatedObjects(response.data)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  const removeObject = async () => {
    await remove(siteStore.id, store.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The store has been removed."))])
        navigate(`/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/`)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && gateway && gateway.id && store && store.id) {
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Connectivity"), url: `/sites/${siteStore.id}/connectivity/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` },
          { label: t("Stores"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/` },
          { label: store.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/${store.id}/` },
          { label: t("Delete"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/${store.id}/delete/` }
        ])
      )
      count()
    }
  }, [siteStore.id, gateway, store])

  return (
    <>
      {store ? (
        <DeleteConfirmation
          objectType={t("Store")}
          objectNames={[store.name]}
          remove={removeObject}
          relatedObjects={relatedObjects}
          goBack={() => navigate(-1)}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}
