import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes"
import { addInterface, retrieveList } from "/apps/client/connectivity/network-interface/api"
import { NetworkIPInterface } from "/apps/client/connectivity/network-interface/models"
import { paginatedRequest } from "/utils/api"
import { REQUEST_DEFAULT_LIMIT } from "/utils/constants/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { FormContent, ListActions, ListLayout, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import { useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const { gateway } = useContext(GatewayRoutesContext)
  const [items, setItems] = useState([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [next, setNext] = useState(false)
  const [networks, setNetworks] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingNext, setLoadingNext] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [connectVisible, setConnectVisible] = useState(false)
  const [errors, setErrors] = useState({})
  const [filters, setFilters] = useState({})
  const [interfaceForm, setInterfaceForm] = useState({
    type: "ipnetwork",
    gateway: gateway.id
  })

  const retrieve = async () => {
    setLoadingNext(true)
    await retrieveList(siteStore.id, gateway.id, auth.userData.access_token, offset)
      .then(({ data }) => {
        if (data) {
          const networkInterfaces = data.results.map(item => ({
            ...item,
            network: item.network.id,
            network_label: item.network.label
          }))

          setCount(data.count)
          setNext(data.next)
          setOffset(offset + REQUEST_DEFAULT_LIMIT)
          setItems([...items, ...networkInterfaces])
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setLoading(false)
        setLoadingNext(false)
      })
  }

  const retrieveNetworks = async () => {
    await paginatedRequest(`asset/sites/${siteStore.id}/networks/?type=ipnetwork`, auth.userData.access_token)()
      .then(results => {
        setNetworks(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const interfaceAdd = async e => {
    e.preventDefault()
    setProcessing(true)
    await addInterface(siteStore.id, interfaceForm, auth.userData.access_token)
      .then(async () => {
        dispatch(addNotifications([successNotification("Network interface", t("The gateway interface has been created."))]))

        // Reset list
        await setNext(false)
        await setOffset(0)
        await setItems([])

        retrieve()
        setConnectVisible(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  const getDetailUrl = interfaceId => `/sites/${siteStore.id}/assets/gateways/${gateway.id}/network-interfaces/${interfaceId}/`

  const getDeleteUrl = interfaceId => `/sites/${siteStore.id}/assets/gateways/${gateway.id}/network-interfaces/${interfaceId}/delete/`

  const onRowClick = networkId => {
    navigate(getDetailUrl(networkId))
  }

  const goBack = e => {
    e && e.preventDefault()
    setConnectVisible(false)
  }

  const columns = [
    { field: "network_label", header: t("Network") },
    { field: "ip_addr", header: t("IP Address") }
  ]

  const menuEntries = []
  const menuFilters = { columns, items, filters, setFilters }

  useEffect(() => {
    if (gateway && gateway.id && siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))

      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: t("Detail"), url: `/sites/${siteStore.id}/` },
          { label: t("Connectivity"), url: `/sites/${siteStore.id}/connectivity/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` }
        ])
      )

      retrieve()
    }
  }, [siteStore.id, gateway])

  useEffect(() => {
    connectVisible && retrieveNetworks()
  }, [connectVisible])

  return (
    <>
      {connectVisible ? (
        <>
          {networks && !!networks.length ? (
            <form
              className="form-view"
              onSubmit={interfaceAdd}
            >
              <div className="base-content-title">
                <h2>{t("Connect a network")}</h2>
              </div>

              <FormContent
                model={NetworkIPInterface(networks)}
                data={interfaceForm}
                setData={setInterfaceForm}
                errors={errors}
              />
              <div className="action-buttons-wrapper">
                <Button
                  rounded
                  severity="success"
                  label={t("Connect")}
                  type="submit"
                  disabled={processing}
                  icon="fa-solid fa-floppy-disk"
                />
                <Button
                  rounded
                  severity="info"
                  label={t("Cancel")}
                  disabled={processing}
                  icon="fa-solid fa-xmark"
                  onClick={goBack}
                />
              </div>
            </form>
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <section className="list-section">
          <ListActions
            entries={menuEntries}
            filters={menuFilters}
          >
            <Button
              rounded
              label={t("Connect")}
              icon="fa-solid fa-plug-circle-plus"
              onClick={() => setConnectVisible(true)}
              severity="info"
            />
          </ListActions>
          <ListLayout
            columns={columns}
            value={items}
            loading={loading}
            filters={filters}
            getDetailUrl={getDetailUrl}
            getDeleteUrl={getDeleteUrl}
            onRowClick={onRowClick}
            count={count}
            next={next}
            loadMore={retrieve}
            loadingNext={loadingNext}
          />
        </section>
      )}
    </>
  )
}
