import * as React from "react"
import { Route, Routes } from "react-router-dom"

import GatewayRoutes from "./gateway/routes"
import ParameterRoutes from "./parameter/routes"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { updateSideBar } from "/utils/store/sidebar.js"

export default function ConnectivityRoutes() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "parameters", siteId: null }))
  }, [])

  return (
    <Routes>
      <Route
        path="/gateways/*"
        element={<GatewayRoutes />}
      />
      <Route
        path="/parameters/*"
        element={<ParameterRoutes />}
      />
    </Routes>
  )
}
