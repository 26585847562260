import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieve(flexId, token) {
  return await axios.get(`${window.BACKEND_FLEX_URL}/flex/${flexId}/opti_activation`, header(token))
}

export async function add(data, token) {
  return await axios.post(`${window.BACKEND_FLEX_URL}/flex/opti_activation`, data, header(token))
}

export async function remove(flexOptiId, token) {
  return await axios.delete(`${window.BACKEND_FLEX_URL}/flex/opti_activation/${flexOptiId}`, header(token))
}
