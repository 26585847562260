{
  "Action": "Action",
  "Active": "Active",
  "Add edge block": "Add edge block",
  "Add edge memory object": "Add edge memory object",
  "Add Equivalent Metering": "Add Equivalent Metering",
  "Address": "Address",
  "Aggregator Response": "Aggregator Response",
  "Altitude": "Altitude",
  "An error occured during the import, see more details in the error report file.": "An error occured during the import, see more details in the error report file.",
  "Analogue (2)": "Analogue (2)",
  "Analogue Input (0)": "Analogue Input (0)",
  "Analogue Output (1)": "Analogue Output (1)",
  "Analogue Value (2)": "Analogue Value (2)",
  "Alarm (4)": "Alarm (4)",
  "Are you sure you want to delete this interface?": "Are you sure you want to delete this interface?",
  "Are you sure you want to delete this protocol instance?": "Are you sure you want to delete this protocol instance?",
  "Automation node": "Automation node",
  "Automation nodes": "Automation nodes",
  "Automation point": "Automation point",
  "Automation point type": "Automation point type",
  "Automation points": "Automation points",
  "Back to connector instance list": "Back to connector instance list",
  "Back to contract list": "Back to contract list",
  "Back to edge controller list": "Back to edge controller list",
  "Back to energy delivery point list": "Back to energy delivery point list",
  "Back to equipment list": "Back to equipment list",
  "Back to gateway list": "Back to gateway list",
  "Back to network list": "Back to network list",
  "Back to site list": "Back to site list",
  "Back to space list": "Back to space list",
  "Back to state list": "Back to state list",
  "Back to store list": "Back to store list",
  "Backoff max wait": "Backoff max wait",
  "Bacnet network": "Bacnet network",
  "Bacnet number": "Bacnet number",
  "Bacnet priority": "Bacnet priority",
  "Bacnet priority uninhibition": "Bacnet priority uninhibition",
  "Bacnet property": "Bacnet property",
  "Batch size": "Batch size",
  "Batch size writing": "Batch size writing",
  "Binary Input (3)": "Binary Input (3)",
  "Binary Output (4)": "Binary Output (4)",
  "Binary Value (5)": "Binary Value (5)",
  "Building": "Building",
  "Carel node": "Carel node",
  "Carel nodes": "Carel nodes",
  "Choose a node type": "Choose a node type",
  "Choose a sub type": "Choose a sub type",
  "City": "City",
  "Client": "Client",
  "Coef": "Coef",
  "Coil": "Coil",
  "Comment": "Comment",
  "Communication": "Communication",
  "Condition": "Condition",
  "Configuration": "Configuration",
  "Confirm password": "Confirm password",
  "Confirmed curtail schedules": "Confirmed curtail schedules",
  "Connect": "Connect",
  "Connect timeout": "Connect timeout",
  "Connector instances": "Connector instances",
  "Constraint": "Constraint",
  "Contract step": "Contract step",
  "Contract type": "Contract type",
  "Contracts": "Contracts",
  "Control light": "Control light",
  "Control method": "Control method",
  "Cooled": "Cooled",
  "Counter": "Counter",
  "Country": "Country",
  "Create": "Create",
  "Create automation node": "Create automation node",
  "Create connector instance": "Create connector instance",
  "Create contract": "Create contract",
  "Create curtail parameters": "Create curtail parameters",
  "Create edge controller": "Create edge controller",
  "Create energy delivery point": "Create energy delivery point",
  "Create equipment": "Create equipment",
  "Create gateway": "Create gateway",
  "Create network": "Create network",
  "Create point": "Create point",
  "Create proposal for aggregator": "Create proposal for aggregator",
  "Create protocol instance": "Create protocol instance",
  "Create site.": "Create site.",
  "Create space": "Create space",
  "Create state": "Create state",
  "Create store": "Create store",
  "Created at": "Created at",
  "Curtail": "Curtail",
  "Curtail capacity": "Curtail capacity",
  "Curtail end": "Curtail end",
  "Curtail parameters": "Curtail parameters",
  "Curtail parameters have been created.": "Curtail parameters have been created.",
  "Curtail parameters have been removed.": "Curtail parameters have been removed.",
  "Curtail parameters have been updated.": "Curtail parameters have been updated.",
  "Curtail start": "Curtail start",
  "Data step": "Data step",
  "Delete": "Delete",
  "Deployed": "Deployed",
  "Detail": "Detail",
  "Device ID": "Device ID",
  "Device instance": "Device instance",
  "Device type": "Device type",
  "Disable storage": "Disable storage",
  "Discrete input": "Discrete input",
  "Distributor": "Distributor",
  "Digital (1)": "Digital (1)",
  "Edge blocks": "Edge blocks",
  "Edge controller": "Edge controller",
  "Edge controller history": "Edge controller history",
  "Edge controllers": "Edge controllers",
  "Edge memory objects": "Edge memory objects",
  "Electricity": "Electricity",
  "End date": "End date",
  "End time": "End time",
  "End validity": "End validity",
  "Energy": "Energy",
  "Energy delivery point": "Energy delivery point",
  "Energy delivery points": "Energy delivery points",
  "Equipment": "Equipment",
  "Equipments": "Equipments",
  "Equivalent Metering Intercept": "Equivalent Metering Intercept",
  "Equivalent Meterings": "Equivalent Meterings",
  "Eval": "Eval",
  "Execution desired": "Execution desired",
  "Export": "Export",
  "Export automation nodes": "Export automation nodes",
  "Export equipments": "Export equipments",
  "Export spaces": "Export spaces",
  "External ID": "External ID",
  "Flex contract": "Flex contract",
  "Flex opti": "Flex opti",
  "Flexibility": "Flexibility",
  "Float": "Float",
  "Gas": "Gas",
  "Gateway": "Gateway",
  "Gateway interface": "Gateway interface",
  "Gateway interfaces": "Gateway interfaces",
  "Gateway point text": "Gateway point text",
  "Gateway point value": "Gateway point value",
  "Gateway UDP Port": "Gateway UDP Port",
  "Gateways": "Gateways",
  "Hardware": "Hardware",
  "Heated": "Heated",
  "Height (m)": "Height (m)",
  "History": "History",
  "History delete": "History delete",
  "History detail": "History detail",
  "Home": "Home",
  "Import": "Import",
  "Import automation nodes": "Import automation nodes",
  "Import equipments": "Import equipments",
  "Import spaces": "Import spaces",
  "Inhibit": "Inhibit",
  "Inhibit ignore": "Inhibit ignore",
  "Inhibit key": "Inhibit key",
  "Inhibit light": "Inhibit light",
  "Initial values": "Initial values",
  "Int": "Int",
  "Integer (3)": "Integer (3)",
  "IO node type": "IO node type",
  "ID Var": "ID Var",
  "IP": "IP",
  "IP Address": "IP Address",
  "IP address range": "IP address range",
  "IP addresses": "IP addresses",
  "IP Range": "IP Range",
  "Is Logic": "Is logic",
  "Keep alive timer": "Keep alive timer",
  "Latitude": "Latitude",
  "Line ID": "Line ID",
  "Linear constant": "Linear constant",
  "Linear multiplier": "Linear multiplier",
  "Load curtail parameters": "Load curtail parameters",
  "Load curtail schedule": "Load curtail schedule",
  "Longitude": "Longitude",
  "Manage modbus timeout": "Manage modbus timeout",
  "Manufacturer": "Manufacturer",
  "Market player": "Market player",
  "Market player interaction": "Market player interaction",
  "Max count": "Max count",
  "Max Schedules Per Day": "Max Schedules Per Day",
  "Meter ID": "Meter ID",
  "Model": "Model",
  "MSTP address": "MSTP address",
  "Multi-case name": "Multi-case name",
  "Multi State Input (13)": "Multi State Input (13)",
  "Multi State Output (14)": "Multi State Output (14)",
  "Name": "Name",
  "Network": "Network",
  "Networks": "Networks",
  "Neutralization duration": "Neutralization duration",
  "No": "No",
  "Node": "Node",
  "Node address": "Node address",
  "Node interfaces": "Node interfaces",
  "Number of batches": "Number of batches",
  "Number of points": "Number of points",
  "Number of stores": "Number of stores",
  "Object instance": "Object instance",
  "Number of words": "Number of words",
  "Object name": "Object name",
  "Object type": "Object type",
  "Optimization": "Optimization",
  "Optimization gateways": "Optimization gateways",
  "Optimized": "Optimisé",
  "Original point value": "Original point value",
  "Overview": "Overview",
  "Parent space": "Parent space",
  "Password": "Password",
  "Passwords must be the same": "Passwords must be the same",
  "Path": "Path",
  "Period": "Period",
  "Permeable": "Permeable",
  "Persistent": "Persistent",
  "Point": "Point",
  "Points": "Points",
  "Points has been removed.": "Points has been removed.",
  "Points optimized": "Points optimized",
  "Points writable": "Points writable",
  "Points has been removed": "Points has been removed",
  "Port": "Port",
  "Post-cooling end date": "Post-cooling end date",
  "Post-cooling end time": "Post-cooling end time",
  "Postcool": "Postcool",
  "Pre-cooling start date": "Pre-cooling start date",
  "Pre-cooling start time": "Pre-cooling start time",
  "Present value": "Present value",
  "Precool": "Precool",
  "Pretty name": "Pretty name",
  "Price zone": "Price zone",
  "Priority": "Priority",
  "Priority 1": "Priority 1",
  "Priority 2": "Priority 2",
  "Priority 3": "Priority 3",
  "Priority 4": "Priority 4",
  "Priority 5": "Priority 5",
  "Priority 6": "Priority 6",
  "Priority 7": "Priority 7",
  "Priority 8": "Priority 8",
  "Priority 9": "Priority 9",
  "Priority 10": "Priority 10",
  "Priority 11": "Priority 11",
  "Priority 12": "Priority 12",
  "Priority 13": "Priority 13",
  "Priority 14": "Priority 14",
  "Priority 15": "Priority 15",
  "Priority 16": "Priority 16",
  "Priority array": "Priority array",
  "Proposal for aggregator": "Proposal for aggregator",
  "Proposal sent": "Proposal sent",
  "Proposed by": "Proposed by",
  "Proposed curtail schedules": "Proposed curtail schedules",
  "Proposed time": "Proposed time",
  "Protocol": "Protocol",
  "Protocol instances": "Protocol instances",
  "Protocol type": "Protocol type",
  "Protocols": "Protocols",
  "Pulling interval": "Pulling interval",
  "Read": "Read",
  "Read timeout": "Read timeout",
  "Read/Write": "Read/Write",
  "Read / Write": "Read / Write",
  "Rebound correction": "Rebound correction",
  "Rectangle": "Rectangle",
  "Relinquish default": "Relinquish default",
  "Request time": "Request time",
  "Requested by": "Requested by",
  "Requested?": "Requested?",
  "Resolution interval": "Resolution interval",
  "Response timeout": "Response timeout",
  "Response timeouts has been updated": "Response timeouts has been updated",
  "Router": "Router",
  "Safe state": "Safe state",
  "Save": "Save",
  "Scenario priority": "Scenario priority",
  "Schedule duration": "Schedule duration",
  "Scheme": "Scheme",
  "Security mode": "Security mode",
  "Security policy": "Security policy",
  "Select a client": "Select a client",
  "Select a gateway": "Select a gateway",
  "Select a price zone": "Select a price zone",
  "Select a state type": "Select a state type",
  "Select a weather zone": "Select a weather zone",
  "Select an edge memory object type": "Select an edge memory object type",
  "Send to aggregator": "Send to aggregator",
  "Sent by": "Sent by",
  "Serial": "Serial",
  "Serial ID": "Serial ID",
  "Server certificate path": "Server certificate path",
  "Set wait time": "Set wait time",
  "Site": "Site",
  "Sites": "Sites",
  "Slave ID": "Slave ID",
  "Sleep time": "Sleep time",
  "SM Nodes": "SM Nodes",
  "Software": "Software",
  "Space": "Space",
  "Spaces": "Spaces",
  "Start date": "Start date",
  "Start time": "Start time",
  "Start validity": "Start validity",
  "State": "State",
  "State history": "State history",
  "State values": "State values",
  "States": "States",
  "Status": "Status",
  "Status flags": "Status flags",
  "Store": "Store",
  "Store name": "Store name",
  "Store type": "Store type",
  "Stores": "Stores",
  "Subtype": "Subtype",
  "Surface area (m²)": "Surface area (m²)",
  "Synchronize legacy": "Synchronize legacy",
  "System manager": "System manager",
  "Swap words": "Swap words",
  "Swap bytes": "Swap bytes",
  "Target": "Target",
  "Template": "Template",
  "Template automation nodes": "Template automation nodes",
  "Template equipments": "Template equipments",
  "Template spaces": "Template spaces",
  "Test Schedule": "Test Schedule",
  "The automation node has been created.": "The automation node has been created.",
  "The automation node has been removed.": "The automation node has been removed.",
  "The automation node has been updated.": "The automation node has been updated.",
  "The automation point has been created.": "The automation point has been created.",
  "The automation point has been removed.": "The automation point has been removed.",
  "The automation point has been updated.": "The automation point has been updated.",
  "The connector instance has been created.": "The connector instance has been created.",
  "The connector instance has been removed.": "The connector instance has been removed.",
  "The connector instance has been updated.": "The connector instance has been updated.",
  "The contract has been created.": "The contract has been created.",
  "The contract has been removed.": "The contract has been removed.",
  "The contract has been updated.": "The contract has been updated.",
  "The curtail schedule has been removed": "The curtail schedule has been removed",
  "The curtail schedule has been updated.": "The curtail schedule has been updated.",
  "The edge controller has been created.": "The edge controller has been created.",
  "The edge controller has been removed.": "The edge controller has been removed.",
  "The edge controller has been updated.": "The edge controller has been updated.",
  "The energy delivery point has been created.": "The energy delivery point has been created.",
  "The energy delivery point has been removed.": "The energy delivery point has been removed.",
  "The energy delivery point has been updated.": "The energy delivery point has been updated.",
  "The equipment has been created.": "The equipment has been created.",
  "The equipment has been removed.": "The equipment has been removed.",
  "The equipment has been updated.": "The equipment has been updated.",
  "The flex contract has been created.": "The flex contract has been created.",
  "The flex opti has been created.": "The flex opti has been created.",
  "The flex opti has been removed.": "The flex opti has been removed.",
  "The flex opti has been updated.": "The flex opti has been updated.",
  "The gateway has been created.": "The gateway has been created.",
  "The gateway has been removed.": "The gateway has been removed.",
  "The gateway has been updated.": "The gateway has been updated.",
  "The gateway interface has been created.": "The gateway interface has been created.",
  "The gateway interface has been removed.": "The gateway interface has been removed.",
  "The gateway interface has been updated.": "The gateway interface has been updated.",
  "The interface has been created.": "The interface has been created.",
  "The interface has been removed.": "The interface has been removed.",
  "The interface has been updated.": "The interface has been updated.",
  "The network has been created.": "The network has been created.",
  "The network has been removed.": "The network has been removed.",
  "The network has been updated.": "The network has been updated.",
  "The point has been created.": "The point has been created.",
  "The point has been removed.": "The point has been removed.",
  "The point has been updated.": "The point has been updated.",
  "The proposal for aggregator has been created.": "The proposal for aggregator has been created.",
  "The proposal for aggregator has been removed.": "The proposal for aggregator has been removed.",
  "The proposal has been sent to the aggregator.": "The proposal has been sent to the aggregator.",
  "The proposed curtail schedule has been removed.": "The proposed curtail schedule has been removed.",
  "The proposed curtail schedule has been updated.": "The proposed curtail schedule has been updated.",
  "The proposed curtail schedules has been created.": "The proposed curtail schedules has been created.",
  "The protocol instance has been created.": "The protocol instance has been created.",
  "The protocol instance has been removed.": "The protocol instance has been removed.",
  "The protocol instance has been updated.": "The protocol instance has been updated.",
  "The site has been created.": "The site has been created.",
  "The site has been removed.": "The site has been removed.",
  "The site has been updated.": "The site has been updated.",
  "The space has been created.": "The space has been created.",
  "The space has been removed.": "The space has been removed.",
  "The space has been updated.": "The space has been updated.",
  "The state has been created.": "The state has been created.",
  "The state has been removed.": "The state has been removed.",
  "The state has been updated.": "The state has been updated.",
  "The store has been created.": "The store has been created.",
  "The store has been removed.": "The store has been removed.",
  "The store has been updated.": "The store has been updated.",
  "Transmission Company": "Transmission Company",
  "Transport type": "Transport type",
  "Type": "Type",
  "Unit": "Unit",
  "Type of bit": "Type of bit",
  "UInt": "UInt",
  "Uninhibit priority": "Uninhibit priority",
  "Upcoming load curtailment for": "Upcoming load curtailment for",
  "Update response timeouts": "Update response timeouts",
  "Updated at": "Updated at",
  "Updated by": "Updated by",
  "User": "User",
  "Valid": "Valid",
  "Value": "Value",
  "Weather zone": "Weather zone",
  "Write timeout": "Write timeout",
  "Write": "Write",
  "Writable": "Writable",
  "Yes": "Yes",
  "Zip code": "Zip code",
  "Zone name": "Zone name"
}
