import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

function headerFile(token) {
  return {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/`, header(token))
}

export async function retrieve(siteId, networkId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/${networkId}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/`, data, header(token))
}

export async function update(siteId, networkId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/${networkId}/`, data, header(token))
}

export async function remove(siteId, networkId, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/${networkId}/`, header(token))
}

export async function getCount(networkId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/networks/${networkId}/count`, header(token))
}

export async function getNetworksCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/networks/count/`, ids, header(token))
}

export async function removeNetworks(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/deletion/`, ids, header(token))
}

export async function getSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, header(token))
}

export async function getGatewayInterfaces(siteId, networkId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/?network=${networkId}`, header(token))
}

export async function getNodeInterfaces(siteId, networkId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/node_interfaces/?network=${networkId}`, header(token))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/allvalues`, header(token))
}

export async function exportNetworks(siteId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/networks/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function exportTemplate(siteId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/networks/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importNetwork(siteId, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/bulk/import`, formData, headerFile(token), { signal: new AbortController() })
}
