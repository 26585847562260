import { breadcrumb } from "/utils/store/breadcrumb"

import { BreadCrumb } from "primereact/breadcrumb"
import React from "react"
import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"

export default function HivolutionBreadCrumb() {
  const { t } = useTranslation(["home"])
  const items = useSelector(breadcrumb)

  return (
    <BreadCrumb
      model={items}
      home={{ label: t("Home"), url: "/" }}
      separatorIcon=""
    />
  )
}
