{
  "Aggregator": "Aggrégateur",
  "Aggregators": "Aggrégateurs",
  "Back to aggregator list": "Retour à la liste des aggrégateurs",
  "Back to distributor list": "Retour à la liste des distributeurs",
  "Back to retailer list": "Retour à la liste des détaillants",
  "Back to TSO list": "Retour à la liste des TSO",
  "Country": "Pays",
  "Create": "Création",
  "Create aggregator": "Créer un aggrégateur",
  "Create distributor": "Créer un distributeur",
  "Create retailer": "Créer un détaillant",
  "Create TSO": "Créer un TSO",
  "Delete": "Suppression",
  "Detail": "Détail",
  "Distributor": "Distributeur",
  "Distributors": "Distributeurs",
  "Energy companies": "Entreprises énergétiques",
  "Home": "Accueil",
  "Name": "Nom",
  "Retailer": "Détaillant",
  "Retailers": "Détaillants",
  "Save": "Sauvegarder",
  "The aggregator has been created.": "L'aggrégateur a été créé.",
  "The aggregator has been removed.": "L'aggrégateur a été supprimé.",
  "The aggregator has been updated.": "L'aggrégateur a été mis à jour.",
  "The distributor has been created.": "Le distributeur a été créé.",
  "The distributor has been removed.": "Le distributeur a été supprimé.",
  "The distributor has been updated.": "Le distributeur a été mis à jour.",
  "The retailer has been created.": "Le détaillant a été créé.",
  "The retailer has been removed.": "Le détaillant a été supprimé.",
  "The retailer has been updated.": "Le détaillant a été mis à jour.",
  "The TSO has been created.": "Le TSO a été créé.",
  "The TSO has been removed.": "Le TSO a été supprimé.",
  "The TSO has been updated.": "Le TSO a été mis à jour.",
  "TSO": "TSO"
}
