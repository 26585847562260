import { Dropdown } from "hivolution-front-utils"
import { InputText } from "primereact/inputtext"
import i18n from "/utils/i18n"
import { InputTextarea } from "primereact/inputtextarea"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"

const t = str => i18n.t(str, { ns: "client" })

export const Space = spaces => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: t("Name"), field: InputText, options: { required: true } },

    parent_space_id: {
      label: t("Parent space"),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id"
      }
    },
    description: { label: t("Description"), field: InputTextarea, options: {} },
    surface_area: {
      label: t("Surface area (m²)"),
      field: InputNumber,
      options: { showButtons: true }
    },
    height: {
      label: "Height (m)",
      field: InputNumber,
      options: { showButtons: true }
    },
    is_optimized: {
      label: t("Optimized"),
      field: InputSwitch,
      options: {}
    },
    is_permeable: {
      label: t("Permeable"),
      field: InputSwitch,
      options: {}
    },
    is_heated: {
      label: t("Heated"),
      field: InputSwitch,
      options: {}
    },
    is_cooled: {
      label: t("Cooled"),
      field: InputSwitch,
      options: {}
    }
  }
}

export const SpaceOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: t("Name") },
    description: { label: t("Description") },
    parent_space_id: { label: t("Parent space") },
    surface_area: { label: t("Surface area (m²)") },
    is_optimized: { label: t("Optimized") },
    is_permeable: { label: t("Permeable") },
    is_heated: { label: t("Heated") },
    is_cooled: { label: t("Cooled") }
  }
}
