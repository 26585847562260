import { errorNotification, successNotification } from "/utils/notification"

import { addNotifications } from "/utils/store/notification"

import { ListLayout, ListActions } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import { Link, useNavigate, useParams } from "react-router-dom"

import { exportSpaces, importSpaces, retrieveList } from "../api"
import { site } from "/utils/store/site"
import { updateSideBar } from "/utils/store/sidebar"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { Button } from "primereact/button"
import fileDownload from "js-file-download"
import { datetimeStr } from "/utils/datetime"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const { siteId } = useParams()

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const hiddenFileInput = useRef(null)

  const retrieve = async () => {
    await retrieveList(siteId, auth.userData.access_token)
      .then(response => {
        if (response.data) {
          setItems(response.data)
        }
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))
    retrieve()
  }, [siteId])

  useEffect(() => {
    siteStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Building"), url: `/sites/${siteStore.id}/assets/building/equipments/` },
          { label: t("Spaces"), url: `/sites/${siteStore.id}/assets/building/spaces/` }
        ])
      )
  }, [siteStore.id])

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "surface_area", header: t("Surface area (m²)"), sortable: true },
    { field: "height", header: t("Height (m)"), sortable: true },
    { field: "is_optimized", header: t("Optimized"), sortable: true, processValue: value => `${value ? t("Yes") : t("No")}` },
    { field: "is_permeable", header: "Permeable", sortable: true, processValue: value => `${value ? t("Yes") : t("No")}` },
    { field: "is_cooled", header: t("Cooled"), sortable: true, processValue: value => `${value ? t("Yes") : t("No")}` },
    { field: "is_heated", header: t("Heated"), sortable: true, processValue: value => `${value ? t("Yes") : t("No")}` }
  ]

  const getDetailUrl = objectId => {
    return `/sites/${siteStore.id}/assets/building/spaces/${objectId}/`
  }

  const getDeleteUrl = objectId => {
    return `/sites/${siteStore.id}/assets/building/spaces/${objectId}/delete/`
  }

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const getExport = async function () {
    await exportSpaces(siteId, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `spaces_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getTemplate = async function () {
    await exportSpaces(0, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `template_spaces_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const postImport = async function (target) {
    const file = target.files[0]
    if (file) {
      await importSpaces(siteId, file, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Import", response.data.message)]))
          retrieve()
        })
        .catch(error => {
          if (error.response && error.response.headers && error.response.headers["content-type"] === "text/csv") {
            fileDownload(error.response.data, `ERROR_spaces_${datetimeStr()}.csv`)
            dispatch(addNotifications([errorNotification("Error import", t("An error occured during the import, see more details in the error report file."))]))
          } else {
            dispatch(addNotifications([errorNotification("Error import", error.message)]))
          }
        })
    }
  }

  const menuEntries = [
    {
      label: t("Import spaces"),
      icon: "fa-solid fa-file-import",
      command: () => hiddenFileInput && hiddenFileInput.current.click()
    },
    {
      label: t("Export spaces"),
      icon: "fa-solid fa-file-export",
      command: getExport
    },
    {
      label: t("Template spaces"),
      icon: "fa-solid fa-file",
      command: getTemplate
    }
  ]

  const menuFilters = { columns, items, filters, setFilters }

  return (
    <>
      <ListActions
        entries={menuEntries}
        filters={menuFilters}
      >
        <input
          className="hidden"
          ref={hiddenFileInput}
          type="file"
          onChange={e => postImport(e.target)}
        />
        <Link to={`/sites/${siteStore.id}/assets/building/spaces/create/`}>
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create space")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Spaces")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
      />
    </>
  )
}
