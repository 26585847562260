import React, { useEffect, useRef, useState } from "react"

import { importPoints, exportPoints, exportTemplate, getAllValues } from "../api"

import { ListLayout, ListActions } from "hivolution-front-utils"

import { Button } from "primereact/button"

import { Link, useNavigate } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { updateSideBar } from "/utils/store/sidebar"

import { addNotifications } from "/utils/store/notification"
import { useTranslation } from "react-i18next"
import { useAuth } from "oidc-react"
import { errorNotification, successNotification } from "/utils/notification"
import { paginatedRequest } from "/utils/api"
import fileDownload from "js-file-download"
import { datetimeStr } from "/utils/datetime"
import { site } from "/utils/store/site"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filterValues, setFilterValues] = useState(null)
  const [filters, setFilters] = useState({})
  const hiddenFileInputModbus = useRef(null)
  const hiddenFileInputBacnet = useRef(null)
  const hiddenFileInputSM = useRef(null)
  const hiddenFileInputCarel = useRef(null)
  const hiddenFileInputExternal = useRef(null)

  const retrieve = async (queryparams = "") => {
    await paginatedRequest(`asset/sites/${siteStore.id}/automation_points/${queryparams}`, auth.userData.access_token)()
      .then(results => {
        setItems(results)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getFilterValues = async () => {
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      dispatch(
        updateBreadcrumb([
          { label: t("Home"), url: "/" },
          { label: t("Sites"), url: "/sites/" },
          { label: t("Detail"), url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Communication"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: t("Automation points"), url: `/sites/${siteStore.id}/assets/communication/automation-points/` }
        ])
      )
      getFilterValues()
      retrieve()
    }
  }, [siteStore.id])

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "node_name", header: t("Node"), sortable: true },
    { field: "read_write", header: t("Read / Write") },
    { field: "type", header: t("Type"), sortable: true },
    { field: "equipment", header: t("Equipment"), sortable: true },
    { field: "point_name", header: t("Point"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name") },
    { field: "type", header: t("Type") },
    { field: "node_name", header: t("Node") },
    { field: "equipment_name", header: t("Equipment") },
    { field: "writable", header: t("Writable") }
  ]

  const getDetailUrl = objectId => {
    return `/sites/${siteStore.id}/assets/communication/automation-points/${objectId}/`
  }

  const getDeleteUrl = objectId => {
    return `/sites/${siteStore.id}/assets/communication/automation-points/${objectId}/delete/`
  }

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const getExport = async pointType => {
    await exportPoints(siteStore.id, pointType, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `${pointType}_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getTemplate = async pointType => {
    await exportTemplate(pointType, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `${pointType}_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const postImport = async function (target, pointType) {
    file = target.files[0]
    if (file) {
      await importPoints(siteStore.id, pointType, file, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Import", response.data)]))
          retrieve()
        })
        .catch(error => {
          if (error.response && error.response.headers && error.response.headers["content-type"] === "text/csv") {
            fileDownload(error.response.data, `ERROR_${pointType}_${datetimeStr()}.csv`)
            dispatch(addNotifications([errorNotification("Error import", t("An error occured during the import, see more details in the error report file."))]))
          } else {
            dispatch(addNotifications([errorNotification("Error import", error.message)]))
          }
        })
    }
  }

  const menuEntries = () => [
    {
      label: t("Import"),
      icon: "fa-solid fa-file-import",
      items: [
        { label: "Modbus", command: () => hiddenFileInputModbus && hiddenFileInputModbus.current.click() },
        { label: "Bacnet", command: () => hiddenFileInputBacnet && hiddenFileInputBacnet.current.click() },
        { label: "SM", command: () => hiddenFileInputSM && hiddenFileInputSM.current.click() },
        { label: "Carel", command: () => hiddenFileInputCarel && hiddenFileInputCarel.current.click() },
        { label: "OPCUA", command: () => hiddenFileInputOPCUA && hiddenFileInputOPCUA.current.click() },
        { label: "External API", command: () => hiddenFileInputExternal && hiddenFileInputExternal.current.click() }
      ]
    },
    {
      label: t("Export"),
      icon: "fa-solid fa-file-export",
      items: [
        { label: "Modbus", command: () => getExport("modbus") },
        { label: "Bacnet", command: () => getExport("bacnet") },
        { label: "SM", command: () => getExport("smn") },
        { label: "Carel", command: () => getExport("carel") },
        { label: "OPCUA", command: () => getExport("opcua") },
        { label: "External API", command: () => getExport("externalapi") }
      ]
    },
    {
      label: t("Template"),
      icon: "fa-solid fa-file",
      items: [
        { label: "Modbus", command: () => getTemplate("modbus") },
        { label: "Bacnet", command: () => getTemplate("bacnet") },
        { label: "SM", command: () => getTemplate("sm") },
        { label: "Carel", command: () => getTemplate("carel") },
        { label: "OPCUA", command: () => getTemplate("opcua") },
        { label: "External API", command: () => getTemplate("externalapi") }
      ]
    }
  ]

  useEffect(() => {
    setLoading(true)
    if (Object.keys(filters).length > 0) {
      let queryParams = "?"
      Object.keys(filters).forEach(key => {
        Object.values(filters[key]).forEach(value => {
          queryParams = queryParams + `${key}=${value}&`
        })
      })
      retrieve(queryParams)
    } else {
      if (siteStore.id) retrieve()
    }
  }, [filters])

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  return (
    <>
      <ListActions
        entries={menuEntries()}
        filterBackend={true}
        filters={menuFilters}
      >
        <input
          hidden
          type="file"
          ref={hiddenFileInputModbus}
          onChange={e => postImport(e.target, "modbus")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputBacnet}
          onChange={e => postImport(e.target, "bacnet")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputSM}
          onChange={e => postImport(e.target, "sm")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputCarel}
          onChange={e => postImport(e.target, "carel")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputExternal}
          onChange={e => postImport(e.target, "externalapi")}
        />
        <Link to={`/sites/${siteStore.id}/assets/communication/automation-points/create/`}>
          <Button
            rounded
            className="button"
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Automation point")}
        loading={loading}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
      />
    </>
  )
}

/* bulkDeleteUrl={`/sites/${siteStore.id}/assets/communication/automation-points/delete/?${selected.map(item => `id=${item.id}`).join("&")}`} */

/* handleSelection={setSelected} */
