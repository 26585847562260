import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Dropdown } from "hivolution-front-utils"

import { t } from "./commons"

export const ExternalAPINode = spaces => {
  return {
    name: { label: t("Name"), field: InputText, options: { required: true } },
    external_id: { label: t("External ID"), field: InputText, options: { required: true } },
    space: {
      label: t("Space"),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        filter: true
      }
    },
    description: { label: t("Description"), field: InputTextarea, options: {} }
  }
}

export const ExternalAPIOverview = () => {
  return {
    name: { label: t("Name") },
    external_id: { label: t("External ID") },
    space_label: { label: t("Space") },
    description: { label: t("Description") }
  }
}
