import { retrieve } from "/apps/client/zone/site/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSite } from "/utils/store/site"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import AssetRoutes from "../assets/routes"
import ConnectivityRoutes from "../connectivity/routes"
import EnergyRoutes from "../energy/routes"
import OptimizationRoutes from "../optimization/routes"
import Create from "./site/components/Create"
import Delete from "./site/components/Delete"
import Detail from "./site/components/Detail"
import List from "./site/components/List"

const SiteContentContext = createContext()

const SiteContent = () => {
  const auth = useAuth()
  const { siteId } = useParams()
  const [site, setSite] = useState()
  const dispatch = useDispatch()

  const retrieveDetail = async () => {
    await retrieve(siteId, auth.userData.access_token)
      .then(({ data }) => {
        setSite(data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Site", error.message)]))
      })
  }

  useEffect(() => {
    siteId && retrieveDetail()
  }, [siteId])

  useEffect(() => {
    site && dispatch(updateSite(site))
  }, [site])

  return (
    <SiteContentContext.Provider value={{ site, retrieveDetail }}>
      <Outlet />
    </SiteContentContext.Provider>
  )
}

export { SiteContentContext }
export default function ZoneRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":siteId"
        element={<SiteContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
        <Route
          path="assets/*"
          element={<AssetRoutes />}
        />
        <Route
          path="energy/*"
          element={<EnergyRoutes />}
        />
        <Route
          path="connectivity/*"
          element={<ConnectivityRoutes />}
        />
        <Route
          path="optimization/*"
          element={<OptimizationRoutes />}
        />
      </Route>
    </Routes>
  )
}
