import { header } from "/utils/api"
import { REQUEST_DEFAULT_LIMIT } from "/utils/constants/api"
import axios from "axios"

export async function retrieve(siteId, interfaceId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/${interfaceId}/`, header(token))
}

export async function retrieveList(siteId, gatewayId, token, offset) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/?gateway=${gatewayId}&limit=${REQUEST_DEFAULT_LIMIT}&offset=${offset}`, header(token))
}

export async function addInterface(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/`, data, header(token))
}

export async function updateInterface(siteId, interfaceId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/${interfaceId}/`, data, header(token))
}

export async function removeInterface(siteId, interfaceId, token) {
  return await axios.delete(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/${interfaceId}/`, header(token))
}
