import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes"
import { updateInterface } from "/apps/client/connectivity/network-interface/api"
import { NetworkInterface, NetworkInterfaceOverview } from "/apps/client/connectivity/network-interface/models"
import { NetworkInterfaceRoutesContext } from "/apps/client/connectivity/network-interface/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { Loader, OverView } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const auth = useAuth()
  const dispatch = useDispatch()
  const { networkInterface, retrieveDetail } = useContext(NetworkInterfaceRoutesContext)
  const { gateway } = useContext(GatewayRoutesContext)
  const { t } = useTranslation(["client"])
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)

  const updateObject = async e => {
    e.preventDefault()
    await updateInterface(siteStore.id, networkInterface.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The network interface has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    networkInterface && networkInterface.id && setData(networkInterface)
  }, [networkInterface])

  return (
    <>
      {networkInterface ? (
        <>
          <div className="base-content-title">
            <h2>{networkInterface.context_name}</h2>
          </div>
          <OverView
            object={networkInterface}
            model={NetworkInterface()}
            overviewModel={NetworkInterfaceOverview()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            setEdit={setEdit}
            deleteUrl={`/sites/${siteStore.id}/assets/gateways/${gateway.id}/network-interfaces/${networkInterface.id}/delete/`}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
