import * as React from "react"
import { Route, Routes } from "react-router-dom"

import ZoneRoutes from "./zone/routes"
import { useEffect } from "react"
import { updateSideBar } from "/utils/store/sidebar.js"
import { useDispatch, useSelector } from "react-redux"
import { site } from "/utils/store/site.js"

export default function ClientRoutes() {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()

  useEffect(() => {
    siteStore.id ? dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id })) : dispatch(updateSideBar({ menuName: "organizations", siteId: null }))
  }, [siteStore.id])

  return (
    <Routes>
      <Route
        path="/*"
        element={<ZoneRoutes />}
      />
    </Routes>
  )
}
