import { createSlice } from "@reduxjs/toolkit"

export const breadcrumbSlice = createSlice({
  name: "breadcrumb",

  initialState: {
    items: []
  },

  reducers: {
    updateBreadcrumb: (state, action) => {
      state.items = action.payload
    }
  }
})

export const { updateBreadcrumb } = breadcrumbSlice.actions

export const breadcrumb = state => state.breadcrumb.items
