import { MainLogo } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { TieredMenu } from "primereact/tieredmenu"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import menu from "/utils/menu.js"

export default function Header() {
  const auth = useAuth()
  const [menuItems, setMenuItems] = useState([])
  const user = useRef()
  const { t } = useTranslation(["home"])
  const [gateways] = menu("parameters")

  const signout = () => {
    auth.signOutRedirect({ redirectMethod: "replace" })
  }

  const UserInfos = () => (
    <div>
      <span>{auth.userData.profile.given_name}</span>
    </div>
  )

  useEffect(() => {
    auth.userData &&
      setMenuItems([
        {
          className: "user-infos",
          template: UserInfos
        },
        {
          label: t("Sign out"),
          icon: "fa-solid fa-power-off",
          command: () => signout()
        }
      ])
  }, [auth])

  return (
    <header className="main-header">
      <nav
        className="navbar"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="home">
          <Link to="/">
            <i className="fa-solid fa-house" />
          </Link>
        </div>
        <Link to="/">
          <MainLogo />
        </Link>

        <div className="nav">
          {gateways && (
            <div className="parameters">
              <Link to={gateways.url}>
                <i className="fa-solid fa-gear" />
              </Link>
            </div>
          )}
          <div className="user">
            {auth.userData && (
              <>
                <TieredMenu
                  popup
                  model={menuItems}
                  ref={user}
                  className="nav-menu"
                />

                <button onClick={e => user.current.toggle(e)}>
                  <i className="fa-solid fa-circle-user" />
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  )
}
