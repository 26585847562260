import { errorNotification, successNotification } from "/utils/notification"

import { addNotifications } from "/utils/store/notification"

import { OverView, ReturnButton, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { getParameters, getStores, update } from "../api"
import { Point, PointOverview } from "../models"
import { site } from "/utils/store/site"
import { PointRouteContext } from "../routes"
import { updateSideBar } from "/utils/store/sidebar"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { paginatedRequest } from "/utils/api"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])

  const [object, setObject] = useState(null)
  const [data, setData] = useState({ time_series_storage_info: {} })
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const { point, retrieveDetail } = useContext(PointRouteContext)
  const [equipments, setEquipments] = useState([])
  const [parameters, setParameters] = useState([])
  const [stores, setStores] = useState([])
  const [automationPoints, setAutomationPoints] = useState([])

  const updateObject = async function (e) {
    e.preventDefault()

    await update(siteStore.id, equipment.id, data, auth.userData.access_token)
      .then(() => {
        setLoading(true)
        dispatch(addNotifications([successNotification("Update", t("The point has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
          console.error(error.message)
        } else {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const retrieveAutomationPoints = async nodeId => {
      await paginatedRequest(`asset/sites/${siteStore.id}/automation_points/?node=${nodeId}`, auth.userData.access_token)()
        .then(results => {
          setAutomationPoints(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }

    if (data.equipment) {
      const find = equipments.find(equipment => equipment.id === data.equipment)
      if (find && find.node) {
        retrieveAutomationPoints(find.node)
      }
    }
  }, [data.equipment])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (point) {
      setLoading(false)
      setObject({
        ...point,
        ...{
          time_series_storage_info: point.time_series_storage_info
            ? point.time_series_storage_info
            : {
                acceptable_values: [
                  { original_point_value: 0, gateway_point_value: 0, gateway_point_text: "", gateway_point_value_change: false },
                  { original_point_value: 1, gateway_point_value: 1, gateway_point_text: "", gateway_point_value_change: false }
                ]
              },
          read: point.read === "Yes",
          write: point.write === "Yes"
        }
      })
    }
  }, [point])

  useEffect(() => {
    const retrieveParameters = async () => {
      await getParameters(auth.userData.access_token)
        .then(response => {
          if (response.data.results) {
            setParameters(response.data.results)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    retrieveParameters()
  }, [])

  useEffect(() => {
    const retrieveEquipments = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteStore.id}/equipments/`, auth.userData.access_token)()
        .then(results => {
          setEquipments(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getEquipments(siteId, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setEquipments(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }

    if (siteStore.id && object && object.id) {
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: t("Detail"), url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Building"), url: `/sites/${siteStore.id}/assets/building/equipments/` },
          { label: t("Points"), url: `/sites/${siteStore.id}/assets/building/points/` },
          { label: point.name, url: `/sites/${siteStore.id}/assets/building/points/${object.id}/` }
        ])
      )
      if (siteStore.id) {
        retrieveEquipments()
      }
    }
  }, [siteStore.id, object])

  useEffect(() => {
    const retrieveStores = async () => {
      await getStores(siteStore.id, data.time_series_storage_info.store_type, auth.userData.access_token)
        .then(response => {
          if (response.data.results) {
            setStores(response.data.results)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    if (data.time_series_storage_info.store_type) {
      retrieveStores()
    }
  }, [data.time_series_storage_info.store_type, siteStore.id])

  return (
    <>
      {object ? (
        <>
          <ReturnButton
            label={t("Back to point list")}
            onClick={() => navigate(`/sites/${siteStore.id}/assets/building/points/`)}
          />
          <div className="base-content-title">
            <h1 className="title">
              {t("Point")} {object.name}
            </h1>
          </div>
          <OverView
            object={object}
            model={Point(equipments, automationPoints, parameters, stores)}
            overviewModel={PointOverview()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            setEdit={setEdit}
            deleteUrl={`/sites/${siteStore.id}/assets/building/points/${object.id}/delete/`}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
