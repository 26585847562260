import { NetworkRoutesContext } from "/apps/client/assets/communication/network/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getCount, remove } from "../api"

export default function Delete() {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["client"])
  const [objectCount, setObjectCount] = useState(null)
  const { network } = useContext(NetworkRoutesContext)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const count = async () => {
    await getCount(network.id, auth.userData.access_token)
      .then(response => {
        setObjectCount(response.data)
      })
      .catch(error => {
        displayNotification(errorNotification("Networks", error.message))
      })
      .finally(() => setLoading(false))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(siteStore.id, network.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Networks", t("The network has been removed."))])
        navigate(`/sites/${siteStore.id}/assets/communication/networks/`)
      })
      .catch(error => {
        displayNotification([errorNotification("Networks", error.message)])
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (network && network.id && siteStore.id) {
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Communication"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: t("Networks"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: network.label ?? network.name, url: `/sites/${siteStore.id}/assets/communication/networks/${network.id}/` },
          { label: t("Delete"), url: `/sites/${siteStore.id}/assets/communication/networks/${network.id}/delete/` }
        ])
      )

      count()
    }
  }, [siteStore.id, network])

  return (
    <>
      {network && !loading ? (
        <DeleteConfirmation
          objectType={t("Network")}
          objectNames={[network.label ?? network.name]}
          remove={removeObject}
          processing={processing}
          relatedObjects={objectCount}
          goBack={() => navigate(-1)}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}
