import { InputText } from "primereact/inputtext"
import { Dropdown } from "hivolution-front-utils"

import { commonsFields, commonsOverview, t } from "./commons"

export const OPCUA = nodes => {
  return {
    ...commonsFields(nodes),
    ...{
      nodeid: {
        label: t("Node ID"),
        field: InputText,
        options: {}
      },
      access_level: {
        label: t("Access level"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "1", label: t("Read") },
            { value: "2", label: t("Write") },
            { value: "3", label: t("Read/Write") }
          ]
        }
      },
      data_type: {
        label: t("Data type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "Boolean", label: t("Boolean") },
            { value: "Int16", label: t("Int16") },
            { value: "UInt16", label: t("UInt16") },
            { value: "Int32", label: t("Int32") },
            { value: "UInt32", label: t("UInt32") },
            { value: "Int64", label: t("Int64") },
            { value: "UInt64", label: t("UInt64") },
            { value: "Float", label: t("Float") },
            { value: "Double", label: t("Double") },
            { value: "String", label: t("String") }
          ]
        }
      }
    }
  }
}

export const OPCUAOverview = nodes => {
  return {
    ...commonsOverview(nodes),
    ...{
      node_id: { label: t("Node ID") },
      access_level: { label: t("Access level") },
      data_type: { label: t("Data type") }
    }
  }
}
