export function successNotification(summary, message) {
  return {
    severity: "success",
    summary: summary,
    detail: message
  }
}

export function errorNotification(summary, message) {
  return {
    severity: "error",
    summary: summary,
    detail: message
  }
}

export function warningNotification(summary, message) {
  return {
    severity: "warn",
    summary: summary,
    detail: message
  }
}

export function infoNotification(summary, message) {
  return {
    severity: "info",
    summary: summary,
    detail: message
  }
}
