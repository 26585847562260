import { configureStore } from "@reduxjs/toolkit"
import { breadcrumbSlice } from "./breadcrumb"

import { notificationsSlice } from "./notification"
import { sidebarSlice } from "./sidebar"
import { siteSlice } from "./site"
import { organizationSlice } from "./organization"

export default configureStore({
  reducer: {
    notifications: notificationsSlice.reducer,
    breadcrumb: breadcrumbSlice.reducer,
    sidebar: sidebarSlice.reducer,
    site: siteSlice.reducer,
    organization: organizationSlice.reducer
  }
})
