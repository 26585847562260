import React, { useState } from "react"
import { Route, Routes, useLocation, useParams } from "react-router-dom"
import { TabsLayout } from "hivolution-front-utils"
import List from "./components/List"
import Create from "./components/Create"
import { useTranslation } from "react-i18next"
import LoadCurtailParametersRoutes from "./load-curtail-parameters/routes"
import ProposedCurtailSchedulesRoutes from "./proposed-load-curtail-schedule/routes"
import ConfirmedCurtailSchedulesRoutes from "./confirmed-load-curtail-schedule/routes"
import MarketPlayerInteractionRoutes from "./market-player-interaction/routes"
import FlexOptiRoutes from "./flex-opti/routes"

function FlexLayout() {
  const { siteId, flexId } = useParams()
  const location = useLocation()
  const { t } = useTranslation(["client"])
  const [tabs, setTabs] = useState([
    { name: t("Curtail parameters"), active: location.pathname.includes("load-curtail-parameters") ? true : false, url: `/sites/${siteId}/optimization/flexibility/${flexId}/load-curtail-parameters/` },
    { name: t("Flex opti"), active: location.pathname.includes("flex-opti") ? true : false, url: `/sites/${siteId}/optimization/flexibility/${flexId}/flex-opti/` },
    { name: t("Proposed curtail schedules"), active: location.pathname.includes("proposed-curtail-schedules") ? true : false, url: `/sites/${siteId}/optimization/flexibility/${flexId}/proposed-curtail-schedules/` },
    { name: t("Confirmed curtail schedules"), active: location.pathname.includes("confirmed-curtail-schedules") ? true : false, url: `/sites/${siteId}/optimization/flexibility/${flexId}/confirmed-curtail-schedules/` },
    { name: t("Market player interaction"), active: location.pathname.includes("market-player-interaction") ? true : false, url: `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/` }
  ])

  return (
    <TabsLayout
      title={t("Flexibility")}
      tabs={tabs}
      setTabs={setTabs}
    />
  )
}

export default function FlexibilityRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="/create/"
        element={<Create />}
      />
      <Route
        path="/:flexId/"
        element={<FlexLayout />}
      >
        <Route
          index
          path="load-curtail-parameters/*"
          element={<LoadCurtailParametersRoutes />}
        />
        <Route
          index
          path="flex-opti/*"
          element={<FlexOptiRoutes />}
        />
        <Route
          index
          path="proposed-curtail-schedules/*"
          element={<ProposedCurtailSchedulesRoutes />}
        />
        <Route
          index
          path="confirmed-curtail-schedules/*"
          element={<ConfirmedCurtailSchedulesRoutes />}
        />
        <Route
          index
          path="market-player-interaction/*"
          element={<MarketPlayerInteractionRoutes />}
        />
      </Route>
    </Routes>
  )
}
