import { retrieveList } from "/apps/client/connectivity/connector-instance/api"
import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes.js"
import { REQUEST_DEFAULT_LIMIT } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [items, setItems] = useState([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [next, setNext] = useState(false)
  const [loadingNext, setLoadingNext] = useState(true)
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [selected, setSelected] = useState([])
  const { gateway } = useContext(GatewayRoutesContext)

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    // { field: "protocols", header: t("Protocols") },
    // { field: "description", header: t("Description") },
    // { field: "stores_response_timeout", header: "Response timeout" },
    { field: "number_stores", header: t("Number of stores") },
    { field: "number_points", header: t("Number of points") }
  ]

  const retrieve = async () => {
    setLoadingNext(true)
    await retrieveList(siteStore.id, gateway.id, auth.userData.access_token, offset)
      .then(({ data }) => {
        setCount(data.count)
        setNext(data.next)
        setOffset(offset + REQUEST_DEFAULT_LIMIT)
        setItems([...items, ...data.results])
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Connector instances", error.message)]))
      })
      .finally(() => {
        setLoading(false)
        setLoadingNext(false)
      })
  }

  const getDetailUrl = connectorId => {
    return `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connectorId}/`
  }

  const getDeleteUrl = connectorId => {
    return `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connectorId}/delete/`
  }

  const onRowClick = connectorId => {
    navigate(getDetailUrl(connectorId))
  }

  const menuFilters = { items, columns, filters, setFilters }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      gateway &&
      gateway.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Connectivity"), url: `/sites/${siteStore.id}/connectivity/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` },
          { label: t("Connector instances"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/` }
        ])
      )

    siteStore.id && gateway && retrieve()
  }, [siteStore.id, gateway])

  return (
    <>
      <section className="list-section">
        <ListActions filters={menuFilters}>
          <Link to={`/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/create/`}>
            <Button
              rounded
              severity="info"
              icon="fa-regular fa-plus"
              label={t("Create")}
            />
          </Link>
        </ListActions>

        <ListLayout
          columns={columns}
          value={items}
          objectName={t("Connector instances")}
          loading={loading}
          filters={filters}
          getDetailUrl={getDetailUrl}
          getDeleteUrl={getDeleteUrl}
          onRowClick={onRowClick}
          count={count}
          next={next}
          loadMore={retrieve}
          loadingNext={loadingNext}
          bulkDeleteUrl={`/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
          handleSelection={setSelected}
        />
      </section>
    </>
  )
}
