import { GatewayRoutesContext } from "../../gateway/routes"
import { getConnectorInstancesCount, removeConnectorInstances } from "../api"
import { errorNotification, successNotification } from "/utils/notification.js"
import { updateBreadcrumb } from "/utils/store/breadcrumb.js"
import { addNotifications } from "/utils/store/notification.js"
import { updateSideBar } from "/utils/store/sidebar.js"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

const DeleteBulk = () => {
  const auth = useAuth()
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["client"])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [objectCount, setObjectCount] = useState()
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const ids = searchParams.getAll("id")
  const { gateway } = useContext(GatewayRoutesContext)

  const count = async () => {
    await getConnectorInstancesCount(ids, auth.userData.access_token)
      .then(({ data, status, statusText }) => {
        if (status === 200) {
          setObjectCount(data)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const bulkDelete = async () => {
    setProcessing(true)
    await removeConnectorInstances(siteStore.id, ids, auth.userData.access_token)
      .then(({ status, statusText }) => {
        if (status === 200) {
          dispatch(addNotifications([successNotification("Delete", t("Connector instances has been removed."))]))
          navigate(`/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/`)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessing(false))
  }

  useLayoutEffect(() => {
    count()
  }, [])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Connectivity"), url: `/sites/${siteStore.id}/connectivity/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` },
          { label: t("Connector instances"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances` }
        ])
      )
  }, [siteStore.id, gateway])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        objectCount &&
        objectCount.label &&
        !!objectCount.label.length && (
          <DeleteConfirmation
            objectType={t("Connector instances")}
            objectNames={objectCount.label}
            relatedObjects={objectCount}
            remove={bulkDelete}
            processing={processing}
            goBack={() => navigate(-1)}
          />
        )
      )}
    </>
  )
}

export default DeleteBulk
