import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, Loader, OverView } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { retrieve, update } from "../api"
import { GatewayBox } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["connectivity"])
  const [object, setObject] = useState(null)
  const { id } = useParams()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)

  const retrieveDetail = async () => {
    await retrieve(id, auth.userData.access_token)
      .then(response => {
        setObject(response.data)
        setLoading(false)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const updateObject = async function (e) {
    e.preventDefault()
    await update(id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The gateway box has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    retrieveDetail()
  }, [id])

  useEffect(() => {
    object &&
      object.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Gateways"), url: "/connectivity/gateways/boxes/" },
          { label: t("Boxes"), url: "/connectivity/gateways/boxes/" },
          { label: object.label, url: `/connectivity/gateways/boxes/${object.id}/` }
        ])
      )
  }, [object])

  return (
    <>
      {object ? (
        <>
          <BaseLayout title={object.label}>
            <OverView
              object={object}
              model={GatewayBox()}
              updateObject={updateObject}
              data={data}
              setData={setData}
              errors={errors}
              edit={edit}
              setEdit={setEdit}
              loading={loading}
              deleteUrl={`/connectivity/gateways/boxes/${id}/delete/`}
            />
          </BaseLayout>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
