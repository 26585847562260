import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"

import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"

import { FormContent, ListActions, ListLayout, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { Link, useNavigate, useParams } from "react-router-dom"

import { add, retrieve } from "../api"
import { FlexOpti } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const { siteId, flexId } = useParams()

  const [data, setData] = useState(null)
  const [flexOptiHistory, setFlexOptiHistory] = useState([])
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))

    dispatch(
      updateBreadcrumb([
        { label: t("Sites"), url: "/sites/" },
        { label: t("Detail"), url: `/sites/${siteId}/` },
        { label: t("Optimization"), url: `/sites/${siteId}/optimization/` },
        { label: t("Flexibility"), url: `/sites/${siteId}/optimization/flexibility/` },
        { label: t("Flex opti"), url: `/sites/${siteId}/optimization/flexibility/${flexId}/flex-opti/` }
      ])
    )

    const retrieveDetail = async () => {
      await retrieve(flexId, auth.userData.access_token)
        .then(response => {
          if (response.data.length > 0) {
            setData(response.data[0])
            setFlexOptiHistory(response.data)
          }
          setLoading(false)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
          setLoading(false)
        })
    }
    retrieveDetail()
  }, [flexId])

  const updateObject = async function (e) {
    e.preventDefault()
    const formData = {
      site_id: siteId,
      contract_id: flexId,
      comment: data.comment,
      active: data.active
    }
    await add(formData, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The flex opti has been updated."))]))
        setLoading(true)
        retrieveDetail()
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  return (
    <>
      {!loading ? (
        data ? (
          <>
            <form
              className="form-view"
              onSubmit={updateObject}
            >
              <FormContent
                model={FlexOpti()}
                data={data}
                setData={setData}
                errors={errors}
              />
              <div className="action-buttons-wrapper">
                <Button
                  rounded
                  severity="success"
                  label={t("Save")}
                  type="submit"
                  icon="fa-solid fa-floppy-disk"
                />
              </div>
            </form>

            <section className="list-section">
              <div className="list-section-head">
                <h3>{t("History")}</h3>
              </div>

              <ListLayout
                columns={[
                  { field: "active", header: t("Active"), processValue: value => (value ? t("Yes") : t("No")), sortable: true },
                  { field: "comment", header: t("Comment") },
                  {
                    field: "update_time",
                    header: t("Updated time"),
                    processValue: value => {
                      return new Date(value).toLocaleString()
                    }
                  },
                  { field: "update_by", header: t("Updated by"), sortable: true }
                ]}
                value={flexOptiHistory}
                actionsBodyTemplate={rowData => (
                  <Button
                    rounded
                    size="small"
                    severity="danger"
                    icon="fa-regular fa-trash-can"
                    onClick={() => navigate(`/sites/${siteId}/optimization/flexibility/${flexId}/flex-opti/${rowData.id}/delete/`)}
                  />
                )}
              />
            </section>
          </>
        ) : (
          <section className="list-section">
            <ListActions>
              <Link to={`/sites/${siteId}/optimization/flexibility/${flexId}/flex-opti/create/`}>
                <Button
                  rounded
                  severity="info"
                  icon="fa-regular fa-plus"
                  label={t("Create flex opti")}
                />
              </Link>
            </ListActions>
          </section>
        )
      ) : (
        <Loader />
      )}
    </>
  )
}
