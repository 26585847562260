import { paginatedRequest } from "/utils/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, ListActions, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["optimization"])

  const [edgeBlockTemplates, setEdgeBlockTemplates] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    dispatch(updateBreadcrumb([{ label: t("Edge block templates"), url: "/optimization/edge-block-templates/" }]))

    const retrieve = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest("optimization/template_edge_blocks/", auth.userData.access_token)()
        .then(results => {
          setEdgeBlockTemplates(results)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await retrieveList(auth.userData.access_token).then(response => {
        if (response.data) {
          setEdgeBlockTemplates(response.data.results)
        }
        setLoading(false)
      }).catch((error) => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    retrieve()
  }, [])

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "priority", header: t("Priority"), sortable: true },
    { field: "updated_by", header: t("Updated by"), sortable: true },
    { field: "description", header: t("Description") }
  ]

  const getDetailUrl = id => {
    return `/optimization/edge-block-templates/${id}/`
  }

  const getDeleteUrl = id => {
    return `/optimization/edge-block-templates/${id}/delete/`
  }

  const onRowClick = id => {
    navigate(getDetailUrl(id))
  }

  const menuFilters = { columns, items: edgeBlockTemplates, filters, setFilters }

  return (
    <BaseLayout title={t("Edge block templates")}>
      <ListActions filters={menuFilters}>
        <Link to="/optimization/edge-block-templates/create/">
          <Button
            rounded
            className="button"
            icon="fa-regular fa-plus"
            label={t("Create edge block template")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={edgeBlockTemplates}
        objectName={t("Edge block templates")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
      />
    </BaseLayout>
  )
}
