import { paginatedRequest } from "/utils/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"

import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"

import { ListActions, ListLayout } from "hivolution-front-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"

import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import { Link, useNavigate } from "react-router-dom"

import { exportNodes, exportTemplate, getAllValues, importNodes } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filterValues, setFilterValues] = useState(null)
  const [filters, setFilters] = useState({})
  const [selected, setSelected] = useState([])
  const hiddenFileInputModbus = useRef(null)
  const hiddenFileInputBacnet = useRef(null)
  const hiddenFileInputSM = useRef(null)
  const hiddenFileInputCarel = useRef(null)
  const hiddenFileInputExternal = useRef(null)

  const retrieve = async (queryparams = "") => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`asset/sites/${siteStore.id}/nodes/${queryparams}`, auth.userData.access_token)()
      .then(results => {
        setItems(results)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /* await retrieveList(siteStore.id, auth.userData.access_token).then(response => {
      if (response.data) {
        setItems(response.data.results)
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const getFilterValues = async () => {
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))

      dispatch(
        updateBreadcrumb([
          { label: t("Home"), url: "/" },
          { label: t("Sites"), url: "/sites/" },
          { label: t("Detail"), url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Communication"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: t("Automation nodes"), url: `/sites/${siteStore.id}/assets/communication/automation-nodes/` }
        ])
      )
      getFilterValues()
      retrieve()
    }
  }, [siteStore.id])

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "model", header: t("Model"), sortable: true },
    { field: "manufacturer", header: t("Manufacturer"), sortable: true },
    { field: "description", header: t("Description") },
    { field: "type", header: t("Protocol"), sortable: true },
    { field: "slave_id", header: t("Slave ID"), sortable: true },
    { field: "ip_addresses", header: t("IP addresses"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name") },
    { field: "model", header: t("Model") },
    { field: "manufacturer", header: t("Manufacturer") },
    { field: "protocol", header: t("Protocol") },
    { field: "slave_id", header: t("Slave ID") },
    { field: "space_name", header: t("Space") },
    { field: "ip_addresses", header: t("IP address") }
  ]

  const getDetailUrl = objectId => {
    return `/sites/${siteStore.id}/assets/communication/automation-nodes/${objectId}/`
  }

  const getDeleteUrl = objectId => {
    return `/sites/${siteStore.id}/assets/communication/automation-nodes/${objectId}/delete/`
  }

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const getExport = async nodeType => {
    await exportNodes(siteStore.id, nodeType, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `${nodeType}_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getTemplate = async nodeType => {
    await exportTemplate(nodeType, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `${nodeType}_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const postImport = async function (target, nodeType) {
    const file = target.files[0]
    if (file) {
      await importNodes(siteStore.id, nodeType, file, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Import", response.data)]))
          retrieve()
        })
        .catch(error => {
          if (error.response && error.response.headers && error.response.headers["content-type"] === "text/csv") {
            fileDownload(error.response.data, `ERROR_${nodeType}_${datetimeStr()}.csv`)
            dispatch(addNotifications([errorNotification("Error import", t("An error occured during the import, see more details in the error report file."))]))
          } else {
            dispatch(addNotifications([errorNotification("Error import", error.message)]))
          }
        })
    }
  }

  const menuEntries = () => [
    {
      label: t("Import"),
      icon: "fa-solid fa-file-import",
      items: [
        { label: "Modbus", command: () => hiddenFileInputModbus && hiddenFileInputModbus.current.click() },
        { label: "Bacnet", command: () => hiddenFileInputBacnet && hiddenFileInputBacnet.current.click() },
        { label: "SM", command: () => hiddenFileInputSM && hiddenFileInputSM.current.click() },
        { label: "Carel", command: () => hiddenFileInputCarel && hiddenFileInputCarel.current.click() },
        { label: "External API", command: () => hiddenFileInputExternal && hiddenFileInputExternal.current.click() }
      ]
    },
    {
      label: t("Export"),
      icon: "fa-solid fa-file-export",
      items: [
        { label: "Modbus", command: () => getExport("modbusnode") },
        { label: "Bacnet", command: () => getExport("bacnetnode") },
        { label: "SM", command: () => getExport("smnode") },
        { label: "Carel", command: () => getExport("carelnode") },
        { label: "External API", command: () => getExport("externalapinode") }
      ]
    },
    {
      label: t("Template"),
      icon: "fa-solid fa-file",
      items: [
        { label: "Modbus", command: () => getTemplate("modbusnode") },
        { label: "Bacnet", command: () => getTemplate("bacnetnode") },
        { label: "SM", command: () => getTemplate("smnode") },
        { label: "Carel", command: () => getTemplate("carelnode") },
        { label: "External API", command: () => getTemplate("externalapinode") }
      ]
    }
  ]

  useEffect(() => {
    setLoading(true)
    if (Object.keys(filters).length > 0) {
      let queryParams = "?"
      Object.keys(filters).forEach(key => {
        Object.values(filters[key]).forEach(value => {
          queryParams = queryParams + `${key}=${value}&`
        })
      })
      retrieve(queryParams)
    } else {
      if (siteStore.id) retrieve()
    }
  }, [filters])

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  return (
    <>
      <ListActions
        entries={menuEntries()}
        filterBackend={true}
        filters={menuFilters}
      >
        <input
          hidden
          type="file"
          ref={hiddenFileInputModbus}
          onChange={e => postImport(e.target, "modbusnode")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputBacnet}
          onChange={e => postImport(e.target, "bacnetnode")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputSM}
          onChange={e => postImport(e.target, "smnode")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputCarel}
          onChange={e => postImport(e.target, "carelnode")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputExternal}
          onChange={e => postImport(e.target, "externalapinode")}
        />
        <Link to={`/sites/${siteStore.id}/assets/communication/automation-nodes/create/`}>
          <Button
            rounded
            className="button"
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Automation node")}
        loading={loading}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        bulkDeleteUrl={`/sites/${siteStore.id}/assets/communication/automation-nodes/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        handleSelection={setSelected}
      />
    </>
  )
}
