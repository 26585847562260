import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { SelectModal } from "hivolution-front-utils"
import { commonsFields, commonsOverview, t } from "./commons"

export const SMControllerNode = (spaces, smNodes) => {
  return {
    ...commonsFields(spaces),
    ...{
      sm_node: {
        label: t("System manager"),
        field: SelectModal,
        options: {
          items: smNodes,
          columns: [
            { field: "name", header: t("Name") },
            { field: "model", header: t("Model") },
            { field: "manufacturer", header: t("Manufacturer") },
            { field: "proto", header: t("Protocol") },
            { field: "sm_type", header: t("SM Type") },
            { field: "software", header: t("Software") },
            { field: "user", header: t("User") }
          ],
          required: true,
          objectName: t("SM Nodes"),
          searchLabel: id => {
            let find = smNodes.find(node => node.id === id)
            return find ? find.name : ""
          }
        }
      },
      node_addr: {
        label: t("Node address"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      },
      device_id: {
        label: t("Device ID"),
        field: InputText,
        options: {
          required: true
        }
      },
      device_type: {
        label: t("Device type"),
        field: InputText,
        options: {
          required: true
        }
      },
      suction_id: {
        label: t("Suction ID"),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      num_suction: {
        label: t("Num suction"),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      multicasename: {
        label: t("Multi-case name"),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      rack_id: {
        label: t("Rack ID"),
        field: InputNumber,
        options: {
          showButtons: true
        }
      }
    }
  }
}

export const SMControllerOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      sm_node_label: { label: t("System manager") },
      node_addr: { label: t("Node address") },
      device_id: { label: t("Device ID") },
      device_type: { label: t("Device type") },
      suction_id: { label: t("Suction ID") },
      num_suction: { label: t("Num suction") },
      multicasename: { label: t("Multi-case name") },
      rack_id: { label: t("Rack ID") }
    }
  }
}
