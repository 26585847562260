import React, { createContext, useEffect, useState } from "react"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import { useDispatch } from "react-redux"
import { useAuth } from "oidc-react"
import { addNotifications } from "/utils/store/notification"
import { errorNotification } from "/utils/notification"
import { listSites, retrieve } from "./api"
import { updateOrganization } from "/utils/store/organization"
import SitesMap from "./components/SitesMap"
import { updateSideBar } from "/utils/store/sidebar.js"

const OrganizationRoutesContext = createContext(null)

const OrganizationContent = () => {
  const auth = useAuth()
  const { organizationId } = useParams()
  const dispatch = useDispatch()
  const [organization, setOrganization] = useState()
  const [sites, setSites] = useState([])

  const retrieveDetail = async () => {
    await retrieve(organizationId, auth.userData.access_token)
      .then(response => {
        setOrganization(response.data)
        dispatch(updateOrganization(response.data))
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const retrieveSites = async () => {
    await listSites(organizationId, auth.userData.access_token)
      .then(response => {
        setSites(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    if (organizationId) {
      retrieveDetail()
      retrieveSites()
    }
  }, [organizationId])

  useEffect(() => {
    organization && organization.id && dispatch(updateSideBar({ menuName: "organizations", siteId: null }))
  }, [organization])

  return (
    <OrganizationRoutesContext.Provider value={{ sites, retrieveDetail }}>
      <Outlet />
    </OrganizationRoutesContext.Provider>
  )
}

export { OrganizationRoutesContext }

export default function OrganizationRoutes() {
  return (
    <Routes>
      <Route
        path="create/"
        element={<Create />}
      />
      <Route
        path=":organizationId/"
        element={<OrganizationContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="map/"
          element={<SitesMap />}
        />
        <Route
          path="delete/"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
