import { retrieve } from "/apps/client/energy/energy-delivery-point/api.js"
import { errorNotification } from "/utils/notification.js"
import { addNotifications } from "/utils/store/notification.js"
import { site } from "/utils/store/site"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"

import List from "./components/List"

const EnergyDeliveryPointRoutesContext = createContext(null)

const EnergyDeliveryPointContent = () => {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { energyDeliveryPointId } = useParams()
  const [energyDeliveryPoint, setEnergyDeliveryPoint] = useState()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, energyDeliveryPointId, auth.userData.access_token)
      .then(response => {
        setEnergyDeliveryPoint(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && energyDeliveryPointId && retrieveDetail()
  }, [siteStore.id, energyDeliveryPointId])

  return (
    <EnergyDeliveryPointRoutesContext.Provider value={{ energyDeliveryPoint, retrieveDetail }}>
      <Outlet />
    </EnergyDeliveryPointRoutesContext.Provider>
  )
}

export { EnergyDeliveryPointRoutesContext }
export default function EnergyDeliveryPointRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":energyDeliveryPointId"
        element={<EnergyDeliveryPointContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
