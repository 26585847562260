import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"

import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"

import { FormContent } from "hivolution-front-utils"
import { useAuth } from "oidc-react"

import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import { useNavigate } from "react-router-dom"

import { add, getSpaces } from "../api"
import { Network } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [data, setData] = useState({ site: siteStore.id })
  const [errors, setErrors] = useState({})
  const [spaces, setSpaces] = useState([])
  const [processing, setProcessing] = useState(false)

  const getSP = async () => {
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(response => {
        setSpaces(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)

    const dataToSend = {
      ...data,
      ...{ bacnet_network_number: data.is_bacnet ? data.bacnet_network_number : null }
    }

    await add(siteStore.id, dataToSend, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Network", t("The network has been created."))]))
        navigate(`/sites/${siteStore.id}/assets/communication/networks/${response.data.id}/`)
      })
      .catch(error => {
        error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Communication"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: t("Networks"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: t("Create"), url: `/sites/${siteStore.id}/assets/communication/networks/create/` }
        ])
      )

      getSP()
    }
  }, [siteStore.id])

  return (
    <>
      {data && (
        <>
          <div className="base-content-title">
            <h2>{t("Create network")}</h2>
          </div>
          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={Network(spaces)}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <Button
                rounded
                severity="success"
                label={t("Save")}
                type="submit"
                icon="fa-solid fa-floppy-disk"
                disabled={processing}
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}
