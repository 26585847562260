import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

function headerFile(token) {
  return {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/`, header(token))
}

export async function retrieve(siteId, nodeId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/${nodeId}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/`, data, header(token))
}

export async function update(siteId, nodeId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/${nodeId}/`, data, header(token))
}

export async function remove(siteId, nodeId, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/${nodeId}/`, header(token))
}

export async function removeNodes(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/deletion/`, ids, header(token))
}

export async function getCount(nodeId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/nodes/${nodeId}/count`, header(token))
}

export async function getSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, header(token))
}

export async function getSMNodes(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/?type=smnode`, header(token))
}

export async function getCarelNodes(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/?type=carelnode`, header(token))
}

export async function getNodesCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/nodes/count/`, ids, header(token))
}

export async function getInterfaces(siteId, nodeId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/node_interfaces/?node=${nodeId}`, header(token))
}

export async function addInterfaces(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/node_interfaces/`, data, header(token))
}

export async function updateInterfaces(siteId, interfaceId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/node_interfaces/${interfaceId}/`, data, header(token))
}

export async function removeInterfaces(siteId, interfaceId, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/node_interfaces/${interfaceId}/`, header(token))
}

export async function getNetworks(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/`, header(token))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/allvalues`, header(token))
}

export async function exportNodes(siteId, nodeType, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/${nodeType}/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function exportTemplate(nodeType, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/0/${nodeType}/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importNodes(siteId, nodeType, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/${nodeType}/bulk/import`, formData, headerFile(token), { signal: new AbortController() })
}
