import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

function headerFile(token) {
  return {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, header(token))
}

export async function retrieve(siteId, id, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/${id}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, data, header(token))
}

export async function update(siteId, id, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/${id}/`, data, header(token))
}

export async function remove(siteId, id, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/${id}/`, header(token))
}

export async function getCount(id, token) {
  return await axios.get(`${window.BACKEND_URL}/DETAIL_URL/${id}/count`, header(token))
}

export async function exportSpaces(siteId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/spaces/bulk/`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importSpaces(siteId, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/bulk/`, formData, headerFile(token), { signal: new AbortController() })
}
