import axios from "axios"
import { REQUEST_DEFAULT_LIMIT } from "/utils/constants/api.js"
import { header } from "/utils/api.js"

export async function retrieveList(siteId, token, offset) {
  return await axios.get(`${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/?limit=${REQUEST_DEFAULT_LIMIT}&offset=${offset}`, header(token))
}

export async function retrieve(siteId, id, token) {
  return await axios.get(`${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/${id}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/`, data, header(token))
}

export async function update(siteId, id, data, token) {
  return await axios.put(`${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/${id}/`, data, header(token))
}

export async function remove(siteId, id, token) {
  return await axios.delete(`${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/${id}/`, header(token))
}

export async function getDistributors(token) {
  return await axios.get(`${window.BACKEND_URL}/energy/energy_companies/?company_type=Distributor`, header(token))
}

export async function getTransmissionCompanies(token) {
  return await axios.get(`${window.BACKEND_URL}/energy/energy_companies/?company_type=Transmission`, header(token))
}

export async function getPoints(siteId, token, offset) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/points/?limit=${REQUEST_DEFAULT_LIMIT}&offset=${offset}`, header(token))
}
