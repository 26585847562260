import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getCount, remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["connectivity"])
  const { id } = useParams()
  const [relatedObjects, setRelatedObjects] = useState(null)
  const [gatewaysCount, setGatewaysCount] = useState(0)

  const displayNotification = notification => {
    dispatch(addNotifications(notification))
  }

  const count = async () => {
    await getCount(id, auth.userData.access_token)
      .then(response => {
        setRelatedObjects(response.data)
        setGatewaysCount(response.data.count.gateways_count)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  const removeObject = async () => {
    await remove(id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The gateway box has been removed."))])
        navigate("/connectivity/gateways/boxes/")
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { label: t("Gateways"), url: "/connectivity/gateways/boxes/" },
        { label: t("Boxes"), url: "/connectivity/gateways/boxes/" },
        { label: t("Detail"), url: `/connectivity/gateways/boxes/${id}/` },
        { label: t("Delete"), url: `/connectivity/gateways/boxes/${id}/delete/` }
      ])
    )

    count()
  }, [])

  return (
    <>
      {relatedObjects && (
        <DeleteConfirmation
          objectType={t("Gateway box")}
          remove={removeObject}
          relatedObjects={relatedObjects}
          goBack={() => navigate(-1)}
          forbidden={gatewaysCount > 0}
          message={t("Delete gateway box link to a site is not allowed.")}
        />
      )}
    </>
  )
}
