import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

function headerFile(token) {
  return {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/`, header(token))
}

export async function retrieve(siteId, pointId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/${pointId}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/`, data, header(token))
}

export async function update(siteId, pointId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/${pointId}/`, data, header(token))
}

export async function remove(siteId, pointId, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/${pointId}/`, header(token))
}

export async function removePoints(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/deletion/`, ids, header(token))
}

export async function getCount(pointId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/automation_points/${pointId}/count`, header(token))
}

export async function getSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, header(token))
}

export async function getSMPoints(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/?type=sm`, header(token))
}

export async function getCarelPoints(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/?type=carel`, header(token))
}

export async function getPointsCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/automation_points/count/`, ids, header(token))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/allvalues`, header(token))
}

export async function exportPoints(siteId, pointType, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/automation-points/${pointType}node/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function exportTemplate(pointType, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/0/automation-points/${pointType}node/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importPoints(siteId, pointType, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/automation-points/${pointType}node/bulk/import`, formData, headerFile(token), { signal: new AbortController() })
}
