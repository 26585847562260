import { ConnectorInstanceRoutesContext } from "/apps/client/connectivity/connector-instance/routes.js"
import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes.js"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { retrieve, update } from "../api"
import { ConnectorInstance } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const { gateway } = useContext(GatewayRoutesContext)
  const { connector } = useContext(ConnectorInstanceRoutesContext)

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, connector.id, auth.userData.access_token)
      .then(response => {
        setObject(response.data)
        setData(response.data)
        setLoading(false)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const updateObject = async function (e) {
    e.preventDefault()
    await update(siteStore.id, connector.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The connector instance has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    }
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id && connector && connector.id && retrieveDetail()
  }, [siteStore.id, connector])

  useEffect(() => {
    siteStore.id &&
      gateway &&
      gateway.id &&
      connector &&
      connector.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` },
          {
            label: t("Connector instances"),
            url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/`
          },
          {
            label: connector.label,
            url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/`
          }
        ])
      )
  }, [siteStore.id, gateway, connector])

  return (
    <>
      {object && connector && (
        <>
          <OverView
            object={object}
            model={ConnectorInstance()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            setEdit={setEdit}
            loading={loading}
            deleteUrl={`/sites/${siteStore.id}/assets/gateways/${object.id}/connector-instances/${connector.id}/delete/`}
          />
        </>
      )}
    </>
  )
}
