{
  "Back to hardware list": "Retour à la liste des hardwares",
  "Create": "Création",
  "Create Hardware": "Créer Hardware",
  "Delete": "Suppression",
  "Description": "Description",
  "Detail": "Détail",
  "Gateway box": "Gateway box",
  "Gateway boxes": "Gateway boxes",
  "Home": "Accueil",
  "Manufacturer": "Manufactureur",
  "Model": "Modèle",
  "Name": "Nom",
  "Parameters": "Paramètres",
  "Quantity": "Quantité",
  "Save": "Sauvegarder",
  "Serial ID": "Numéro de série",
  "The hardware has been created.": "Le hardware a été créé.",
  "The hardware has been removed.": "Le hardware a été supprimé.",
  "The hardware has been updated.": "Le hardware a été mis à jour.",
  "Unit": "Unité"
}
