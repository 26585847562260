import { Button } from "primereact/button"
import { updateSideBar } from "/utils/store/sidebar.js"
import { BaseLayout, ListLayout, ListActions, CopyButton } from "hivolution-front-utils"
import { TabPanel, TabView } from "primereact/tabview"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { retrieveList } from "../api"
import { useAuth } from "oidc-react"
import { addNotifications } from "/utils/store/notification.js"
import { errorNotification } from "/utils/notification.js"
import { updateBreadcrumb } from "/utils/store/breadcrumb.js"
import { resetSite } from "/utils/store/site.js"
import { resetOrganization } from "/utils/store/organization.js"

const CardsLayout = props => {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a list of cards.
   * It is placed in a TabList.
   *
   * @component
   * @example
   *
   * export default function List () {
   *   const [activeIndex, setActiveIndex] = useState(0)
   *   const items = [{label: "Cards"}, ...]
   *   return (
   *     <>
   *       <section>
   *         <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
   *         {activeIndex === 0 && <CardsLayout />}
   *         ...
   *       </section>
   *     </>
   *   )
   * }
   *
   *
   */
  const { t } = useTranslation(["home"])

  return (
    <div className="organizations-cards">
      {props.items &&
        props.items.map(organization => (
          <Link
            to={`/organizations/${organization.id}/map/`}
            key={organization.id}
          >
            <div className="organization-card">
              <div
                className="organization-card-logo"
                dangerouslySetInnerHTML={{ __html: organization.logo }}
              />
              <p className="organization-card-title">{organization.name}</p>
              <div className="organization-card-infos">
                <p>{t("sites_count", { count: organization.sites_count })}</p>
              </div>
            </div>
          </Link>
        ))}
    </div>
  )
}

const TableLayout = props => {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in a TabList.
   *
   * @component
   * @example
   *
   * export default function List () {
   *   const [activeIndex, setActiveIndex] = useState(0)
   *   const items = [{label: "Table"}, ...]
   *   return (
   *     <>
   *       <section>
   *         <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
   *         {activeIndex === 0 && <TableLayout />}
   *         ...
   *       </section>
   *     </>
   *   )
   * }
   *
   *
   */

  const actionsBodyTemplate = object => {
    return (
      <div className="p-datatable-action-buttons">
        <CopyButton
          isUrl
          icon="fa-solid fa-link"
          value={`/organizations/${object.id}/map/`}
        />

        <Link
          className="update-button"
          to={`/organizations/${object.id}/`}
        >
          <i className="fa-solid fa-pen" />
        </Link>

        <Link
          className="delete-button"
          to={`/organizations/${object.id}/delete/`}
        >
          <i className="fa-regular fa-trash-can" />
        </Link>
      </div>
    )
  }

  const navigate = useNavigate()
  const { t } = useTranslation(["home"])

  const columns = [
    { field: "name", header: t("Name") },
    { field: "sites_count", header: t("Number of sites") }
  ]

  const onRowClick = objectId => {
    navigate(`/organizations/${objectId}/map/`)
  }

  return (
    <>
      {props.items && (
        <ListLayout
          columns={columns}
          value={props.items}
          objectName={t("Organizations")}
          onRowClick={onRowClick}
          actionsBodyTemplate={actionsBodyTemplate}
        />
      )}
    </>
  )
}

export default function TabList() {
  /*
   * This component work with PrimeReact
   * It display a TabMenu component with one or more <componentLayout>.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<TabList />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const { t } = useTranslation(["home"])
  const dispatch = useDispatch()
  const auth = useAuth()
  const [organizations, setOrganizations] = useState([])

  const retrieve = async () => {
    await retrieveList(auth.userData.access_token)
      .then(response => {
        if (response.data.results) {
          setOrganizations(response.data.results)
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    dispatch(resetSite())
    dispatch(resetOrganization())
    dispatch(updateSideBar({ menuName: null, siteId: null }))
    dispatch(updateBreadcrumb())
    retrieve()
  }, [])

  return (
    <BaseLayout title={t("Organizations")}>
      <ListActions>
        <Link to={"/organizations/create/"}>
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>
      <TabView>
        <TabPanel header={t("Cards")}>
          <CardsLayout items={organizations} />
        </TabPanel>
        <TabPanel header={t("List")}>
          <TableLayout items={organizations} />
        </TabPanel>
      </TabView>
    </BaseLayout>
  )
}
