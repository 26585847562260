import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, ListActions, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})

  const retrieve = async () => {
    await retrieveList(auth.userData.access_token)
      .then(({ data }) => {
        data && setItems(data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    dispatch(updateBreadcrumb([{ label: t("Sites"), url: "/sites/" }]))

    retrieve()
  }, [])

  const columns = [
    { field: "pretty_name", header: "Pretty name", sortable: true },
    { field: "name", header: "Name", sortable: true },
    { field: "country", header: "Country", sortable: true }
  ]

  const getDetailUrl = siteId => {
    return `/sites/${siteId}/`
  }

  const getDeleteUrl = siteId => {
    return `/sites/${siteId}/delete/`
  }

  const onRowClick = siteId => {
    navigate(getDetailUrl(siteId))
  }

  const menuFilters = { columns, items, filters, setFilters }

  return (
    <BaseLayout title={t("Sites")}>
      <ListActions filters={menuFilters}>
        <Link to="/sites/create/">
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create site")}
          />
        </Link>
      </ListActions>
      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Site")}
        loading={loading}
        filters={filters}
        sortField="pretty_name"
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
      />
    </BaseLayout>
  )
}
