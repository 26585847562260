import { GatewayRoutesContext } from "/apps/client/connectivity/gateway/routes.js"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getCount, remove } from "../api"

export default function Delete() {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["client"])
  const [relatedObjects, setRelatedObjects] = useState(null)
  const { gateway } = useContext(GatewayRoutesContext)

  const displayNotification = notification => {
    dispatch(addNotifications(notification))
  }

  const count = async () => {
    await getCount(gateway.id, auth.userData.access_token)
      .then(response => {
        setRelatedObjects(response.data)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  const removeObject = async () => {
    await remove(siteStore.id, gateway.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The gateway has been removed."))])
        navigate(`/sites/${siteStore.id}/assets/gateways/`)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && gateway && gateway.id) {
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` },
          { label: t("Delete"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/delete/` }
        ])
      )

      count()
    }
  }, [siteStore.id, gateway])

  return (
    <>
      {gateway ? (
        <DeleteConfirmation
          objectType={t("Gateway")}
          objectNames={[gateway.name]}
          remove={removeObject}
          relatedObjects={relatedObjects}
          goBack={() => navigate(-1)}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}
