import i18n from "/utils/i18n"
import { Dropdown } from "hivolution-front-utils"
import { InputText } from "primereact/inputtext"

export const NetworkInterfaceOverview = () => {
  return {
    // context_name: { label: i18n.t("Name", { ns: "client" }) },
    // label: { label: i18n.t("Label", { ns: "client" }) },
    gateway_name: { label: i18n.t("Gateway", { ns: "client" }) },
    network_label: { label: i18n.t("Network", { ns: "client" }) },
    ip_addr: { label: i18n.t("IP Address", { ns: "client" }) }
  }
}

export const NetworkInterface = () => {
  return {
    gateway_name: {
      label: i18n.t("Gateway", { ns: "client" }),
      field: InputText,
      options: {
        disabled: true
      }
    },
    network_label: {
      label: i18n.t("Network", { ns: "client" }),
      field: InputText,
      options: {
        disabled: true
      }
    },
    ip_addr: {
      label: i18n.t("IP Address", { ns: "client" }),
      field: InputText,
      options: { required: true }
    }
  }
}

export const NetworkIPInterface = networks => {
  return {
    network: {
      label: i18n.t("Network", { ns: "client" }),
      field: Dropdown,
      options: {
        required: true,
        options: networks,
        optionLabel: "label",
        optionValue: "id"
      }
    },
    ip_addr: {
      label: i18n.t("IP Address", { ns: "client" }),
      field: InputText,
      options: { required: true }
    }
  }
}
