import React, { useContext, useEffect, useState } from "react"

import { OverView } from "hivolution-front-utils"

import { useDispatch, useSelector } from "react-redux"

import { addNotifications } from "/utils/store/notification"

import { update } from "../api"

import { Bacnet, BacnetOverview } from "../models/bacnet"
import { Carel, CarelOverview } from "../models/carel"
import { ModbusBit, ModbusBitOverview } from "../models/modbus"
import { ModbusRegister, ModbusRegisterOverview } from "../models/modbus"
import { ModbusRegisterBitExtract, ModbusRegisterBitExtractOverview } from "../models/modbus"
import { OPCUA, OPCUAOverview } from "../models/opcua"
import { SMIO, SMIOOverview, SMMeter, SMMeterOverview, SMController, SMControllerOverview } from "../models/sm"

import { useTranslation } from "react-i18next"
import { useAuth } from "oidc-react"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { updateSideBar } from "/utils/store/sidebar"
import { errorNotification, successNotification } from "/utils/notification"
import { paginatedRequest } from "/utils/api"
import { PointRoutesContext } from "../routes"
import { site } from "/utils/store/site"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["client"])

  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [nodes, setNodes] = useState([])
  const { point, retrieveDetail } = useContext(PointRoutesContext)

  const mappingNodeTypes = {
    modbusbit: "modbusnode",
    modbusregister: "modbusnode",
    modbusregisterbitextract: "modbusnode",
    bacnet: "bacnetnode",
    opcua: "opcuanode",
    carel: "carelnode",
    smio: "smionode",
    smmeter: "smmeternode",
    smcontroller: "smcontrollernode",
    externalapi: "externalapinode"
  }

  useEffect(() => {
    if (point) {
      setLoading(false)
      setObject(point)
    }
  }, [point])

  useEffect(() => {
    const retrieveNodes = async () => {
      await paginatedRequest(`asset/sites/${siteStore.id}/nodes/?type=${mappingNodeTypes[object.type]}`, auth.userData.access_token)()
        .then(results => {
          setNodes(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    if (object && object.type) retrieveNodes()
  }, [siteStore.id, object])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      object &&
      object.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Communication"), url: `/sites/${siteStore.id}/assets/communication/networks/` },
          { label: t("Automation points"), url: `/sites/${siteStore.id}/assets/communication/automation-points/` },
          { label: object.label ?? object.name, url: `/sites/${siteStore.id}/assets/communication/automation-points/${object.id}/` }
        ])
      )
  }, [siteStore.id, object])

  const updateObject = async function (e) {
    e.preventDefault()
    await update(siteStore.id, point.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The automation point has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getModel = pointType => {
    switch (pointType) {
      case "modbusbit":
        return ModbusBit(nodes)
      case "modbusregister":
        return ModbusRegister(nodes)
      case "modbusregisterbitextract":
        return ModbusRegisterBitExtract(nodes)
      case "bacnet":
        return Bacnet(nodes)
      case "smio":
        return SMIO(nodes)
      case "smcontroller":
        return SMController(nodes)
      case "smmeter":
        return SMMeter(nodes)
      case "opcua":
        return OPCUA(nodes)
      case "carel":
        return Carel(nodes)
      default:
        return {}
    }
  }

  const getOverviewModel = pointType => {
    switch (pointType) {
      case "modbusbit":
        return ModbusBitOverview(nodes)
      case "modbusregister":
        return ModbusRegisterOverview(nodes)
      case "modbusregisterbitextract":
        return ModbusRegisterBitExtractOverview(nodes)
      case "bacnet":
        return BacnetOverview(nodes)
      case "smio":
        return SMIOOverview(nodes)
      case "smcontroller":
        return SMControllerOverview(nodes)
      case "smmeter":
        return SMMeterOverview(nodes)
      case "opcua":
        return OPCUAOverview(nodes)
      case "carel":
        return CarelOverview(nodes)
      default:
        return {}
    }
  }

  return (
    <>
      {object && siteStore.id && (
        <>
          <h1 className="title">
            {object.type} {object.name}
          </h1>
          <OverView
            object={object}
            model={data && getModel(data.type)}
            overviewModel={object && getOverviewModel(object.type)}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            setEdit={setEdit}
            deleteUrl={`/sites/${siteStore.id}/assets/communication/automation-points/${point.id}/delete/`}
          />
        </>
      )}
    </>
  )
}
