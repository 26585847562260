import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { InputNumber } from "primereact/inputnumber"
import { Dropdown } from "hivolution-front-utils"

import i18n from "/utils/i18n"

export const Store = connectorInstances => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("Name", { ns: "client" }), field: InputText, options: { required: true } },
    description: { label: i18n.t("Description", { ns: "client" }), field: InputTextarea, options: {} },
    pulling_interval: { label: i18n.t("Pulling interval", { ns: "client" }), field: InputNumber, options: { required: true, showButtons: true } },
    type: {
      label: i18n.t("Type", { ns: "client" }),
      field: Dropdown,
      options: {
        required: true,
        options: [
          { label: i18n.t("Status", { ns: "client" }), value: "status" },
          { label: i18n.t("Gauge", { ns: "client" }), value: "gauge" }
        ]
      }
    },
    connector_instance: {
      label: i18n.t("Connector instance", { ns: "client" }),
      field: Dropdown,
      options: {
        required: true,
        options: connectorInstances,
        optionLabel: "name",
        optionValue: "id"
      }
    },
    resolution_interval: {
      label: i18n.t("Resolution interval", { ns: "client" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "gauge",
      options: {
        required: true,
        showButtons: true
      }
    }
  }
}

export const StoreStatusOverview = () => {
  return {
    name: { label: i18n.t("Name", { ns: "client" }) },
    description: { label: i18n.t("Description", { ns: "client" }) },
    pulling_interval: { label: i18n.t("Pulling interval", { ns: "client" }) },
    type: { label: i18n.t("Type", { ns: "client" }) },
    connector_instance_name: { label: i18n.t("Connector instance", { ns: "client" }) }
  }
}

export const StoreGaugeOverview = () => {
  return {
    name: { label: i18n.t("Name", { ns: "client" }) },
    description: { label: i18n.t("Description", { ns: "client" }) },
    pulling_interval: { label: i18n.t("Pulling interval", { ns: "client" }) },
    type: { label: i18n.t("Type", { ns: "client" }) },
    connector_instance_name: { label: i18n.t("Connector instance", { ns: "client" }) },
    resolution_interval: { label: i18n.t("Resolution interval", { ns: "client" }) }
  }
}
