import { errorNotification, successNotification } from "/utils/notification"

import { addNotifications } from "/utils/store/notification"

import { FormContent } from "hivolution-front-utils"
import { useAuth } from "oidc-react"

import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import { useNavigate } from "react-router-dom"

import { add, getParameters, getStores } from "../api"
import { Point } from "../models"
import { site } from "/utils/store/site"
import { updateSideBar } from "/utils/store/sidebar"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { paginatedRequest } from "/utils/api"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])

  const [data, setData] = useState({
    parameter: 1,
    time_series_storage_info: {
      acceptable_values: [
        { original_point_value: 0, gateway_point_value: 0, gateway_point_text: "", gateway_point_value_change: false },
        { original_point_value: 1, gateway_point_value: 1, gateway_point_text: "", gateway_point_value_change: false }
      ]
    }
  })
  const [errors, setErrors] = useState({})
  const [equipments, setEquipments] = useState([])
  const [parameters, setParameters] = useState([])
  const [stores, setStores] = useState([])
  const [automationPoints, setAutomationPoints] = useState([])

  useEffect(() => {
    const retrieveAutomationPoints = async nodeId => {
      await paginatedRequest(`asset/sites/${siteStore.id}/automation_points/?node=${nodeId}`, auth.userData.access_token)()
        .then(results => {
          setAutomationPoints(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }

    if (data.equipment) {
      const find = equipments.find(equipment => equipment.id === data.equipment)
      if (find && find.node) {
        retrieveAutomationPoints(find.node)
      }
    }
  }, [data.equipment])

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))

      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: t("Detail"), url: `/sites/${siteStore.id}/` },
          { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
          { label: t("Building"), url: `/sites/${siteStore.id}/assets/building/equipments/` },
          { label: t("Points"), url: `/sites/${siteStore.id}/assets/building/points/` },
          { label: t("Create"), url: `/sites/${siteStore.id}/assets/building/points/create/` }
        ])
      )
    }

    const retrieveEquipments = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteStore.id}/equipments/`, auth.userData.access_token)()
        .then(results => {
          setEquipments(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getEquipments(siteId, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setEquipments(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    if (siteStore.id) {
      retrieveEquipments()
    }

    const retrieveParameters = async () => {
      await getParameters(auth.userData.access_token)
        .then(response => {
          if (response.data.results) {
            setParameters(response.data.results)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    retrieveParameters()
  }, [siteStore.id])

  useEffect(() => {
    const retrieveStores = async () => {
      await getStores(siteStore.id, data.time_series_storage_info.store_type, auth.userData.access_token)
        .then(response => {
          if (response.data.results) {
            setStores(response.data.results)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    if (data.time_series_storage_info.store_type) {
      retrieveStores()
    }
  }, [data.time_series_storage_info.store_type])

  const create = async function (e) {
    e.preventDefault()
    let pointData = Object.assign({}, data)
    nullValues = ["", undefined, null]
    if (pointData.time_series_storage_info && nullValues.includes(pointData.time_series_storage_info.store_type)) {
      delete pointData.time_series_storage_info
    }
    await add(siteStore.id, pointData, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Create", t("The point has been created."))]))
        navigate(`/sites/${siteStore.id}/assets/building/points/${response.data.id}`)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  return (
    <>
      {data && (
        <>
          <div className="base-content-title">
            <h1 className="title">{t("Create point")}</h1>
          </div>

          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={Point(equipments, automationPoints, parameters, stores)}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <Button
                rounded
                severity="success"
                label={t("Save")}
                type="submit"
                icon="fa-solid fa-floppy-disk"
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}
