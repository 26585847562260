import { GatewayRoutesContext } from "../../../gateway/routes"
import { ConnectorInstanceRoutesContext } from "../../routes"
import { removeProtocolInstances } from "../api"
import { errorNotification, successNotification } from "/utils/notification.js"
import { updateBreadcrumb } from "/utils/store/breadcrumb.js"
import { addNotifications } from "/utils/store/notification.js"
import { updateSideBar } from "/utils/store/sidebar.js"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

const DeleteBulk = () => {
  const auth = useAuth()
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["client"])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)
  const ids = searchParams.getAll("id")
  const { gateway } = useContext(GatewayRoutesContext)
  const { connector } = useContext(ConnectorInstanceRoutesContext)

  const bulkDelete = async () => {
    setProcessing(true)
    await removeProtocolInstances(siteStore.id, ids, auth.userData.access_token)
      .then(({ status, statusText }) => {
        if (status === 200) {
          dispatch(addNotifications([successNotification("Delete", t("Protocol instances has been removed."))]))
          navigate(`/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/`)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      gateway &&
      gateway.id &&
      connector &&
      connector.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Connectivity"), url: `/sites/${siteStore.id}/connectivity/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` },
          { label: t("Connector instances"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/` },
          { label: connector.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/` },
          { label: t("Protocol instances"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/` },
          { label: t("Delete"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/delete/` }
        ])
      )
  }, [siteStore.id, gateway, connector])

  return (
    <>
      <DeleteConfirmation
        objectType={t("Protocol instances")}
        objectNames={[`${ids.length} ${t("Protocol instances")}`]}
        remove={bulkDelete}
        processing={processing}
        goBack={() => navigate(-1)}
      />
    </>
  )
}

export default DeleteBulk
