import { errorNotification, successNotification } from "/utils/notification"

import { addNotifications } from "/utils/store/notification"

import { OverView, ReturnButton, Loader } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { useNavigate } from "react-router-dom"

import { retrieveList, update } from "../api"
import { Space, SpaceOverview } from "../models"
import { SpaceRouteContext } from "../routes"
import { site } from "/utils/store/site"
import { updateSideBar } from "/utils/store/sidebar"
import { updateBreadcrumb } from "/utils/store/breadcrumb"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["client"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [spaces, setSpaces] = useState([])
  const { space, retrieveDetail } = useContext(SpaceRouteContext)

  const updateObject = async function (e) {
    e.preventDefault()
    await update(siteStore.id, space.id, data, auth.userData.access_token)
      .then(() => {
        setLoading(true)
        dispatch(addNotifications([successNotification("Update", t("The space has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (space) {
      setLoading(false)
      setObject(space)
    }
  }, [space])

  useEffect(() => {
    const retrieveSpaces = async () => {
      await retrieveList(siteStore.id, auth.userData.access_token)
        .then(response => {
          setSpaces(response.data)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }

    if (siteStore.id) {
      object &&
        object.id &&
        dispatch(
          updateBreadcrumb([
            { label: t("Sites"), url: "/sites/" },
            { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
            { label: t("Assets"), url: `/sites/${siteStore.id}/assets/` },
            { label: t("Building"), url: `/sites/${siteStore.id}/assets/building/equipments/` },
            { label: t("Equipments"), url: `/sites/${siteStore.id}/assets/building/spaces/` },
            { label: object.label ?? object.name, url: `/sites/${siteStore.id}/assets/building/spaces/${object.id}/` }
          ])
        )

      retrieveSpaces()
    }
  }, [siteStore.id, object])

  return (
    <>
      {object ? (
        <>
          <ReturnButton
            label={t("Back to space list")}
            onClick={() => navigate(`/sites/${siteStore.id}/assets/building/spaces/`)}
          />

          <div className="base-content-title">
            <h2>
              {t("Space")} {object.name}
            </h2>
          </div>

          <OverView
            object={object}
            model={Space(spaces)}
            overviewModel={SpaceOverview()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            setEdit={setEdit}
            deleteUrl={`/sites/${siteStore.id}/assets/building/spaces/${object.id}/delete/`}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
