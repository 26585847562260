import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(flexId, date, token) {
  return await axios.get(`${window.BACKEND_FLEX_URL}/flex/${flexId}/load_curtail_schedule_fetch_in_a_day/?execution_desired=true${date ? `&current_date=${date}` : ""}`, header(token))
}

export async function retrieve(flexId, id, token) {
  return await axios.get(`${window.BACKEND_FLEX_URL}/flex/${flexId}/load_curtail_schedule/${id}/`, header(token))
}

export async function update(flexId, id, data, token) {
  return await axios.put(`${window.BACKEND_FLEX_URL}/flex/${flexId}/load_curtail_schedule/${id}/`, data, header(token))
}

export async function remove(flexId, id, token) {
  return await axios.delete(`${window.BACKEND_FLEX_URL}/flex/${flexId}/load_curtail_schedule/${id}/`, header(token))
}
