import { Button } from "primereact/button"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"

export default function LogoInput(props) {
  const hiddenFileInput = useRef(null)
  const { t } = useTranslation(["home"])

  const handleFile = target => {
    file = target.files[0]
    if (file) {
      let reader = new FileReader()
      reader.readAsText(file, "UTF-8")
      reader.onload = event => {
        props.setData({ ...props.data, logo: event.target.result })
      }
    }
  }

  return (
    <div className="logo-field">
      <div className="logo-container">
        <input
          className="hidden"
          ref={hiddenFileInput}
          type="file"
          accept="image/svg+xml"
          onChange={e => handleFile(e.target)}
        />
        <div
          className="logo-previsualisation-container"
          onClick={() => hiddenFileInput && hiddenFileInput.current.click()}
        >
          {props.data.logo ? (
            <div
              className="logo-image"
              dangerouslySetInnerHTML={{ __html: props.data.logo }}
            />
          ) : (
            <i className="fa-solid fa-upload text-gray-300 text-xl"></i>
          )}
        </div>
        <div className="logo-buttons">
          <Button
            rounded
            outlined
            icon="far fa-file-image"
            label={t("Logo (SVG)")}
            onClick={() => hiddenFileInput && hiddenFileInput.current.click()}
            type="button"
          />
          {props.data.logo && (
            <Button
              rounded
              outlined
              severity="danger"
              icon="far fa-file-image"
              label={t("Remove")}
              onClick={() => {
                props.setData({ ...props.data, logo: null })
                hiddenFileInput.current.value = ""
              }}
              type="button"
            />
          )}
        </div>
      </div>
    </div>
  )
}
