import { paginatedRequest } from "/utils/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})

  const retrieve = async () => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`optimization/sites/${siteStore.id}/states/`, auth.userData.access_token)()
      .then(results => {
        setItems(results)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /* await retrieveList(siteId, auth.userData.access_token).then(response => {
      if (response.data) {
        setItems(response.data.results)
      }
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const columns = [
    { field: "gateway_name", header: t("Gateway"), sortable: true },
    { field: "name", header: t("Name"), sortable: true },
    { field: "type", header: t("Type"), sortable: true },
    { field: "active", header: t("Deployed"), sortable: true }
  ]

  const getDetailUrl = stateId => {
    return `/sites/${siteStore.id}/optimization/configuration/states/${stateId}/`
  }

  const getDeleteUrl = stateId => {
    return `/sites/${siteStore.id}/optimization/configuration/states/${stateId}/delete/`
  }

  const onRowClick = stateId => {
    navigate(getDetailUrl(stateId))
  }

  const menuFilters = { items, columns, filters, setFilters }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))

      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Optimization"), url: `/sites/${siteStore.id}/optimization/` },
          { label: t("States"), url: `/sites/${siteStore.id}/optimization/configuration/states/` }
        ])
      )

      retrieve()
    }
  }, [siteStore.id])

  return (
    <section className="list-section">
      <ListActions filters={menuFilters}>
        <Link to={`/sites/${siteStore.id}/optimization/configuration/states/create/`}>
          <Button
            rounded
            icon="fa-regular fa-plus"
            label={t("Create state")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("States")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
      />
    </section>
  )
}
