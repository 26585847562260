import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { SelectModal } from "hivolution-front-utils"
import { commonsFields, commonsOverview, t } from "./commons"

export const CarelNetworkNode = (spaces, carelNodes) => {
  return {
    ...commonsFields(spaces),
    ...{
      carel_node: {
        label: t("Carel node"),
        field: SelectModal,
        options: {
          items: carelNodes,
          columns: [
            { field: "name", header: t("Name") },
            { field: "model", header: t("Model") },
            { field: "manufacturer", header: t("Manufacturer") },
            { field: "target", header: t("Target") },
            { field: "scheme", header: t("Scheme") },
            { field: "tls_version", header: t("TLS version") },
            { field: "low_cipher", header: t("Low cipher") },
            { field: "user", header: t("User") }
          ],
          required: true,
          objectName: t("Carel Nodes"),
          searchLabel: id => {
            let find = carelNodes.find(node => node.id === id)
            return find ? find.name : ""
          }
        }
      },
      id_device: {
        label: t("Device ID"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      },
      status: {
        label: t("Status"),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      address: {
        label: t("Address"),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      id_line: {
        label: t("Line ID"),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      type_protocol: {
        label: t("Protocol type"),
        field: InputText,
        options: {}
      }
    }
  }
}

export const CarelNetworkOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      carel_node_label: { label: t("Carel node") },
      id_device: { label: t("Device ID") },
      status: { label: t("Status") },
      address: { label: t("Address") },
      id_line: { label: t("Line ID") },
      type_protocol: { label: t("Protocol type") }
    }
  }
}
