import * as React from "react"
import { createContext, useState, useEffect } from "react"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"

import List from "./components/List"
import { useDispatch } from "react-redux"
import { useAuth } from "oidc-react"
import { retrieve } from "./api"
import { addNotifications } from "/utils/store/notification"
import { errorNotification } from "/utils/notification"

const SpaceRouteContext = createContext(null)

const SpaceContent = () => {
  const auth = useAuth()
  const { siteId, spaceId } = useParams()
  const dispatch = useDispatch()
  const [space, setSpace] = useState()

  const retrieveDetail = async () => {
    await retrieve(siteId, spaceId, auth.userData.access_token)
      .then(response => {
        setSpace(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    spaceId && retrieveDetail()
  }, [spaceId])

  return (
    <SpaceRouteContext.Provider value={{ space, retrieveDetail }}>
      <Outlet />
    </SpaceRouteContext.Provider>
  )
}

export { SpaceRouteContext }

export default function SpaceRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="/create/"
        element={<Create />}
      />

      <Route
        path=":spaceId"
        element={<SpaceContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
