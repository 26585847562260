import { InputText } from "primereact/inputtext"
import { InputSwitch } from "primereact/inputswitch"
import { Dropdown } from "hivolution-front-utils"

import { commonsFields, commonsOverview, t } from "./commons"

export const Carel = nodes => {
  return {
    ...commonsFields(nodes),
    ...{
      id_var: {
        label: t("ID Var"),
        field: InputText,
        options: { required: true }
      },
      write: {
        label: t("Write"),
        field: InputSwitch,
        options: {}
      },
      is_logic: {
        label: t("Is Logic"),
        field: InputSwitch,
        options: {}
      },
      carel_type: {
        label: t("Type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "1", label: t("Digital (1)") },
            { value: "2", label: t("Analogue (2)") },
            { value: "3", label: t("Integer (3)") },
            { value: "4", label: t("Alarm (4)") }
          ]
        }
      }
    }
  }
}

export const CarelOverview = nodes => {
  return {
    ...commonsOverview(nodes),
    ...{
      id_var: { label: t("ID Var") },
      read_write: { label: t("Read / Write") },
      type: { label: t("Type") }
    }
  }
}
