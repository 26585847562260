{
  "Organizations": "Organizations",
  "Home": "Home",
  "Cards": "Cards",
  "List": "List",
  "Name": "Name",
  "Number of sites": "Number of sites",
  "sites_count": "{{count}} sites",
  "Edit": "Edit",
  "Organization": "Organization",
  "The organization has been updated.": "The organization has been updated.",
  "The organization has been created.": "The organization has been created.",
  "The organization has been removed.": "The organization has been removed.",
  "You can't delete this organization, there is sites linked to it": "You can't delete this organization, there is sites linked to it",
  "Create organization": "Create organization",
  "Save": "Save",
  "Remove": "Remove",
  "Map": "Map"
}
