import { Dropdown } from "hivolution-front-utils"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

import i18n from "/utils/i18n"

export const StateForm = gateways => {
  return {
    name: {
      label: i18n.t("Name", { ns: "client" }),
      field: InputText,
      options: { required: true }
    },
    comment: {
      label: i18n.t("Comment", { ns: "client" }),
      field: InputTextarea,
      options: {}
    },
    type: {
      label: i18n.t("Type", { ns: "client" }),
      field: Dropdown,
      options: {
        required: true,
        options: [
          { label: i18n.t("Inhibit", { ns: "client" }), value: "inhibit" },
          { label: i18n.t("Safe state", { ns: "client" }), value: "safestate" },
          { label: i18n.t("Constraint", { ns: "client" }), value: "constraint" },
          { label: i18n.t("Curtail", { ns: "client" }), value: "curtail" },
          { label: i18n.t("Precool", { ns: "client" }), value: "precool" },
          { label: i18n.t("Postcool", { ns: "client" }), value: "postcool" }
        ],
        placeholder: i18n.t("Select a state type", { ns: "client" })
      }
    },
    gateway: {
      label: i18n.t("Gateway"),
      field: Dropdown,
      options: {
        required: true,
        options: gateways,
        optionLabel: "name",
        optionValue: "id",
        filter: true,
        placeholder: i18n.t("Select a gateway", { ns: "client" })
      }
    }
  }
}

export const StateOverview = () => {
  return {
    name: { label: i18n.t("Name", { ns: "client" }) },
    comment: { label: i18n.t("Comment", { ns: "client" }) },
    type: { label: i18n.t("Type", { ns: "client" }) },
    gateway_name: { label: i18n.t("Gateway", { ns: "client" }) }
  }
}
