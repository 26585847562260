{
  "Organizations": "Sociétés",
  "Home": "Accueil",
  "Cards": "Cartes",
  "List": "Liste",
  "Name": "Nom",
  "Number of sites": "Nombre de sites",
  "sites_count": "{{count}} sites",
  "Edit": "Éditer",
  "Organization": "Société",
  "The organization has been updated.": "La société a été mise à jour.",
  "The organization has been created.": "La société a été créée",
  "The organization has been removed.": "La société a été supprimé",
  "You can't delete this organization, there is sites linked to it": "Vous ne pouvez pas supprimer cette organisation, il existe des sites qui y sont liés",
  "Create organization": "Créer société",
  "Save": "Sauvegarder",
  "Remove": "Supprimer",
  "Map": "Carte"
}
