import { createSlice } from "@reduxjs/toolkit"

export const siteSlice = createSlice({
  name: "site",

  initialState: {
    entry: { id: null }
  },

  reducers: {
    updateSite: (state, action) => {
      state.entry = action.payload
    },

    resetSite: state => {
      state.entry = { id: null }
    }
  }
})

export const { updateSite, resetSite } = siteSlice.actions
export const site = state => state.site.entry
