import { errorNotification } from "/utils/notification"

import { addNotifications } from "/utils/store/notification"

import { ListActions, ListLayout } from "hivolution-front-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import { Link, useNavigate } from "react-router-dom"

import { retrieveList } from "../api"
import { ConnectorInstanceRoutesContext } from "../../routes"
import { site } from "/utils/store/site"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { GatewayRoutesContext } from "../../../gateway/routes"
import { PROTOCOL_INSTANCE_TYPE } from "/utils/constants/protocol-instance"
import { Button } from "primereact/button"
import { updateSideBar } from "/utils/store/sidebar"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["client"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [selected, setSelected] = useState([])
  const { connector } = useContext(ConnectorInstanceRoutesContext)
  const { gateway } = useContext(GatewayRoutesContext)

  const retrieve = async () => {
    await retrieveList(siteStore.id, connector.id, auth.userData.access_token)
      .then(response => {
        setItems(
          response.data.results.map(protocolInstance => {
            return {
              ...protocolInstance,
              ...{
                label: PROTOCOL_INSTANCE_TYPE[protocolInstance.type],
                gateway_ip_interface: protocolInstance.gateway_ip_interface.id,
                gateway_ip_interface_label: protocolInstance.gateway_ip_interface.label,
                gateway_ip_interface_info: protocolInstance.gateway_ip_interface
              }
            }
          })
        )
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      gateway &&
      gateway.id &&
      connector &&
      connector.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Connectivity"), url: `/sites/${siteStore.id}/connectivity/` },
          { label: t("Gateways"), url: `/sites/${siteStore.id}/assets/gateways/` },
          { label: gateway.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/` },
          { label: t("Connector instances"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/` },
          { label: connector.name, url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/` },
          { label: t("Protocol instances"), url: `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/` }
        ])
      )

    siteStore.id && connector && gateway && retrieve()
  }, [siteStore.id, gateway, connector])

  const getDetailUrl = objectId => {
    return `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/${objectId}/`
  }

  const getDeleteUrl = objectId => {
    return `/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/${objectId}/delete/`
  }

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const infoBody = rowData => {
    return (
      <div className="flex">
        {rowData.type === "bacnetip" && `${t("Port")} : ${rowData.gateway_udp_port}`}
        {rowData.type === "smxmlapi" && `${t("Batch size")} : ${rowData.batch_size} - ${t("Sleep time")} : ${rowData.sleep_time}`}
        {rowData.type === "modbusip" && (
          <button
            className="list-action-button"
            onClick={() => navigate(`/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/${rowData.id}/?editTimeout=1`)}
          >
            <i className="fa-solid fa-gears" />
            {t("Manage timeout")}
          </button>
        )}
      </div>
    )
  }

  const columns = [
    { field: "label", header: t("Type") },
    { field: "gateway_ip_interface_label", header: t("Gateway interface") },
    { field: "info", header: t("Infos"), body: infoBody }
  ]

  const menuEntries = []

  const menuFilters = { columns, items, filters, setFilters }

  return (
    <section className="list-section">
      <ListActions
        entries={menuEntries}
        filters={menuFilters}
      >
        <Link to={`/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/create/`}>
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Protocol instances")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        bulkDeleteUrl={`/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        handleSelection={setSelected}
      />
    </section>
  )
}
